<template lang="html">
  <div class="card card-primary card-outline">
    <form @submit.prevent>
      <div class="card-header p-1">
        <h3 class="card-title">客栈编辑</h3>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="title">客栈名称</label>
          <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$error }" id="title" v-model.trim="$v.form.title.$model" placeholder="请输入客栈名称">
          <div class="invalid-feedback">
            <span v-if="!$v.form.title.required">请输入客栈名称</span>
            <span v-if="!$v.form.title.minLength">客栈名称至少 {{$v.form.title.$params.minLength.min}} 个字符</span>
            <span v-if="!$v.form.title.maxLength">客栈名称最多 {{$v.form.title.$params.maxLength.max}} 个字符</span>
          </div>
        </div>
        <CollapedBox collapseIcon expandCard>
          <template v-slot:header>
            基本设置
          </template>
          <div class="form-group">
            <label for="Description">简介</label>
            <!--<textarea class="form-control" id="Description" v-model.trim="form.description" placeholder="请输入简介"></textarea>-->
            <PellEditor v-model.trim="form.description"
                        :options="options"
                        :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
          </div>
          <div class="form-group">
            <label for="SpecialService">服务</label>
            <!--<textarea class="form-control" id="SpecialService" v-model.trim="form.specialService" placeholder="请输入服务"></textarea>-->
            <PellEditor v-model.trim="form.specialService"
                        :options="options"
                        :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
          </div>
          <div class="form-group">
            <label for="Tips">提示</label>
            <!--<textarea class="form-control" id="Tips" v-model.trim="form.tips" placeholder="请输入提示"></textarea>-->
            <PellEditor v-model.trim="form.tips"
                        :options="options"
                        :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
          </div>

          <div class="form-group">
            <label for="Type">类型</label>
            <select class="form-control" id="Type" v-model:value="form.type">
              <option v-for="i in types" :value="i.value">{{i.key}}</option>
            </select>
          </div>
          <div class="form-group">
            <label for="Level">档次</label>
            <select class="form-control" id="Level" v-model:value="form.level">
              <option v-for="i in levels" :value="i.value">{{i.key}}</option>
            </select>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.isChain" placeholder="连锁">连锁
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.isForeigner" placeholder="接待外宾">接待外宾
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.isHkMoTw" placeholder="接待港澳台宾客">接待港澳台宾客
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.notElderly" placeholder="不接待老人">不接待老人
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.notChild" placeholder="不接待小孩">不接待小孩
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model.trim="form.notPet" placeholder="不接待宠物">不接待宠物
            </label>

          </div>

          <div v-if="$inRole('管理员')" class="form-group">
            <label for="ExpiryTime">有效期</label>
            <input type="date" class="form-control" id="ExpiryTime" v-model.trim="form.expiryTime" placeholder="请输入有效期">
          </div>
          <div class="form-group">
            <label for="reserveUrl">预订链接</label>
            <input type="url" class="form-control" :class="{ 'is-invalid': $v.form.reserveUrl.$error }" id="reserveUrl" v-model.trim="$v.form.reserveUrl.$model" placeholder="请输入预订链接">
            <div class="invalid-feedback">
              <span v-if="!$v.form.reserveUrl.isUrl">不是有效的 Url 格式</span>
            </div>
          </div>
          <div class="form-group">
            <label for="ownerSummary">店主介绍</label>
            <input type="url" class="form-control" :class="{ 'is-invalid': $v.form.ownerSummary.$error }" id="ownerSummary" v-model.trim="$v.form.ownerSummary.$model" placeholder="请输入店主介绍">
            <div class="invalid-feedback">
              <span v-if="!$v.form.ownerSummary.maxLength">店主介绍最多 {{$v.form.ownerSummary.$params.maxLength.max}} 个字符</span>
            </div>
          </div>

        </CollapedBox>

        <CollapedBox collapseIcon :cardColor="$v.form.address.$invalid?'danger':'primary'">
          <template v-slot:header>
            联系信息
          </template>
          <div>
            <div class="form-group">
              <label for="fullName">联系人</label>
              <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.address.fullName.$error }" id="fullName" v-model.trim="$v.form.address.fullName.$model" placeholder="请输入联系人姓名" maxlength="10">
              <div class="invalid-feedback">
                <span v-if="!$v.form.address.fullName.required">请输入联系人姓名</span>
                <span v-else="!$v.form.address.fullName.minLength">联系人姓名至少 {{$v.form.address.fullName.$params.minLength.min}} 个字符</span>
                <span v-else="!$v.form.address.fullName.maxLength">联系人姓名最多 {{$v.form.address.fullName.$params.maxLength.max}} 个字符</span>
              </div>
            </div>
            <div class="form-group">
              <label for="mobile">手机</label>
              <input type="tel" class="form-control" :class="{ 'is-invalid': $v.form.address.mobile.$error }" id="mobile" v-model.trim="$v.form.address.mobile.$model" placeholder="请输入手机号码" maxlength="11">
              <div class="invalid-feedback">
                <span v-if="!$v.form.address.mobile.required">请输入手机号码</span>
                <span v-else="!$v.form.address.mobile.isMobile">不是有效的手机号码格式</span>
                <span v-else="!$v.form.address.mobile.minLength">手机号码至少 {{$v.form.address.mobile.$params.minLength.min}} 个字符</span>
                <span v-else="!$v.form.address.mobile.maxLength">手机号码最多 {{$v.form.address.mobile.$params.maxLength.max}} 个字符</span>
              </div>
            </div>
            <div class="form-group">
              <label for="tel">电话</label>
              <input type="tel" class="form-control" :class="{ 'is-invalid': $v.form.address.tel.$error }" id="tel" v-model.trim="$v.form.address.tel.$model" placeholder="请输入电话号码" maxlength="20">
              <div class="invalid-feedback">
                <span v-if="!$v.form.address.tel.isTel">不是有效的电话号码格式</span>
              </div>
            </div>
            <div class="form-group">
              <AreaCode v-model="form.address.area" :level="3"></AreaCode>
            </div>
            <div class="form-group">
              <label for="street">地址</label>
              <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.address.street.$error }" id="street" v-model.trim="$v.form.address.street.$model" placeholder="请输入地址" maxlength="200">
              <div class="invalid-feedback">
                <span v-if="!$v.form.address.street.required">请输入地址</span>
                <span v-else="!$v.form.address.street.minLength">地址至少 {{$v.form.address.street.$params.minLength.min}} 个字符</span>
                <span v-else="!$v.form.address.street.maxLength">地址最多 {{$v.form.address.street.$params.maxLength.max}} 个字符</span>
              </div>
            </div>
          </div>
        </CollapedBox>



        <!--<div class="form-group">
        <input type="file" id="files" accept="image/jpeg" />
        <button type="button" v-on:click="upload">提交</button>
        <div v-if="showProgress" class="progress" :style="{backgroundImage:'linear-gradient(to right,#C0C7CB 0%,#C0C7CB '+progress+',#E1E6E9 '+progress+',#E1E6E9 100%)'}"></div>
        <div v-html=ProgressErro></div>
          </div>-->
        <mapbox :position="[clng,clat]" :citycode="(form.address||{}).area||null" :onChange="mapOnChange" ref="myMapbox"></mapbox>
        <CollapedBox collapseIcon>
          <template v-slot:header>
            设置全景
          </template>
          <div>
            <a class="btn btn-primary" @click="showPanoLibDialog()">请选择全景</a>
            <PanoLib :on-selected="onSelected" :libUrl="PanolibUrl" :params="{innid:$route.params.innId}" ref="PanoLib"></PanoLib>
            <div>
              <img v-if="form.panoUrl" :src="form.panoUrl.replace('/0/0/0/0/', '/600/0/0/0/')" style="max-height:400px" />
            </div>
          </div>
        </CollapedBox>
        <CollapedBox collapseIcon expandCard>
          <template v-slot:header>
            选择设施
          </template>
          <div class="form-group">
            <label for="tel">综合设施</label>
            <CheckBox checkprop="id"
                      :checkList="facilitys1"
                      :selected="form.facilitys"
                      :onChange="checkOnChange1">

            </CheckBox>
            <div v-if="!facilitys1">等待数据</div>
          </div>
          <div class="form-group">
            <label for="tel">周边服务</label>
            <CheckBox checkprop="id"
                      :checkList="facilitys2"
                      :selected="form.facilitys"
                      :onChange="checkOnChange2">

            </CheckBox>
            <div v-if="!facilitys2">等待数据</div>
          </div>
        </CollapedBox>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model.trim="form.closed" placeholder="关闭客栈">关闭客栈
          </label>
        </div>

      </div>
      <Footer>
        <button type="button" class="btn btn-default mr-1" @click.prevent="Close">取消</button>
        <template v-if="$inRole('管理员')||$inRole('客栈店主')||$inRole('客栈代理')">
          <template v-if="form.id==null||form.id.length==0">
            <button type="button" @click.prevent="Create" class="btn btn-primary" :disabled="Updateing">添加</button>
          </template>
          <template v-else>
            <button type="button" @click.prevent="Update(form.id)" class="btn btn-primary" :disabled="Updateing">更新</button>
          </template>
        </template>
      </Footer>
    </form>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'
  import {
    AreaCode,
    PanoLib,
    MapBox,
    CheckBox,
    CollapedBox,
    FileBox,
    UploadSingleBox,
    PellEditor,
    //Modal,
    Footer,
  } from 'common'
  //import PanoLib from '@/components/PanoLib.vue'
  //import mapbox from '@/components/mapbox.vue'
  //import FileBox from '@/components/FileBox.vue'
  //import CheckBox from '@/components/CheckBox.vue'
  //import UploadSingleBox from '@/components/UploadSingleBox.vue'
  //import CollapedBox from '@/components/CollapedBox.vue'
  //import PellEditor from '@/components/PellEditor.vue'
  //import Footer from '@/components/Footer.vue'
  //import Modal from '@/components/Modal.vue'
  import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
  const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
  const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
  const isUrl = helpers.regex('isUrl', /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(?::[0-9]{1,5})?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/)

  export default {
    components: {
      AreaCode,
      PanoLib,
      MapBox,
      CheckBox,
      CollapedBox,
      FileBox,
      UploadSingleBox,
      PellEditor,
      //Modal,
      Footer,
    },
    data() {
      return {
        id: this.$route.params.innId,
        form: {
          id: '',
          title: '',
          description: '',
          address: {
            area: null,
            street: '',
            tel: '',
            mobile: '',
            fullName: ''
          },
          expiryTime: '',
          panoUrl: '',
          lng: 0,
          lat: 0,
          facilitys: [],
          businessLicense: null,
          idCard: null,
          specialLicense: null,
          fireLicense: null,
          healthLicense: null,
          closed: false,
        },
        progress: 0,
        showProgress: false,
        showProgressErro: false,
        ProgressErro: '',
        //aMap: null,
        dontchangemap: 0,
        clng: 0,
        clat: 0,
        facilitys1: [],
        facilitys2: [],
        types: [],
        levels: [],
        //qrcode: null,
        //qrcodeLoading: false,
        areas: {
          province: null,
          provinces: null,
          city: null,
          citys: null,
          district: null,
          districts: null
        },
        fields: [
          { key: 'more', label: '操作' },
          { key: 'id', label: 'Id' },
          { key: 'title', label: '客栈名称' },
          { key: 'lnglat', label: '经纬度' },
          { key: 'phone', label: '联系方式' },
          { key: 'areaId', label: '区域' },
          { key: 'address', label: '地址' },
          { key: 'userId', label: '用户Id' },
          { key: 'createId', label: '创建Id' },
          { key: 'expiryTime', label: '到期时间' },
          { key: 'addTime', label: '注册时间', sortable: true }
        ],
        material: null,
        //pager: {
        //  prePageSize: [10, 20, 50, 100],
        //  pageIndex: 1,
        //  pageSize: 1,
        //  totalPages: 0,
        //  totalRecords: 0,
        //  data: []
        //},
        query: {
          prePageSize: [10, 20, 50, 100],
          totalPages: 0,
          totalRecords: 0,

          pageIndex: 1,
          pageSize: 20,

          sort: "addTime",
          order: true,
          fields: 'cardNo',
          key: null,
          roles: "",
          data: []
        },
        PanolibUrl: window.global.ENABLE_ELASTICSEARCH ? "/Api/ES/Admin/Panos" : "/Api/Admin/Panos",
        dragData: {
          lng: null,
          lat: null,
          address: null,
          nearestJunction: null,
          nearestRoad: null,
          nearestPOI: null
        },

        zhssloading: false,
        zbfwloading: false,
        Updateing: false,
        options: {
          whiteList: {
            p: [],
            b: [],
            strong: [],
            i: [],
            u: [],
            strike: [],
            br: [],
            //h1: [],
            //h2: [],
            //blockquote: [],
            //hr: [],
            ol: [],
            ul: [],
            li: [],
            //code: [],
            //a: ['href'],
            //img: ['src'],
            sub: [],
            sup: [],
          },
          stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
          stripIgnoreTagBody: ['script'] // script标签较特殊，需要过滤标签中间的内容
        }
      }
    },
    validations: {
      areas: {
        province: {
          required,
        },
        city: {
          required,
        },
        district: {
          required,
        },
      },
      form: {
        title: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30),
        },
        address: {
          area: {
            required
          },
          mobile: {
            required,
            minLength: minLength(11),
            maxLength: maxLength(11),
            isMobile
          },
          tel: {
            //required,
            isTel
          },
          fullName: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(10),
          },
          street: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(200),
          }
        },
        reserveUrl: {
          isUrl
        },
        ownerSummary: {
          maxLength: maxLength(50),
        },
      }
    },
    //created() {
    //  console.info('created')
    //  //if (this.form.id == null || this.form.id.length == 0) {
    //  //  this.loadData()
    //  //}
    //  //this.$store.state.pageinfo.main.title = "用户管理"
    //  //this.setPageInfo("用户管理")

    //},
    computed: {
      //SBComponent: function () {
      //  return 'mapDrag'
      //}
      //...mapGetters([
      //  'inns'
      //]),
      //...mapState({
      //  //tableData: state => state.users.main.data,//绑定store.state.direction到组件，之后可用this.direction获取
      //  //list: state => state.loading
      //}),
      //list() {
      //  this.pager.pageIndex = this.query.pageIndex
      //  this.pager.pageSize = this.query.pageSize
      //  this.pager.totalPages = this.query.totalPages
      //  this.pager.totalRecords = this.query.totalRecords
      //  //this.pager.data = this.query.data
      //  return this.pager.data
      //}
    },
    methods: {
      formRemove(pname) {
        var _this = this
        return () => {
          console.log('formRemove', pname)
          this.form[pname] = null;
        }
      },
      status(validation) {
        return {
          error: validation.$error,
          dirty: validation.$dirty
        }
      },
      //changelng() {
      //  test = clng
      //  this.form.lat = clng
      //},
      //changelat() {
      //  test = clat
      //  this.form.lat = clat
      //},
      ...mapActions([
        'setPageInfo',
      ]),
      //dragMap(data) {
      //  this.dragData = {
      //    lng: data.position.lng,
      //    lat: data.position.lat,
      //    address: data.address,
      //    nearestJunction: data.nearestJunction,
      //    nearestRoad: data.nearestRoad,
      //    nearestPOI: data.nearestPOI
      //  }
      //},
      //文件上传
      //upload: function () {
      //  var _this = this
      //  _this.showProgress = true
      //  _this.showProgressErro = false
      //  var url = 'https://localhost:5003' + "/Api/Inn/Files/UploadPano"
      //  var token = $('#access_token').val()
      //  var files = $('#files')[0].files[0]
      //  console.log(files)
      //  var data = new FormData()
      //  //data.append("articleGUID", articleGUID); //文章 Id
      //  data.append("files", files)

      //  var config = {
      //    onUploadProgress: progressEvent => {
      //      var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
      //      this.progress = complete
      //      console.info('#', complete)
      //      console.info('#', progressEvent)
      //      console.info('#', progressEvent.loaded)
      //    }
      //  }
      //  this.$axios.post(url, data, config)
      //    .then(function (response) {
      //      console.log('#', response)
      //      _this.showProgressErro = true
      //      if (true) {
      //        _this.showProgress = false
      //        _this.ProgressErro = '上传成功'
      //        _this.ProgressErro = '<img style="max-width:200px;max-height:200px" src=http://localhost:5001' + response.data.url + ' />'
      //      } else {
      //        _this.ProgressErro = response.data
      //      }
      //    }).catch(function (error) {
      //      console.log(error)
      //    })
      //},
      initData() {
        //this.initMap(0, 0)
        this.getProvince();
      },
      loadData() {
        let _this = this
        this.zhssloading = true;
        this.$axios.get('/Api/InnAdmin/Facilitys/All', { params: { category: '综合设施' } })
          .then(res => {
            _this.facilitys1 = res.data;
            _this.zhssloading = false;
          })
          .catch(function (error) {
            console.log(error)
            _this.zhssloading = false;
          })
        this.zbfwloading = true;
        this.$axios.get('/Api/InnAdmin/Facilitys/All', { params: { category: '周边服务' } })
          .then(res => { _this.facilitys2 = res.data })
          .catch(function (error) {
            console.log(error)
          })
        this.$axios.get('/Api/InnAdmin/Inns/Types')
          .then(res => { _this.types = res.data })
          .catch(function (error) {
            console.log(error)
          })
        this.$axios.get('/Api/InnAdmin/Inns/Levels')
          .then(res => { _this.levels = res.data })
          .catch(function (error) {
            console.log(error)
          })
        if (!this.id || this.id.length == 0) {
          this.initData();
          return;
        }
        this.$axios.get('/Api/InnAdmin/Inns/' + this.id)
          .then(function (response) {
            //console.log(response)
            let d = response.data
            //_this.form.id = d.id
            //_this.form.title = d.title
            //_this.form.description = d.description
            //_this.form.expiryTime = d.expiryTime
            //_this.form.lng = d.lng
            //_this.form.lat = d.lat
            _this.form = d;
            _this.clng = d.lng;
            _this.clat = d.lat;
            _this.dontchangemap = 1;
            //console.log('loadData', d.lng, d.lat)
            //初始化地图
            //_this.initMap(d.lng, d.lat)
          })
          .catch(function (error) {
            console.log(error)
          })


      },
      // 搜索
      handleSearch() {
        if (this.searchKey) {
          this.placeSearch.search(this.searchKey)
        }
      },

      mapOnChange(lng, lat) {
        console.log(lng, lat)
        this.clng = lng;
        this.clat = lat;
        this.form.lng = lng;
        this.form.lat = lat;
      },
      checkOnChange1(list) {
        console.log(list)
        this.form.facilitys = [...list]
      },
      checkOnChange2(list) {
        console.log(list)
        this.form.facilitys = [...list]
      },
      Create() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let _this = this
        let d = this.form
        delete d.id
        _this.Updateing = true;
        this.$axios.post('/Api/InnAdmin/Inns', d)
          .then(function (response) {
            console.log(response)
            _this.id = response.data.id;
            _this.Updateing = false;
            _this.Close()
          })
          .catch(function (error) {
            _this.Updateing = false;
            console.log(error)
          })
      },
      Delete(id, i) {
        let _this = this
        this.$axios.delete('/Api/InnAdmin/Inns/' + id)
          .then(function (response) {
            _this.Close()
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      Update(id) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let _this = this
        _this.Updateing = true;
        this.$axios.put('/Api/InnAdmin/Inns/' + id, this.form)
          .then(function (response) {
            _this.Close()
            _this.Updateing = false;
          })
          .catch(function (error) {
            console.log(error)
            _this.Updateing = false;
          })
      },
      //Room(id) {
      //    console.log(id)
      //    this.$router.push({ path: '/rooms', query: { id: id } })
      //},
      Close() {
        this.$router.push({ name: 'InnHome' })
      },
      onSelected(select) {
        console.log(select)
        this.material = select
        this.form.panoUrl = select.firstImage.url
        this.form.PanoId = select.guid
      },
      showPanoLibDialog() {
        this.$refs.PanoLib.showDialog();
      },
      idCardUploadFinished(file) {
        this.form.idCard = file;
        console.log('inCard', file)
      },
      businessLicenseUploadFinished(file) {
        this.form.businessLicense = file;
        console.log('businessLicense', file)
      },
      specialLicenseUploadFinished(file) {
        this.form.specialLicense = file;
        console.log('specialLicense', file)
      },

      fireLicenseUploadFinished(file) {
        this.form.fireLicense = file;
        console.log('fireLicense', file)
      },

      healthLicenseUploadFinished(file) {
        this.form.healthLicense = file;
        console.log('healthLicense', file)
      },

    },
    created() {
      this.loadData()
    },
    mounted() {
      var _this = this
      console.log(_this.$refs)
    },
    watch: {
      'form.address.area': function (val, oldval) {
        if (this.dontchangemap > 0) {
          this.dontchangemap--;
          return;
        }
        this.$refs.myMapbox.mapSetCity(val);
      }
    },
  }
</script>
<style lang="css">
</style>
