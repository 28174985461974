import Vue from 'vue'
import { Paging } from 'common'
import isObjectValueEqual from 'common/src/libs/isObjectValueEqual.js'
import formatTime from 'common/src/libs/formatTime.js'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
//import 'leaflet.chinatmsproviders/src/leaflet.ChineseTmsProviders.js'
import 'common/src/libs/leaflet.ChineseTmsProviders.js'
import 'leaflet.markercluster/dist/leaflet.markercluster-src.js'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import Viewer from 'v-viewer'
import VueLazyload from 'vue-lazyload'
//import BootstrapVue from 'bootstrap-vue'
import VueBootstrap4Table from 'vue-bootstrap4-table'
import Message from 'vue-m-message'
import Vuelidate from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import CKEditor from '@ckeditor/ckeditor5-vue';
import moment from 'moment'
import remoteLoad from 'common/src/libs/remoteLoad.js'
Vue.prototype.$remoteLoad = remoteLoad
moment.locale('zh-cn');
Vue.prototype.$moment = moment
Vue.prototype.$formatTime = formatTime
Vue.prototype.$isObjectValueEqual = isObjectValueEqual

Vue.prototype.$required = required
Vue.prototype.$minLength = minLength
Vue.prototype.$maxLength = maxLength

Vue.component('paging', Paging)
//Vue.component('SubmitButton', SubmitButton)
Vue.component('VueBootstrap4Table', VueBootstrap4Table)

//Vue.use(BootstrapVue)
Vue.use(Message) // will mount `Vue.prototype.$message`
Vue.use(Vuelidate)
Vue.use(CKEditor);
Vue.use(Viewer, {
  defaultOptions: {
    url: 'data-src',
    inline: false,
    button: true,
    navbar: false,
    title: true,
    toolbar: {
      zoomIn: 1,
      zoomOut: 1,
      oneToOne: 1,
      reset: 1,
      prev: false,
      play: false,
      next: false,
      rotateLeft: 1,
      rotateRight: 1,
      flipHorizontal: 1,
      flipVertical: 1,
    },
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
    //zIndexInline: 2017,
    //zIndex: 9999
  }
})
Vue.use(VueLazyload)
