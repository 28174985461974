import Vue from 'vue'
import Router from 'vue-router'
import mainAuth from './auth';

import NotFound from './views/NotFound.vue'
import NoAccess from './views/NoAccess.vue'
//import SigninOidc from './views/SigninOidc.vue'
//import SignoutOidc from './views/SignoutOidc.vue'
//import SilentRefresh from './views/SilentRefresh.vue'

import Default from './views/Default.vue'
import Inn from './views/Inn.vue'
import Home from './views/Home.vue'
import Setting from './views/Setting.vue'
import MenuList from './views/Menu/List.vue'
import CategoryList from './views/Category/List.vue'
import WeixinMenu from './views/Weixin/Menu.vue'
import UserList from './views/User/List.vue'
import RoleList from './views/Role/List.vue'
import TagList from './views/Tag/List.vue'
import ModuleList from './views/Module/List.vue'
import NoticeList from './views/Notice/List.vue'
import NoticeEdit from './views/Notice/Edit.vue'
import CommentList from './views/Comment/List.vue'
import AudioList from './views/Audio/List.vue'
import TopItemList from './views/TopItem/List.vue'
import MessageList from './views/Message/List.vue'
import LibList from './views/Lib/List.vue'

import ArticleList from './views/Article/List.vue'
import ArticleEdit from './views/Article/Edit.vue'

import BannerList from './views/Banner/List.vue'
import BannerEdit from './views/Banner/Edit.vue'

import PageList from './views/Page/List.vue'
import PageEdit from './views/Page/Edit.vue'

import PanoList from './views/Pano/List.vue'
import InnPanoList from './views/Inn/PanoList.vue'
import PanoEdit from './views/Pano/Edit.vue'
import PanoFullScreen from './views/PanoFullScreen.vue'
import HotspotIcon from './views/Pano/HotspotIcon.vue'

import BlogList from './views/Blog/List.vue'
import BlogEdit from './views/Blog/Edit.vue'

import TourList from './views/Tour/List.vue'
import TourEdit from './views/Tour/Edit.vue'

import ProductList from './views/Product/List.vue'

//import CardLogList from './pages/CardLogs.vue'
////import RoomList from './pages/Rooms.vue'
//import RoomStockLogList from './pages/RoomStockLogs.vue'
//import RoomApplyList from './pages/roomapply/RoomApplys.vue'
//import RoomApplyItemList from './pages/roomapply/RoomApplyItems.vue'
import AgentList from './views/Agent/List.vue'
import AgentEdit from './views/Agent/Edit.vue'

import GroupList from './views/Group/List.vue'
import GroupEdit from './views/Group/Edit.vue'

import InnHome from './views/Inn/Home.vue'
import InnList from './views/Inn/List.vue'
import InnEdit from './views/Inn/Edit.vue'
import InnView from './views/Inn/View.vue'
import InnLicenses from './views/Inn/Licenses.vue'
import InnRoomList from './views/Inn/Room.vue'
import InnRoomEdit from './views/Inn/RoomEdit.vue'
import InnOrderList from './views/Inn/Order.vue'
import InnTagList from './views/Inn/Tag.vue'
import RoomStockList from './views/Inn/RoomStock.vue'
import InnLib from './views/Inn/Lib.vue'
import CardList from './views/Inn/Card.vue'
import FacilityList from './views/Inn/Facility.vue'
import InnInfoList from './views/InnInfo/List.vue'
import InnInfoEdit from './views/InnInfo/Edit.vue'
import InnTopItemList from './views/Inn/InnTopItemList.vue'



import axiosTestPage from './views/axiosTestPage.vue'
//import ckeditorTestPage from './components/Editor.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "default" */ './views/Default.vue'),
      meta: {
        authName: mainAuth.authName
      },
      children: [
        //{
        //    path: '*',
        //    component: NotFoundComponent,
        //    meta: { authName: mainAuth.authName, title: '页面不存在' }
        //},
        {
          path: '',
          name: 'Home',
          component: Home,
          meta: { authName: mainAuth.authName, title: '主页' }
        },
        {
          path: 'Setting',
          name: 'Setting',
          component: Setting,
          meta: { authName: mainAuth.authName, title: '系统设置' }
        },
        {
          path: 'User',
          name: 'UserList',
          component: UserList,
          meta: { authName: mainAuth.authName, title: '用户管理' }
        },
        {
          path: 'Role',
          name: 'RoleList',
          component: RoleList,
          meta: { authName: mainAuth.authName, title: '角色管理' }
        },
        {
          path: 'Module',
          name: 'ModuleList',
          component: ModuleList,
          meta: { authName: mainAuth.authName, title: '模块管理' }
        },
        {
          path: 'Menu/:id',
          name: 'MenuList',
          component: MenuList,
          meta: { authName: mainAuth.authName, title: '菜单管理' }
        },
        {
          path: 'Category/',
          name: 'CategoryList',
          component: CategoryList,
          meta: { authName: mainAuth.authName, title: '分类管理' }
        },
        {
          path: 'Weixin/Menu',
          name: 'WeixinMenu',
          component: WeixinMenu,
          meta: { authName: mainAuth.authName, title: '菜单管理' }
        },
        {
          path: 'Article/:id',
          name: 'ArticleList',
          component: ArticleList,
          meta: { authName: mainAuth.authName, title: '文章管理' }
        },
        {
          path: 'Article/:categoryId/Edit/:articleId',
          name: 'ArticleEdit',
          component: ArticleEdit,
          meta: { authName: mainAuth.authName, title: '文章编辑' }
        },
        {
          path: 'Article/:categoryId/Edit',
          name: 'ArticleCreate',
          component: ArticleEdit,
          meta: { authName: mainAuth.authName, title: '新建文章' }
        },
        {
          path: 'Banner/:id',
          name: 'BannerList',
          component: BannerList,
          meta: { authName: mainAuth.authName, title: '横幅管理' }
        },
        {
          path: 'Banner/:categoryId/Edit/:bannerId',
          name: 'BannerEdit',
          component: BannerEdit,
          meta: { authName: mainAuth.authName, title: '横幅编辑' }
        },
        {
          path: 'Banner/:categoryId/Edit',
          name: 'BannerCreate',
          component: BannerEdit,
          meta: { authName: mainAuth.authName, title: '新建横幅' }
        },
        {
          path: 'Page/:id',
          name: 'PageList',
          component: PageList,
          meta: { authName: mainAuth.authName, title: '页面管理' }
        },
        {
          path: 'Page/:categoryId/Edit/:pageId',
          name: 'PageEdit',
          component: PageEdit,
          meta: { authName: mainAuth.authName, title: '页面编辑' }
        },
        {
          path: 'Page/:categoryId/Edit',
          name: 'PageCreate',
          component: PageEdit,
          meta: { authName: mainAuth.authName, title: '新建页面' }
        },
        {
          path: 'Pano',
          name: 'PanoList',
          component: PanoList,
          meta: { authName: mainAuth.authName, title: '全景管理' }
        },
        {
          path: 'Pano/Edit/:panoId',
          name: 'PanoEdit',
          component: PanoEdit,
          meta: { authName: mainAuth.authName, title: '全景编辑' }
        },
        {
          path: 'HotspotIcon',
          name: 'HotspotIconList',
          component: HotspotIcon,
          meta: { authName: mainAuth.authName, title: '全景图标管理' }
        },
        {
          path: 'Tour',
          name: 'TourList',
          component: TourList,
          meta: { authName: mainAuth.authName, title: '漫游管理' }
        },
        {
          path: 'Tour/Edit',
          name: 'TourCreate',
          component: TourEdit,
          meta: { authName: mainAuth.authName, title: '新建漫游' }
        },
        {
          path: 'Tour/Edit/:tourId',
          name: 'TourEdit',
          component: TourEdit,
          meta: { authName: mainAuth.authName, title: '漫游编辑' }
        },
        {
          path: 'Blog',
          name: 'BlogList',
          component: BlogList,
          meta: { authName: mainAuth.authName, title: '博文管理' }
        },
        {
          path: 'Blog/Edit',
          name: 'BlogCreate',
          component: BlogEdit,
          meta: { authName: mainAuth.authName, title: '新建博文' }
        },
        {
          path: 'Blog/Edit/:blogId',
          name: 'BlogEdit',
          component: BlogEdit,
          meta: { authName: mainAuth.authName, title: '博文编辑' }
        },
        {
          path: 'Comment',
          name: 'CommentList',
          component: CommentList,
          meta: { authName: mainAuth.authName, title: '留言管理' }
        },
        {
          path: 'Audio',
          name: 'AudioList',
          component: AudioList,
          meta: { authName: mainAuth.authName, title: '音频管理' }
        },
        {
          path: 'TopItem',
          name: 'TopItemList',
          component: TopItemList,
          meta: { authName: mainAuth.authName, title: '置顶项目' }
        },
        {
          path: 'Tag',
          name: 'TagList',
          component: TagList,
          meta: { authName: mainAuth.authName, title: '标签管理' }
        },
        {
          path: 'Notice',
          name: 'NoticeList',
          component: NoticeList,
          meta: { authName: mainAuth.authName, title: '通知管理' }
        },
        {
          path: 'Notice/Create',
          name: 'NoticeCreate',
          component: NoticeEdit,
          meta: { authName: mainAuth.authName, title: '创建通知' }
        },
        {
          path: 'Product',
          name: 'ProductList',
          component: ProductList,
          meta: { authName: mainAuth.authName, title: '产品管理' }
        },
        {
          path: 'Message',
          name: 'MessageList',
          component: MessageList,
          meta: { authName: mainAuth.authName, title: '聊天管理' }
        },
        {
          path: 'Lib',
          name: 'LibList',
          component: LibList,
          meta: { authName: mainAuth.authName, title: '素材库管理' }
        },
        //{
        //    path: 'CardLog',
        //    name: 'CardLogList',
        //    component: CardLogList,
        //    meta: { authName: mainAuth.authName, title: '卡日志管理' }
        //},
        {
          path: 'Card',
          name: 'CardList',
          component: CardList,
          meta: { authName: mainAuth.authName, title: '卡管理' }
        },
        {
          path: 'Facility',
          name: 'FacilityList',
          component: FacilityList,
          meta: { authName: mainAuth.authName, title: '服务设施' }
        },
        //{
        //    path: 'Inn/:innId/Rooms',
        //    name: 'InnRoom',
        //    component: InnRoomList,
        //    meta: { authName: mainAuth.authName, title: '房间管理' }
        //},
        //{
        //    path: 'Inn/:innId/Room/:roomId/Edit',
        //    name: 'InnRoomEdit',
        //    component: InnRoomEdit,
        //    meta: { authName: mainAuth.authName, title: '房间编辑' }
        //},
        //{
        //    path: 'Inn/:innId/Room/Create',
        //    name: 'InnRoomCreate',
        //    component: InnRoomEdit,
        //    meta: { authName: mainAuth.authName, title: '房间创建' }
        //},
        //{
        //    path: 'RoomStock/:roomId',
        //    name: 'RoomStock',
        //    component: RoomStockList,
        //    meta: { authName: mainAuth.authName, title: '房间库存管理' }
        //},
        //{
        //    path: 'RoomStockLog',
        //    name: 'RoomStockLogList',
        //    component: RoomStockLogList,
        //    meta: { authName: mainAuth.authName, title: '房间库存日志' }
        //},
        {
          path: 'InnOrder',
          name: 'InnOrderList',
          component: InnOrderList,
          meta: { authName: mainAuth.authName, title: '订单管理' }
        },
        {
          path: 'Order',
          name: 'OrderList',
          component: () => import(/* webpackChunkName: "order-list" */ './views/Order/List.vue'),
          meta: { authName: mainAuth.authName, title: '订单管理' }
        },
        {
          path: 'Agent',
          name: 'AgentList',
          component: AgentList,
          meta: { authName: mainAuth.authName, title: '代理管理' }
        },
        {
          path: 'Agent/Edit/:agentId',
          name: 'AgentEdit',
          component: AgentEdit,
          meta: { authName: mainAuth.authName, title: '编辑代理' }
        },
        {
          path: 'Agent/Edit',
          name: 'AgentCreate',
          component: AgentEdit,
          meta: { authName: mainAuth.authName, title: '新建代理' }
        },
        {
          path: 'Group',
          name: 'GroupList',
          component: GroupList,
          meta: { authName: mainAuth.authName, title: '用户组管理' }
        },
        {
          path: 'Group/Edit/:groupId',
          name: 'GroupEdit',
          component: GroupEdit,
          meta: { authName: mainAuth.authName, title: '编辑用户组' }
        },
        {
          path: 'Group/Edit',
          name: 'GroupCreate',
          component: GroupEdit,
          meta: { authName: mainAuth.authName, title: '新建用户组' }
        },
        {
          path: 'Inn',
          name: 'InnList',
          component: InnList,
          meta: { authName: mainAuth.authName, title: '客栈管理' }
        },
        {
          path: 'InnEdit',
          name: 'InnCreate',
          component: InnEdit,
          meta: { authName: mainAuth.authName, title: '新建客栈' }
        },
        {
          path: 'InnInfo/:id',
          name: 'InnInfoList',
          component: InnInfoList,
          meta: { authName: mainAuth.authName, title: '信息管理' }
        },
        {
          path: 'InnInfo/Edit',
          name: 'InnInfoCreate',
          component: InnInfoEdit,
          meta: { authName: mainAuth.authName, title: '新建信息' }
        },
        {
          path: 'InnInfo/Edit/:infoId',
          name: 'InnInfoEdit',
          component: InnInfoEdit,
          meta: { authName: mainAuth.authName, title: '信息编辑' }
        },
        {
          path: 'InnTopItem',
          name: 'InnTopItemList',
          component: InnTopItemList,
          meta: { authName: mainAuth.authName, title: '置顶管理' }
        },
        //{
        //    path: 'InnEdit/:innId',
        //    name: 'InnEdit',
        //    component: InnEdit,
        //    meta: { authName: mainAuth.authName, title: '客栈编辑' }
        //},
        //{
        //    path: 'roomapply/roomapplys',
        //    name: 'RoomApplyList',
        //    component: RoomApplyList,
        //    meta: { authName: mainAuth.authName, title: '预定申请' }
        //},
        //{
        //    path: 'roomapply/roomapplyitems',
        //    name: 'RoomApplyItemList',
        //    component: RoomApplyItemList,
        //    meta: { authName: mainAuth.authName, title: '预定申请子表' }
        //}
      ]
    },
    {
      path: '/Inn/:innId',
      component: Inn,
      children: [
        {
          path: '',
          name: 'InnHome',
          component: InnHome,
          meta: { authName: mainAuth.authName, title: '主页' }
        },
        {
          path: 'Lib',
          name: 'InnLibList',
          component: InnLib,
          meta: { authName: mainAuth.authName, title: '素材库管理' }
        },
        {
          path: 'Pano',
          name: 'InnPanoList',
          component: InnPanoList,
          meta: { authName: mainAuth.authName, title: '全景管理' }
        },
        {
          path: 'Pano/Edit/:panoId',
          name: 'InnPanoEdit',
          component: PanoEdit,
          meta: { authName: mainAuth.authName, title: '全景编辑' }
        }, {
          path: 'Edit',
          name: 'InnEdit',
          component: InnEdit,
          meta: { authName: mainAuth.authName, title: '客栈编辑' }
        },
        {
          path: 'View',
          name: 'InnView',
          component: InnView,
          meta: { authName: mainAuth.authName, title: '客栈查看' }
        },
        {
          path: 'Licenses',
          name: 'InnLicenses',
          component: InnLicenses,
          meta: { authName: mainAuth.authName, title: '客栈证件' }
        },
        {
          path: 'Room',
          name: 'InnRoomList',
          component: InnRoomList,
          meta: { authName: mainAuth.authName, title: '房间管理' }
        },
        {
          path: 'Room/:roomId/Edit',
          name: 'InnRoomEdit',
          component: InnRoomEdit,
          meta: { authName: mainAuth.authName, title: '房间编辑' }
        },
        {
          path: 'Room/Create',
          name: 'InnRoomCreate',
          component: InnRoomEdit,
          meta: { authName: mainAuth.authName, title: '房间创建' }
        },
        {
          path: 'RoomStock/:roomId',
          name: 'RoomStockList',
          component: RoomStockList,
          meta: { authName: mainAuth.authName, title: '房间库存管理' }
        },
        {
          path: 'Tag',
          name: 'InnTagList',
          component: InnTagList,
          meta: { authName: mainAuth.authName, title: '标签管理' }
        },
        {
          path: 'Order',
          name: 'InnOrderList',
          component: InnOrderList,
          meta: { authName: mainAuth.authName, title: '订单管理' }
        },
        {
          path: 'NoAccess',
          name: 'NoAccess',
          component: NoAccess,
          meta: { authName: mainAuth.authName, title: '无权访问' }
        },

      ]
    },
    {
      path: '/Pano/:panoId',
      name: 'PanoFullScreen',
      component: PanoFullScreen,
      meta: { authName: mainAuth.authName, title: '全景查看' }
    },
    //{
    //    path: '/signin-oidc',
    //    component: SigninOidc,
    //    meta: { authName: mainAuth.authName, title: '登录' }
    //},
    //{
    //    path: '/signout-oidc',
    //    name: 'SignOut',
    //    component: SignoutOidc,
    //    meta: { authName: mainAuth.authName, title: '注销' }
    //},
    //{
    //    path: '/silent-refresh',
    //    component: SilentRefresh,
    //    meta: { authName: mainAuth.authName, title: '刷新 Token' }
    //},
    //{
    //    path: '/ck',
    //    component: ckeditorTestPage,
    //    meta: { authName: mainAuth.authName, title: '测试页' }
    //},
    {
      path: '/test',
      component: axiosTestPage,
      meta: { authName: mainAuth.authName, title: '测试页' }
    },
    //{
    //    path: '*',
    //    component: Default,
    //    children: [
    //        {
    //            path: '',
    //            name: 'NotFound',
    //            component: NotFound,
    //            meta: { authName: mainAuth.authName, title: '404 文件未找到' }
    //        },
    //    ]
    //},
  ],
  //linkActiveClass: 'active'
  //linkExactActiveClass: 'active'
})

mainAuth.useRouter(router);

export default router;
