<template lang="html">
  <div>
    <div class="card card-primary card-outline box-primary">
      <!--<div class="overlay" v-if="$store.state.loading">
          <i class="fa fa-refresh fa-spin"></i>
      </div>-->
      <div class="card-header p-1">
        <h3 class="card-title">页面编辑</h3>
      </div>
      <div class="card-body">
        <span v-if="updateing">加载中</span>
        <template>
          <div class="form-group">
            <label for="title">标题</label>
            <input type="text" class="form-control" id="title" placeholder="输入标题" v-model.trim:value="form.title">
          </div>
          <div class="form-group">
            <label for="pageName">页面名称</label>
            <input type="text" class="form-control" id="pageName" placeholder="输入页面名称" v-model.trim:value="form.pageName" maxlength="20">
          </div>
          <div class="form-group">
            <label for="url">链接</label>
            <input type="text" class="form-control" id="url" placeholder="输入 Url" v-model.trim:value="form.url">
          </div>
          <editor ref="editor"
                  v-model="form.text"
                  :onUploadComplete="onUploadComplete"
                  @uploadfile="uploadfile"
                  placeholder="在这里输入内容!" />
          <EditorFileList ref="EditorFileList" :editor="$refs.editor" :followEl="$refs.editor" :images="form.images" :attachments="form.attachments" :bottom="55"></EditorFileList>
          <div v-if="!form.url" class="form-group">
            <label for="description">摘要</label>
            <textarea type="text" class="form-control" id="description" placeholder="输入摘要" v-model.trim:text="form.description"></textarea>
          </div>
          <div v-if="!form.url" class="form-group">
            <label for="writer">作者</label>
            <input type="text" class="form-control" id="writer" placeholder="输入作者" v-model.trim:text="form.writer" />
          </div>
          <div v-if="!form.url" class="form-group">
            <label for="writerFrom">来源</label>
            <input type="text" class="form-control" id="writerFrom" placeholder="输入来源" v-model.trim:text="form.writerFrom" />
          </div>
          <div class="form-group">
            <label for="panoTitle">标签</label>
            <CheckBox checkprop="name"
                      :checkList="tags"
                      :selected="form.tags"
                      :onChange="tagsCheckOnChange">
            </CheckBox>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="form.closed">
              关闭
            </label>
          </div>
        </template>
      </div>
      <div class="card-footer">
      </div>
    </div>
    <Footer>
      <button type="button" class="btn btn-default mr-2" @click.prevent="cancel">取消</button>
      <button type="button" class="btn btn-primary" @click.prevent="save()" :disabled="updateing">保存</button>
    </Footer>
    <BreakLeave v-if="cantleave"></BreakLeave>
  </div>
</template>

<script>
  import { EditorFileList, CheckBox, Footer, BreakLeave } from 'common'
  import Editor from '@/components/Editor.vue'
  export default {
    components: {
      Editor,
      CheckBox,
      Footer,
      EditorFileList,
      BreakLeave
    },
    data() {
      return {
        id: this.$route.params.pageId,
        form: {
          guid: null,
          categoryGUID: this.$route.params.categoryId,
          title: '',
          text: '',
          description: '',
          writer: '',
          writerFrom: '',
          addTime: null,
          tags: [],
          images: [],
          attachments: [],
          cover: null,
          closed: false,
        },
        coverImg: null,
        fov: 120,
        hLookAt: 0,
        vLookAt: 0,
        hotspots: [],
        //distortion: 0,
        tags: [],
        updateing: false,
        imageOnDrag: null,
        imageDropIndex: null,
        cantleave: true,
      }
    },
    created() {
      this.loadData()
      this.loadTags()
    },
    mounted() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
      loadData() {
        console.log(this.id)
        if (this.id) {
          this.$axios.get('/Api/Admin/Pages/' + this.id)
            .then((response) => {
              //console.log(response)
              let val = response.data
              this.form = val;
            })
            .catch((error) => {
              console.log(error)
            })
        }
      },
      loadTags() {
        this.$axios.get('/Api/Tags/All')
          .then((response) => {
            //console.log(response)
            this.tags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      uploadfile(resolve, reject, file) {
        try {
          var fe = {
            target: { files: [file] }
          }
          this.$refs.EditorFileList.$refs.EditorImgUpload.$refs.Upload.addFile(fe, {
            onFailed: (file, err) => {
              reject(err)
            },
            onSuccess: (response) => {
              console.log(response)
              resolve({ default: response.url.replace('/0/0/0/0/', '/800/0/0/0/') })
            }
          });
        } catch (e) {
          reject('程序错误')
        }
      },
      onUploadComplete(date) {
        console.log(date)
        this.form.images = [...this.form.images, date]
      },
      save() {
        this.updateing = true;
        if (this.id) {
          this.$axios.put('/Api/Admin/Pages/' + this.form.guid, this.form)
            .then((response) => {
              this.cantleave = false
              this.Close()
              this.updateing = false;
            })
            .catch((error) => {
              console.log(error)
              this.updateing = false;
            })

        } else {
          this.$axios.post('/Api/Admin/Pages', this.form)
            .then((response) => {
              this.cantleave = false
              this.Close()
              this.updateing = false;
            })
            .catch((error) => {
              console.log(error)
              this.updateing = false;
            })
        }
      },
      cancel() {
        if (confirm("确定退出吗，未保存的内容将丢失")) {
          this.Close()
        } else {
          return
        }
      },
      Close() {
        this.cantleave = false
        setTimeout(() => {
          this.$router.push({ name: 'PageList', params: { id: this.form.categoryGUID } })
        }, 1)
      },
      tagsCheckOnChange(list) {
        console.log(list)
        this.form.tags = [...list]
      },
    },
    watch: {
    },
  }
</script>
