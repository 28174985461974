<template>
    <div class="card card-primary card-outline p-1 weixinMueu">
        <div class="btn-group">
            <button class="btn btn-default wid3" @click="loadData" :disabled="loading" title="刷新">刷新</button>
            <button class="btn btn-default wid3" @click="save" :disabled="updateing" title="保存">保存</button>
            <button class="btn btn-default wid3" @click="del" :disabled="updateing" title="删除">删除</button>
        </div>
        <hr />
        <div class="row">
            <div v-if="item" class="col-12 col-md-6 col-lg-5 col-xl-4 mb-2">
                <div style="height: 200px;display: flex;align-items: flex-end;">
                    <div :class="{'wid1':item.button.length == 0,'wid2':item.button.length == 1,'wid3':item.button.length >= 2,}" style="position:relative" v-for="(b,bi) in item.button">
                        <template v-if="b.type==null">
                            <div class="btn-group-vertical mb-2 w-100">
                                <template v-for="(sb,sbi) in b.sub_button||[]">
                                    <button class="btn form-control px-1" :class="{'btn-info':buttonIndex==bi&&subButtonIndex==sbi,'btn-default':!(buttonIndex==bi&&subButtonIndex==sbi)}" @click="buttonIndex=bi;subButtonIndex=sbi">{{sb.name}}</button>
                                </template>
                                <button class="btn btn-default form-control" @click="b=addSubButton(b)" v-if="b.sub_button.length<5" title="添加"><i class="fas fa-plus"></i></button>
                            </div>
                        </template>
                        <!--<button class="btn btn-default form-control" :class="{'btn-info':buttonIndex==bi&&subButtonIndex==null}" @click="buttonIndex=bi;subButtonIndex=null">{{b.name}}</button>-->
                        <div v-if="b.type==null" class="arrowdown"></div>
                    </div>
                    <div :class="{'wid1':item.button.length == 0,'wid2':item.button.length == 1,'wid3':item.button.length == 2,}" v-if="item.button.length < 3">
                        <!--<button class="btn btn-default form-control" @click="addButton()"><i class="fas fa-plus"></i></button>-->
                    </div>
                </div>
                <div class="btn-group w-100">
                    <template v-for="(b,bi) in item.button">
                        <!--<template v-if="b.type==null">
                            <div class="btn-group-vertical mb-2 w-100">
                                <template v-for="(sb,sbi) in b.sub_button||[]">
                                    <button class="btn btn-default form-control" :class="{'btn-info':buttonIndex==bi&&subButtonIndex==sbi}" @click="buttonIndex=bi;subButtonIndex=sbi">{{sb.name}}</button>
                                </template>
                                <button class="btn btn-default form-control" @click="b=addSubButton(b)" v-if="b.sub_button.length<5"><i class="fas fa-plus"></i></button>
                            </div>
                        </template>-->
                        <button class="btn form-control px-1" :class="{'wid1':item.button.length == 0,'wid2':item.button.length == 1,'wid3':item.button.length >= 2,'btn-info':buttonIndex==bi&&subButtonIndex==null,'btn-default':!(buttonIndex==bi&&subButtonIndex==null)}" @click="buttonIndex=bi;subButtonIndex=null">{{b.name}}</button>
                    </template>
                    <button :class="{'wid1':item.button.length == 0,'wid2':item.button.length == 1,'wid3':item.button.length == 2,}" v-if="item.button.length < 3" class="btn btn-default form-control" @click="addButton()" title="添加"><i class="fas fa-plus"></i></button>
                </div>
            </div>
            <div v-if="item" class="col-12 col-md-6 col-lg-7 col-xl-8">
                <div class="panel panel-default" v-for="(b,bi) in item.button" v-if="bi==buttonIndex">
                    <div class="panel-body">
                        <template v-if="subButtonIndex==null">
                            <div>
                                <button class="mr-2 btn btn-danger" @click="item.button.splice(bi,1)" title="删除"><i class="fas fa-trash-alt"></i></button>
                                <button class="mr-2 btn btn-default" @click="moveItem(item.button,bi,bi-1);buttonIndex=bi-1" :disabled="bi==0" title="左移"><i class="fas fa-caret-left"></i></button>
                                <button class="mr-2 btn btn-default" @click="moveItem(item.button,bi,bi+1);buttonIndex=bi+1" :disabled="bi==item.button.length-1" title="右移"><i class="fas fa-caret-right"></i></button>
                            </div>
                            <div class="form-group">
                                <label for="Name">名称(一级菜单最多4个汉字)</label>
                                <input type="text" class="form-control" id="Name" placeholder="名称" v-model.trim="b.name" />
                            </div>
                            <div class="form-group">
                                <label for="Type">类型</label>
                                <select type="text" class="form-control" id="Type" placeholder="类型" v-model="b.type">
                                    <option :value="null">子菜单</option>
                                    <option v-for="t in typeList" :value="t.value" :title="t.text">{{t.name}}</option>
                                </select>
                            </div>
                            <template v-if="b.type==null">
                                <!--<button class="btn btn-default" @click="b=addSubButton(b)">添加子菜单</button>-->
                            </template>
                            <template v-if="b.type=='click'||b.type=='scancode_push'||b.type=='scancode_waitmsg'||b.type=='pic_sysphoto'||b.type=='pic_photo_or_album'||b.type=='pic_weixin'||b.type=='location_select'">
                                <div class="form-group">
                                    <label for="Key">菜单 Key 值</label>
                                    <input type="text" class="form-control" id="Key" placeholder="Key" v-model.trim="b.key" />
                                </div>
                            </template>
                            <template v-if="b.type=='view'">
                                <div class="form-group">
                                    <label for="Url">网页链接</label>
                                    <input type="text" class="form-control" id="Url" placeholder="Url" v-model.trim="b.url" />
                                </div>
                            </template>
                            <template v-if="b.type=='media_id'||b.type=='view_limited'">
                                <div class="form-group">
                                    <label for="Media_id">调用新增永久素材接口返回的合法 media_id</label>
                                    <input type="text" class="form-control" id="Media_id" placeholder="Media_id" v-model.trim="b.media_id" />
                                </div>
                            </template>
                            <template v-if="b.type=='miniprogram'">
                                <div class="form-group">
                                    <label for="Url">网页链接</label>
                                    <input type="text" class="form-control" id="Url" placeholder="Url" v-model.trim="b.url" />
                                </div>
                                <div class="form-group">
                                    <label for="appid">小程序的 AppId</label>
                                    <input type="text" class="form-control" id="appid" placeholder="AppId" v-model.trim="b.appid" />
                                </div>
                                <div class="form-group">
                                    <label for="pagepath">小程序的页面路径</label>
                                    <input type="text" class="form-control" id="pagepath" placeholder="小程序的页面路径" v-model.trim="b.pagepath" />
                                </div>
                            </template>
                        </template>
                        <!--子项-->
                        <template v-else>
                            <template v-if="b.type==null">
                                <div class="" v-for="(sb,sbi) in b.sub_button||[]" v-if="subButtonIndex==sbi">
                                    <div class="">
                                        <div>
                                            <button class="mr-2 btn btn-danger pull-right" @click="b.sub_button.splice(sbi,1)" title="删除"><i class="fas fa-trash-alt"></i></button>
                                            <button class="mr-2 btn btn-default" @click="moveItem(b.sub_button,sbi,sbi-1);subButtonIndex=sbi-1" :disabled="sbi==0" title="上移"><i class="fas fa-caret-up"></i></button>
                                            <button class="mr-2 btn btn-default" @click="moveItem(b.sub_button,sbi,sbi+1);subButtonIndex=sbi+1" :disabled="sbi==b.sub_button.length-1" title="下移"><i class="fas fa-caret-down"></i></button>
                                        </div>
                                        <div class="form-group">
                                            <label for="Name">名称(二级菜单最多7个汉字)</label>
                                            <input type="text" class="form-control" id="Name" placeholder="名称" v-model.trim="sb.name" />
                                        </div>
                                        <div class="form-group">
                                            <label for="Type">类型</label>
                                            <select type="text" class="form-control" id="Type" placeholder="类型" v-model="sb.type">
                                                <option v-for="t in typeList" :value="t.value" :title="t.text">{{t.name}}</option>
                                            </select>
                                        </div>
                                        <template v-if="sb.type=='click'||sb.type=='scancode_push'||sb.type=='scancode_waitmsg'||sb.type=='pic_sysphoto'||sb.type=='pic_photo_or_album'||sb.type=='pic_weixin'||sb.type=='location_select'">
                                            <div class="form-group">
                                                <label for="Key">菜单 Key 值</label>
                                                <input type="text" class="form-control" id="Key" placeholder="Key" v-model.trim="sb.key" />
                                            </div>
                                        </template>
                                        <template v-if="sb.type=='view'">
                                            <div class="form-group">
                                                <label for="Url">网页链接</label>
                                                <input type="text" class="form-control" id="Url" placeholder="Url" v-model.trim="sb.url" />
                                            </div>
                                        </template>
                                        <template v-if="sb.type=='media_id'||sb.type=='view_limited'">
                                            <div class="form-group">
                                                <label for="Media_id">调用新增永久素材接口返回的合法 media_id</label>
                                                <input type="text" class="form-control" id="Media_id" placeholder="Media_id" v-model.trim="sb.media_id" />
                                            </div>
                                        </template>
                                        <template v-if="sb.type=='miniprogram'">
                                            <div class="form-group">
                                                <label for="Url">网页链接</label>
                                                <input type="text" class="form-control" id="Url" placeholder="Url" v-model.trim="sb.url" />
                                            </div>
                                            <div class="form-group">
                                                <label for="appid">小程序的 AppId</label>
                                                <input type="text" class="form-control" id="appid" placeholder="AppId" v-model.trim="sb.appid" />
                                            </div>
                                            <div class="form-group">
                                                <label for="pagepath">小程序的页面路径</label>
                                                <input type="text" class="form-control" id="pagepath" placeholder="小程序的页面路径" v-model.trim="sb.pagepath" />
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                        </template>

                    </div>

                </div>
            </div>

        </div>
        <details>
            <summary>JSON</summary>
            <pre>{{item}}</pre>
        </details>
    </div>
</template>
<script>
    import { Modal } from 'common'
    //import Modal from '@/components/Modal.vue'

    export default {
        data() {
            return {
                item: null,
                typeList: [
                    { value: 'click', name: '点击事件', text: '点击推事件用户点击click类型按钮后，微信服务器会通过消息接口推送消息类型为event的结构给开发者（参考消息接口指南），并且带上按钮中开发者填写的key值，开发者可以通过自定义的key值与用户进行交互；' },
                    { value: 'view', name: '视图', text: '跳转URL用户点击view类型按钮后，微信客户端将会打开开发者在按钮中填写的网页URL，可与网页授权获取用户基本信息接口结合，获得用户基本信息。' },
                    { value: 'scancode_push', name: '扫码推事件', text: '扫码推事件用户点击按钮后，微信客户端将调起扫一扫工具，完成扫码操作后显示扫描结果（如果是URL，将进入URL），且会将扫码的结果传给开发者，开发者可以下发消息。' },
                    { value: 'scancode_waitmsg', name: '扫码并等待', text: '扫码推事件且弹出“消息接收中”提示框用户点击按钮后，微信客户端将调起扫一扫工具，完成扫码操作后，将扫码的结果传给开发者，同时收起扫一扫工具，然后弹出“消息接收中”提示框，随后可能会收到开发者下发的消息。' },
                    { value: 'pic_sysphoto', name: '拍照', text: '弹出系统拍照发图用户点击按钮后，微信客户端将调起系统相机，完成拍照操作后，会将拍摄的相片发送给开发者，并推送事件给开发者，同时收起系统相机，随后可能会收到开发者下发的消息。' },
                    { value: 'pic_photo_or_album', name: '拍照或相册', text: '弹出拍照或者相册发图用户点击按钮后，微信客户端将弹出选择器供用户选择“拍照”或者“从手机相册选择”。用户选择后即走其他两种流程。' },
                    { value: 'pic_weixin', name: '相册', text: '弹出微信相册发图器用户点击按钮后，微信客户端将调起微信相册，完成选择操作后，将选择的相片发送给开发者的服务器，并推送事件给开发者，同时收起相册，随后可能会收到开发者下发的消息。' },
                    { value: 'location_select', name: '地理位置选择', text: '弹出地理位置选择器用户点击按钮后，微信客户端将调起地理位置选择工具，完成选择操作后，将选择的地理位置发送给开发者的服务器，同时收起位置选择工具，随后可能会收到开发者下发的消息。' },
                    { value: 'media_id', name: '下发消息', text: '下发消息（除文本消息）用户点击media_id类型按钮后，微信服务器会将开发者填写的永久素材id对应的素材下发给用户，永久素材类型可以是图片、音频、视频、图文消息。请注意：永久素材id必须是在“素材管理/新增永久素材”接口上传后获得的合法id。' },
                    { value: 'view_limited', name: '跳转图文消息', text: '跳转图文消息URL用户点击view_limited类型按钮后，微信客户端将打开开发者在按钮中填写的永久素材id对应的图文消息URL，永久素材类型只支持图文消息。请注意：永久素材id必须是在“素材管理/新增永久素材”接口上传后获得的合法id。' },
                    { value: 'miniprogram', name: '小程序', text: '小程序' }
                ],
                buttonIndex: null,
                subButtonIndex: null,
                loading: false,
                updateing: false,

            }
        },
        created() {
            this.loadData()
        },
        mounted() {
        },
        methods: {
            moveItem(array, from, to) {
                var item = array[from]
                if (to < 0 || to > array.length || item == null) {
                    this.$message({
                        message: '移动错误',
                        type: 'error',
                        zIndex: 9999,
                    })
                    return
                }
                array.splice(from, 1)
                array.splice(to, 0, item)
            },
            newButton() {
                return { name: '', type: null, sub_button: [], key: null, url: null, media_id: null, appid: null, pagepath: null }
            },
            addButton() {
                if (this.item.button.length < 3) {
                    this.item.button.push(this.newButton())
                    this.buttonIndex = this.item.button.length - 1;

                } else {
                    console.log('达到上限')
                }
            },
            setedit(bi, sbi) {
                this.buttonIndex = bi;
                this.subButtonIndex = sbi;
            },
            addSubButton(Button) {
                console.log(Button)
                if (Button.sub_button) {
                    if (Button.sub_button.length < 5) {
                        Button.sub_button.push(this.newButton())
                        this.subButtonIndex = Button.sub_button.length - 1;
                    } else {
                        console.log('达到上限')
                    }
                }
                else {
                    Button = { ...Button, sub_button: [this.newButton()] }
                    this.subButtonIndex = 1;
                }
                return Button
            },
            loadData() {
                var _this = this;
                _this.loading = true;
                this.$axios.get('/Api/Weixin/Menu')
                    .then(res => {
                        _this.item = _this.rebuidData(res.data)
                        _this.loading = false;
                    })
                    .catch(err => {
                        _this.$message({
                            message: err,
                            type: 'error',
                            zIndex: 9999,
                            //showClose: true
                        })
                        _this.item = { button: [] }
                        _this.loading = false;
                    })
            },
            rebuidData(data) {
                var item = { button: [] }
                for (var l in data.button) {
                    var nb = this.newButton()
                    for (var li in data.button[l]) {
                        nb[li] = data.button[l][li]
                    }
                    item.button.push(nb)
                }
                return item;
            },
            save() {
                var _this = this;
                _this.updateing = true;
                var item = _this.item;
                for (var i in item.button) {
                    if (item.button[i].type)
                        delete item.button[i].sub_button
                }
                this.$axios.post('/Api/Weixin/Menu', item).then(res => {
                    _this.$message({
                        message: '保存成功！',
                        type: 'success',
                        zIndex: 9999,
                        //showClose: true
                    })
                    _this.updateing = false;
                }).catch(err => {
                    _this.$message({
                        message: err,
                        type: 'error',
                        zIndex: 9999,
                        //showClose: true
                    })
                    _this.updateing = false;
                })
            },
            del() {
                var _this = this;
                _this.updateing = true;
                this.$axios.delete('/Api/Weixin/Menu').then(res => {
                    _this.$message({
                        message: '删除成功！',
                        type: 'success',
                        zIndex: 9999,
                        //showClose: true
                    })
                    _this.updateing = false;
                }).catch(err => {
                    _this.$message({
                        message: err,
                        type: 'error',
                        zIndex: 9999,
                        //showClose: true
                    })
                    _this.updateing = false;
                })

            }
        },
        computed: {
        },
        components: {
            Modal
        },
    }
</script>

<style lang="css">
    .arrowdown {
        position: absolute;
        display: block;
        width: 1rem;
        height: .5rem;
        margin: 0 .3rem;
        bottom: calc((.5rem + 1px) * -1 + 9px);
        left: calc(50% - 13px);
    }

        .arrowdown::after, .arrowdown::before {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }

        .arrowdown::after {
            bottom: 1px;
            border-width: .5rem .5rem 0;
            border-top-color: #f8f9fa;
        }

        .arrowdown::before {
            bottom: 0;
            border-width: .5rem .5rem 0;
            border-top-color: rgba(0,0,0,.25);
        }


    .weixinMueu .wid1 {
        width: 100%;
        vertical-align: bottom;
        display: inline-block;
    }

    .weixinMueu .wid2 {
        width: 50%;
        vertical-align: bottom;
        display: inline-block;
    }

    .weixinMueu .wid3 {
        width: 33.33%;
        vertical-align: bottom;
        display: inline-block;
    }
</style>
