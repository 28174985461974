<template>
  <div v-if="loaded" class="PanoFullScreen">
    <Pano :baseData="{form}" :skinData="skinData" @changePano="changePano"></Pano>
  </div>
  <div v-else>
    <i class="fas fa-spinner fa-pulse"></i>
    加载中…
  </div>
</template>
<script>
  import { Pano } from 'common'
  import Skin from 'common/src/components/PanoSkins/EditTourSkin.json'
  export default {
    scrollToTop: true,
    components: {
      Pano
    },
    data() {
      return {
        loaded: false,
        form: null,
        skinData: Skin
      }
    },
    computed: {
    },
    watch: {
    },
    created() {
    },
    mounted() {
      for (var i in this.skinData.sences) {
        this.skinData.sences[i].components.push({
          name: 'AdminUrls'
        })
      }
      this.loadData()
    },
    destroyed() {
    },
    methods: {
      changePano(pano) {
        this.pano = pano
      },
      loadData() {
        if (this.$route.params.tourId) {
          this.$axios.get('/Api/Admin/Tours/' + this.$route.params.tourId)
            .then((response) => {
              let val = response.data
              if (val.title == '无标题') val.title = null;
              this.form = val;
              this.loaded = true;
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          this.form = this.newTour()
          this.loaded = true;
        }
      },
      newTour() {
        return {
          guid: null,
          categoryGuid: null,
          title: '',
          text: '',
          featured: false,
          skin: '',
          blend: null,
          closed: false,
          audioAutoPlay: true,
          audioLoop: false,
          private: false,
          introImage: null,
          tags: [],
          groups: [],
          items: [],
          links: [],
          cover: null,
          audio: null,
          user: {},
          gpsLat: 0,
          gpsLng: 0,
          hideAvatar: false,
          hideLogo: false,
          hideCount: false,
          time: '尚未'
        }
      }
    },
  }
</script>
<style scoped>
  .PanoFullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
  }
</style>
