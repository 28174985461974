<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <router-link :to="{ name: 'Home'}" class="brand-link" style="overflow: hidden;">
            <img src="../assets/logo.png"
                 alt="AdminLTE Logo"
                 class="brand-image img-circle elevation-3"
                 style="opacity: .8">
            <span class="brand-text font-weight-light">管理中心</span>
        </router-link>

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar user (optional) -->
            <!--<div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="image">
                    <img src="/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
                </div>
                <div class="info">
                    <a href="#" class="d-block">Alexander Pierce</a>
                </div>
            </div>-->
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul v-if="slideMenuItems" class="nav nav-pills nav-sidebar flex-column nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
                    <!-- Add icons to the links using the .nav-icon class
                    with font-awesome or any other icon font library -->
                    <va-slide-item v-for="(item,index) in slideMenuItems"
                                   :data="item"
                                   :key="index"
                                   :type="item.type"
                                   :isHeader="item.isHeader"
                                   :icon="item.icon"
                                   :name="item.name"
                                   :badge="item.badge"
                                   :items="item.items"
                                   :router="item.router"
                                   :link="item.link">
                    </va-slide-item>
                </ul>
                <ul v-else class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item">
                        <a class="nav-link">
                            <i class="nav-icon fas fa-spinner fa-pulse"></i>
                            <p>
                                载入中…
                            </p>
                        </a>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>

<script>
    import { mapGetters } from 'vuex'
    import VASlideItem from '@/components/VASlideItem'

    export default {
        name: 'va-slider',
        props: {
            slideMenuItems: {
                type: Array,
                default: []
            }
        },
        created() {
        },
        computed: {
            ...mapGetters([
                'currentUser'
            ]),
            avatarUrl() {
                var url = this.currentUser.avatarUrl
                if (url) {
                    return url.replace('/0/0/0/0/', '/100/100/1/0/')
                }
                return "/images/avatar-default.jpg"
            }

        },
        components: {
            'va-slide-item': VASlideItem
        }
    }
</script>
