<template>
    <div class="PanoFullScreen" :class="{hideTool:hideTool}">
        <Krpano ref="Pano" :pano-id="(pano||{}).guid" height="100%" :krpanoSettings="krpanoSettings" :on-krpano-ready="onKrpanoReady" :sceneVars="sceneVars" :onLoadComplete="onXmlComplete">
            <Scale ref="Scale" :zoom="1" :mobile-only="true">
                <template v-if="pano">
                    <div class="userA" style="pointer-events:none">
                        <div class="avatar" style="pointer-events:auto">
                            <div class="text-center">
                                <router-link :to="{name:'Home'}">
                                    <img v-if="pano.user.avatarUrl" :src="pano.user.avatarUrl.replace('/0/0/0/0/', '/200/200/1/0/')">
                                    <img v-else src="/images/avatar-default.jpg">
                                </router-link>
                            </div>
                            <div class="text-center text-truncate">
                                <router-link class="text-shadow" :to="{name:'Home'}">
                                    {{ pano.user.nickName }}
                                </router-link>
                            </div>
                        </div>
                        <div class="">
                            <div style="margin-top:10px;line-height:2rem;font-size:1rem">
                                <span class="text-shadow" title="观看"><i class="fas fa-eye" /> {{ pano.viewCount }}</span><br>
                                <span class="text-shadow" title="点赞"><i class="fas fa-thumbs-up" /> {{ pano.praiseCount }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="toolbar text-shadow">
                        <!--<i class="fas fa-comment-alt"></i>
                        <i class="fas fa-info-circle"></i>
                        <i class="fas fa-map-marker-alt"></i>
                        <span style="font-size:12px">测试文字</span>-->
                        <!--<button @click="loadpano">
                          小行星视角
                        </button>
                        <button @click="autorotate">
                          开始旋转
                        </button>
                        <button @click="stopautorotate">
                          停止旋转
                        </button>
                        <button @click="addSnow()">
                          下雪
                        </button>
                        <button @click="addRain()">
                          下雨
                        </button>
                        <button @click="removeSnow">
                          停止天气
                        </button>
                        <button @click="setHideTool">
                          隐藏界面
                        </button>-->
                    </div>
                    <div class="righttop text-shadow text-center">
                        <!--<div>
                          <i class="fas fa-vr-cardboard" title="WebVR" @click="addwebvr" />
                        </div>
                        <div>
                          <i class="icon icon-gyroscope drop-shadow" title="陀螺仪" />
                        </div>-->
                        <div v-if="pano&&pano.audio">
                            <i v-if="!audioPlayed" class="fas fa-volume-mute" @click="$refs.audio.play();audioPlayed=true;" title="播放" />
                            <i v-else class="fas fa-volume-down" @click="$refs.audio.pause();audioPlayed=false;" title="暂停"></i>
                            <!--<i class="fas fa-volume-off fa-2x"></i>
                            <i class="fas fa-volume-up fa-2x"></i>-->
                        </div>
                        <div>
                            <i class="fas fa-share-alt" @click="ShareQrcode" title="分享" />
                        </div>
                        <div>
                            <i class="fas fa-thumbs-up" :class="{disabled:!!Praiseed}" @click="PraiseCount" title="点赞" />
                        </div>
                        <!--<div>
                          <i class="fas fa-comment-alt" title="评论" />
                        </div>-->
                        <div v-if="pano.exif||pano.text">
                            <i class="fas fa-info-circle" @click="$refs.panoinfo.show = true" title="信息" />
                        </div>
                        <div v-if="this.pano.exif && (this.pano.exif.gpsLat != 0 || this.pano.exif.gpsLng != 0)">
                            <i class="fas fa-map-marker-alt" @click="$refs.mapModal.show = true" title="地图" />
                        </div>
                    </div>
                    <div class="panoTitle text-shadow">
                        <h1 class="text-center">
                            {{ (pano||{}).title }}
                        </h1>
                    </div>
                </template>
            </Scale>
            <div class="logo">
                <router-link to="/">
                    <img src="/images/logo.png">
                </router-link>
            </div>
        </Krpano>
        <audio ref="audio" @canplay="oncanplay" :src="((pano||{}).audio||{}).url" loop></audio>
        <SimpleModal ref="mapinfoModal">
            <pre>{{mapdata}}</pre>
        </SimpleModal>
        <SimpleModal ref="hotspotitem" :onHide="hotspotitem_onHide">
            <div v-if="showitem">
                <div v-if="showitem.linkType==1">
                    <div v-if="showitem.file">
                        <div v-if="showitem.file.isImage" class="panoinfo">
                            <h4>{{showitem.title}}</h4>
                            <img :src="showitem.file.url.replace('/0/0/0/0/', '/600/0/0/0/')" />
                        </div>
                        <div v-else-if="showitem.file.isAudio" class="panoinfo">
                            <h4>{{showitem.title}}</h4>
                            <audio :src="showitem.file.url" controls />
                        </div>
                        <div v-else-if="showitem.file.isVideo" class="panoinfo">
                            <h4>{{showitem.title}}</h4>
                            <video style="width:100%;" :src="showitem.file.url" :poster="((showitem.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls />
                        </div>
                        <div v-else>
                            <pre>{{showitem}}</pre>
                        </div>
                    </div>
                    <div v-else>
                        <pre>{{showitem}}</pre>
                    </div>
                </div>
                <div v-else-if="showitem.linkType==2" class="panoinfo">
                    <h4>{{showitem.title}}</h4>
                    <p>{{showitem.description}}</p>
                </div>
                <div v-else>
                    <pre>{{showitem}}</pre>
                </div>
            </div>
        </SimpleModal>
        <SimpleModal ref="mapModal" boxClass="mapModal">
            <div v-if="$refs.mapModal&&$refs.mapModal.show" style="width:90vw;height:90vh;position:relative">
                <PanoMap ref="PanoMap" zoom="max" :autoZoom="true" :currentItem="pano" :currentLoc="{lng:pano.gpsLng,lat:pano.gpsLat}" :mapCenter="{lng:pano.gpsLng,lat:pano.gpsLat}"></PanoMap>
                <button style="position:absolute;bottom:3px;right:3px;" @click="mapBackToPano">回到全景位置</button>
            </div>
        </SimpleModal>
        <SimpleModal ref="share">
            <div class="panoinfo">
                <img :src="qrcodeurl" />
                <div class="text-center">
                    手机扫描分享<br />
                    微信分享请点击右上角的“…”
                </div>
            </div>
        </SimpleModal>
        <SimpleModal ref="panoinfo">
            <div v-if="pano" class="panoinfo">
                <div>
                    <h5>{{pano.title}}</h5>
                    <div v-if="pano.text" style="max-height:30vh;overflow:auto">
                        <p>{{pano.text}}</p>
                    </div>
                </div>
                <div v-if="pano.exif">
                    <hr />
                    <table class="exif">
                        <tr v-if="pano.exif.dateTimeOriginal">
                            <td>拍摄时间：</td>
                            <td>{{pano.exif.dateTimeOriginal}}</td>
                        </tr>
                        <tr v-if="pano.exif.make">
                            <td>相机品牌：</td>
                            <td>{{pano.exif.make}}</td>
                        </tr>
                        <tr v-if="pano.exif.model">
                            <td>相机型号：</td>
                            <td>{{pano.exif.model}}</td>
                        </tr>
                        <tr v-if="pano.exif.exposureTime&&pano.exif.exposureTime>0">
                            <td>曝光时间：</td>
                            <td>1/{{(1/pano.exif.exposureTime).toFixed(0)}} 秒</td>
                        </tr>
                        <tr v-if="pano.exif.fNumber&&pano.exif.fNumber>0">
                            <td>光圈值：</td>
                            <td>f/{{pano.exif.fNumber.toFixed(1)}}</td>
                        </tr>
                        <tr v-if="pano.exif.isoSpeedRatings&&pano.exif.isoSpeedRatings>0">
                            <td>ISO 值：</td>
                            <td>ISO {{pano.exif.isoSpeedRatings}}</td>
                        </tr>
                        <tr v-if="pano.exif.focalLength&&pano.exif.focalLength>0">
                            <td>焦距：</td>
                            <td>{{pano.exif.focalLength}} mm</td>
                        </tr>
                        <tr v-if="pano.exif.lensModel">
                            <td>镜头：</td>
                            <td>{{pano.exif.lensModel}}</td>
                        </tr>
                        <tr v-if="pano.exif.software">
                            <td>软件：</td>
                            <td>{{pano.exif.software}}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </SimpleModal>
    </div>
</template>
<script>
    import {
        Krpano,
        Scale,
        SimpleModal,
        PanoMap,
    } from 'common'
    //import Krpano from '@/components/Krpano.vue'
    //import Scale from '@/components/Scale.vue'
    //import SimpleModal from '@/components/SimpleModal.vue'
    //import PanoMap from '@/components/PanoMap.vue'
    export default {
        scrollToTop: true,
        components: {
            Krpano,
            Scale,
            SimpleModal,
            PanoMap,
        },
        data() {
            return {
                panoId: this.$route.params.panoId,
                krpano: null,
                krpanoSettings: {
                    //passQueryParameters: true
                    vars: {
                        'preview.type': 'grid(CUBE,64,64,512,0x000000,0x000000,0x000000);',
                        'autorotate.enabled': true,
                        'autorotate.speed': 7,
                        'autorotate.accel': 1,
                        'autorotate.waittime': 5
                    }
                },
                sceneVars:
                {
                },
                hideTool: false,
                audioPlayed: false,
                Praiseed: 0,
                qrcodeurl: null,
                mapConfig: {
                    zoom: 15,
                },
                mapdata: null,
                showitem: null,
                metaInfo: null,
                pano: null,
            }
        },
        head() {
            return {
                title: `${this.metaInfo.title} - ${this.metaInfo.siteName}`,
                meta: [
                    { hid: 'og:title', property: 'og:title', content: this.metaInfo.title },
                    { hid: 'og:type', property: 'og:type', content: 'article' },
                    { hid: 'og:site_name', property: 'og:site_name', content: this.metaInfo.siteName },
                    { hid: 'description', name: 'description', content: this.metaInfo.description },
                    { hid: 'og:description', property: 'og:description', content: this.metaInfo.description },
                    { hid: 'og:image', property: 'og:image', content: this.metaInfo.image },
                    { hid: 'og:url', property: 'og:url', content: this.metaInfo.url },
                    { hid: 'author', name: 'author', content: this.metaInfo.author },
                ],
                link: [
                    { rel: 'index', href: '/Pano' },
                    { rel: 'author', href: `/U/${this.pano.user.userName}` }
                ]
            }
        },
        computed: {
            scale() {
                if (this.$refs.Scale) {
                    return this.$refs.Scale.isMobile ? 0.5 : 1
                }
                return 1
            }
        },
        watch: {
        },
        //async asyncData({ $axios, params, query, store, route }) {
        //    const { data } = await $axios.get('/Api/Panos/' + params.panoId)
        //    const metaInfo = {
        //        title: data.title,
        //        siteName: store.state.settings.siteTitle,
        //        author: (data.user || {}).nickName,
        //        url: `${store.state.settings.siteUrl}${route.path}`,
        //        image: data.firstImage.url.replace('/0/0/0/0/', '/200/200/1/0/'),
        //        description: data.description || data.title
        //    }
        //    return {
        //        metaInfo: metaInfo,
        //        pano: data,
        //        sceneVars: {
        //            'view.fovmin': 70,
        //            'view.fovmax': 140,
        //            'view.maxpixelzoom': 2.0,
        //            'view.fovtype': 'MFOV',
        //            'view.fov': data.fov || 120,
        //            'view.vlookat': data.vLookAt,
        //            'view.hlookat': data.hLookAt,
        //            ...query
        //        }
        //    }
        //},
        created() {
            this.$axios.get('/Api/Panos/' + this.$route.params.panoId).then(({ data }) => {
                this.pano = data
                this.metaInfo = {
                    title: data.title,
                    siteName: store.state.settings.siteTitle,
                    author: (data.user || {}).nickName,
                    url: `${store.state.settings.siteUrl}${this.$route.path}`,
                    image: data.firstImage.url.replace('/0/0/0/0/', '/200/200/1/0/'),
                    description: data.description || data.title
                }
                this.sceneVars = {
                    'view.fovmin': 70,
                    'view.fovmax': 140,
                    'view.maxpixelzoom': 2.0,
                    'view.fovtype': 'MFOV',
                    'view.fov': data.fov || 120,
                    'view.vlookat': data.vLookAt,
                    'view.hlookat': data.hLookAt,
                    ...this.$route.query
                }
            })
        },
        mounted() {
            //this.$wxShare(
            //    {
            //        title: this.metaInfo.title,
            //        desc: this.metaInfo.description,
            //        link: this.metaInfo.url,
            //        imgUrl: this.metaInfo.image
            //    },
            //    location.href
            //)
            setTimeout(() => {
                this.hideTool = true
            }, 2000)
            window.addEventListener('beforeunload', this.onBeforeUnload)
        },
        destroyed() {
            window.removeEventListener('beforeunload', this.onBeforeUnload)
        },
        methods: {
            onBeforeUnload(event) {
                this.$refs.panoinfo.show = false
                return false
            },
            hotspotitem_onHide() {
                console.log('hide')
                this.showitem = null
            },
            mapBackToPano() {
                this.$refs.PanoMap.mapBackToPano()
            },
            ShareQrcode() {
                this.$refs.share.show = true
                this.qrcodeurl = `${process.env.baseUrl}/Api/QRCode/?url=${location.protocol}//${location.host}${location.pathname}`
            },
            PraiseCount() {
                if (this.Praiseed != 0) {
                    return
                }
                this.Praiseed = 1
                this.$axios.post('/Api/Panos/PraiseCount/' + this.pano.guid).then((res) => {
                    this.pano.praiseCount = res.data.praiseCount
                    this.pano.viewCount = res.data.viewCount
                    this.pano.shareCount = res.data.shareCount
                    this.Praiseed = 2
                }).catch((err) => {
                    this.Praiseed = 0
                })
            },
            ViewCount() {
                this.$axios.post('/Api/Panos/ViewCount/' + this.pano.guid).then((res) => {
                    this.pano.praiseCount = res.data.praiseCount
                    this.pano.viewCount = res.data.viewCount
                    this.pano.shareCount = res.data.shareCount
                })
            },
            ShareCount() {
                this.$axios.post('/Api/Panos/ShareCount/' + this.pano.guid).then((res) => {
                    this.pano.praiseCount = res.data.praiseCount
                    this.pano.viewCount = res.data.viewCount
                    this.pano.shareCount = res.data.shareCount
                })
            },
            setHideTool() {
                this.hideTool = true
            },
            setShowTool() {
                this.hideTool = !this.hideTool
            },
            onXmlComplete() {
                console.log('sethotspots')
                this.ViewCount()
                this.sethotspots()
                if (this.pano.littlePlanetIntro) {
                    this.littlePlanetIntro()
                }
            },
            oncanplay() {
                if (this.pano && this.pano.audio && this.pano.audioAutoPlay) {
                    this.$refs.audio.play().then(() => {
                        this.audioPlayed = true
                    }).catch((err) => {
                        setTimeout(this.oncanplay, 300)
                    })
                }
            },
            onMouseDown() {
                this.krpano.call('autorotate.stop()')
            },
            onKrpanoReady(krpano) {
                this.krpano = krpano
                this.krpano.set('events.onclick', `js(${this.$refs.Pano.getFuncName(this.setShowTool)}())`)
                this.krpano.set('events.onmousedown', `js(${this.$refs.Pano.getFuncName(this.onMouseDown)}())`)
            },
            loadpano() {
                this.$refs.Pano.setPano()
            },
            sethotspots() {
                const hotspots = this.pano.hotspots
                for (const i in hotspots) {
                    const item = hotspots[i]
                    var onclick
                    console.log(item.linkType)
                    switch (item.linkType) {
                        case 0:
                            if (item.linkItem) {
                                onclick = (index) => {
                                    this.$router.push({ name: 'PanoFullScreen', params: { panoId: hotspots[index].linkItem.key }, query: { 'view.fov': hotspots[index].linkFOV, 'view.hlookat': hotspots[index].linkHLookAt, 'view.vlookat': hotspots[index].linkVLookAt } })
                                }
                            }
                            break
                        case 1:
                        case 2:
                            onclick = (index) => {
                                this.showitem = hotspots[index]
                                this.$refs.hotspotitem.show = true
                            }
                            break
                        default:
                    }
                    const hotspotSettings = {
                        name: 'hotspot_' + item.guid,
                        ath: item.ath,
                        atv: item.atv,
                        url: item.icon.imageUrl,
                        onloaded: item.icon.isAnimation ? `js( ${this.$refs.Pano.getFuncName(this.AnimationCrop)}(${item.icon.width},${item.icon.height},${item.icon.fps},${item.guid}))` : null,
                        distorted: item.distorted,
                        scale: item.scale,
                        zoom: item.zoom,
                        rotate: item.rotate,
                        edge: item.icon.edge,
                        onclick: `js(${this.$refs.Pano.getFuncName(onclick)}(${i}))`
                    }
                    this.addhotspot(hotspotSettings)
                    if (item.showTitle && item.title != null && item.title != '') {
                        const layerSettings = {
                            name: 'hotspotTxt_' + item.guid,
                            type: 'text',
                            html: item.title,
                            vcenter: 'true',
                            padding: '0',
                            wordwrap: 'false',
                            mergedalpha: 'true',
                            distorted: item.distorted,
                            css: 'font-size:16px; color:#FFF;text-shadow:1px 1px 1px #000, -1px -1px 1px #000, 1px -1px 1px #000, -1px 1px 1px #000;',
                            bg: 'false',
                            parent: 'hotspot[' + 'hotspot_' + item.guid + ']',
                            zoom: item.zoom,
                            rotate: -item.rotate,
                            align: this.calcEdge(item.icon.edge, 0),
                            onclick: `js(${this.$refs.Pano.getFuncName(onclick)}(${i}))`
                        }
                        let p = [item.icon.textOffsetX, item.icon.textOffsetY, (parseFloat(item.rotate)) * Math.PI / 180]
                        p = this.changeAngle(p)
                        layerSettings.ox = p[0] * item.scale
                        layerSettings.oy = p[1] * item.scale
                        layerSettings.edge = this.calcEdge(item.icon.textEdge, parseFloat(item.rotate))
                        this.addlayer(layerSettings)
                    }
                }
            },
            unsethotspots(hotspots) {
                for (const i in hotspots) {
                    const item = hotspots[i]
                    this.removehotspot('hotspot_' + item.guid)
                    this.removelayer('hotspotTxt_' + item.guid)
                }
            },
            addRain() {
                const setting = {
                    mode: 'rain',
                    blendmode: 'normal',
                    flakes: '2000',
                    color: '0x7FAFFF',
                    speed: '3.5',
                    shake: '1.0',
                    speedvariance: '1.0',
                    spreading: '3.0',
                    rainwidth: '1.0',
                    rainalpha: '0.5',
                    wind: '2.0'
                }
                this.addSnow(setting)
            },
            addwebvr() {

            },
            addSnow(snowSetting) {
                if (this.krpano.get('plugin[snow]') != null) {
                    this.krpano.call('removeplugin(snow,true)')
                }
                this.krpano.call('addplugin(snow)')
                this.krpano.set('plugin[snow].url', '%SWFPATH%/plugins/snow.js')
                for (const i in snowSetting) {
                    this.krpano.set('plugin[snow].' + i, snowSetting[i])
                }
            },
            removeSnow() {
                this.krpano.call('removeplugin(snow,true)')
            },
            addhotspot(hotspotSetting) {
                let name = ''
                if (hotspotSetting.name) {
                    name = hotspotSetting.name
                    delete hotspotSetting.name
                } else {
                    name = 'hotspot_' + Math.random()
                }
                this.krpano.call('addhotspot(' + name + ')')
                for (const i in hotspotSetting) {
                    this.krpano.set(`hotspot[${name}].${i}`, hotspotSetting[i])
                }
            },
            removehotspot(name) {
                this.krpano.call('removehotspot(' + name + ',true)')
            },
            removeAllhotspot() {
                this.krpano.call('loop(hotspot.count GT 0, removehotspot(0) );')
            },
            addlayer(layerSetting) {
                let name = ''
                if (layerSetting.name) {
                    name = layerSetting.name
                    delete layerSetting.name
                } else {
                    name = 'layer_' + Math.random()
                }
                this.krpano.call('addlayer(' + name + ')')
                for (const i in layerSetting) {
                    this.krpano.set('layer[' + name + '].' + i, layerSetting[i])
                }
            },
            removelayer(name) {
                this.krpano.call('removelayer(' + name + ',true)')
            },
            littlePlanetIntro() {
                const lpScene = this.krpano.get('xml.scene')
                const lpHlookat = this.krpano.get('view.hlookat')
                const lpVlookat = this.krpano.get('view.vlookat')
                const lpFov = this.krpano.get('view.fov')
                const lpFovmax = this.krpano.get('view.fovmax')
                const lpLimitview = this.krpano.get('view.limitview')
                this.krpano.set('view.fovmax', 170)
                this.krpano.set('view.limitview', 'lookto')
                this.krpano.set('view.vlookatmin', 90)
                this.krpano.set('view.vlookatmax', 90)
                this.krpano.call(`lookat(calc(${lpHlookat} - 180), 90, 150, 1, 0, 0);`)
                //window.littleplanetintro_onloadcomplete = () => {
                setTimeout(() => {
                    if (lpScene === this.krpano.get('xml.scene')) {
                        this.krpano.set('control.usercontrol', 'off')
                        this.krpano.set('view.limitview', lpLimitview)
                        this.krpano.set('view.vlookatmin', null)
                        this.krpano.set('view.vlookatmax', null)
                        this.krpano.call(`tween(view.hlookat|view.vlookat|view.fov|view.distortion, calc('' + ${lpHlookat} + '|' + ${lpVlookat} + '|' + ${lpFov} + '|' + 0.0),
            3.0, easeOutQuad,
            set(control.usercontrol, all);
            tween(view.fovmax, get(${lpFovmax})););`)
                    }
                }, 500)
                //}
                //this.krpano.set('events[lp_events].onloadcomplete', 'js(littleplanetintro_onloadcomplete())')
            },
            autorotate() {
                this.krpano.set('autorotate.enabled', 'true')
            },
            stopautorotate() {
                this.krpano.set('autorotate.enabled', 'false')
            },
            changeAngle(param) {
                if (param[0] != 0 || param[1] != 0) {
                    const x = param[0]
                    const y = param[1]
                    const tha1 = param[2]
                    const value = Math.sqrt(x * x + y * y)
                    const cos1 = x / value
                    const sin1 = y / value
                    const cos2 = Math.cos(tha1)
                    const sin2 = Math.sin(tha1)
                    const cos3 = cos1 * cos2 - sin1 * sin2
                    const sin3 = sin1 * cos2 + cos1 * sin2
                    param[0] = (value * cos3).toFixed(2)
                    param[1] = (value * sin3).toFixed(2)
                    return param
                }
                return param
            },
            calcEdge(edge, angle) {
                let e
                const fx = ['top', 'righttop', 'right', 'rightbottom', 'bottom', 'leftbottom', 'left', 'lefttop']
                e = fx.findIndex(val => val == edge)
                if (e < 0) {
                    return edge
                }
                const offset = 22.5
                const c = parseFloat(angle) + offset
                let f = Math.floor(c / 45)
                f = e + f
                do {
                    if (f >= 0 && f < 8) {
                        break
                    }
                    if (f < 0) {
                        f += 8
                    }
                    if (f >= 8) {
                        f -= 8
                    }
                } while (true)
                return fx[f]
            },
            AnimationCrop(framewidth, frameheight, framerate, guid) {
                const caller = this.krpano.get(`hotspot[hotspot_${guid}]`)
                //define local variables
                const xframes = (caller.imagewidth / framewidth) || 0
                const frames = xframes * ((caller.imageheight / frameheight) || 0)
                let frame = 0
                //set the first frame
                this.krpano.set(`hotspot[hotspot_${guid}].crop`, `0|0|${framewidth}|${frameheight}`)
                //do the animation
                var ai = setInterval(() => {
                    if (caller.loaded) {
                        frame++
                        if (frame >= frames) {
                            frame = 0
                        }
                        let xpos = frame % xframes
                        let ypos = Math.floor(frame / xframes)
                        xpos *= framewidth
                        ypos *= frameheight
                        this.krpano.set(`hotspot[hotspot_${guid}].crop`, `${xpos}|${ypos}|${framewidth}|${frameheight}`)
                    } else {
                        clearInterval(ai)
                    }
                }, 1000 / framerate)
            },
        },
        beforeRouteUpdate(to, from, next) {
            this.$axios.get('/Api/Panos/' + to.params.panoId).then(({ data }) => {
                this.pano = data
                this.sceneVars = {
                    'view.fovmin': 70,
                    'view.fovmax': 140,
                    'view.maxpixelzoom': 2.0,
                    'view.fovtype': 'MFOV',
                    'view.fov': data.fov || 120,
                    'view.vlookat': data.vLookAt,
                    'view.hlookat': data.hLookAt,
                    ...to.query
                }
                this.loadpano()
                next()
            })
        }
    }
</script>
<style>

    .PanoFullScreen {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 1;
    }

        .PanoFullScreen .userA {
            position: absolute;
            top: 4rem;
            left: 1rem;
            max-width: 100px;
        }

        .PanoFullScreen .avatar img {
            width: 50px;
            height: 50px;
            /*border: 3px solid #fff;*/
            border-radius: 50%;
        }

        .PanoFullScreen .toolbar {
            position: absolute;
            left: 10px;
            bottom: 5px;
            font-size: 1.25rem;
        }

        .PanoFullScreen .panoTitle {
            position: absolute;
            top: 10px;
            left: 75px;
            right: 75px;
            pointer-events: none !important;
        }

            .PanoFullScreen .panoTitle h1 {
                font-size: 1.4rem;
            }

        .PanoFullScreen .icon {
        }

        .PanoFullScreen .logo {
            position: absolute;
            right: 0.5rem;
            bottom: 0;
        }

            .PanoFullScreen .logo img {
                width: 130px;
            }

        .PanoFullScreen .righttop {
            position: absolute;
            right: 10px;
            bottom: 45px;
            font-size: 1.8rem;
            line-height: 3.2rem;
            opacity: 0.6
        }

            .PanoFullScreen .righttop i {
                cursor: pointer;
                padding: 8px;
            }

            .PanoFullScreen .righttop .disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

        .PanoFullScreen.hideTool .userA {
            left: -100px;
        }

        .PanoFullScreen.hideTool .righttop {
            right: -40px;
        }

        .PanoFullScreen.hideTool .panoTitle {
            top: -40px;
        }

        .PanoFullScreen.hideTool .toolbar {
            bottom: -48px;
        }

        .PanoFullScreen .userA {
            transition: all 1s;
        }

        .PanoFullScreen .righttop {
            transition: all 1s;
        }

        .PanoFullScreen .panoTitle {
            transition: all 1s;
        }

        .PanoFullScreen .toolbar {
            transition: all 1s;
        }

        .PanoFullScreen .panoinfo {
            max-width: 70vw;
        }

        .PanoFullScreen .exif {
            font-size: 1.2rem
        }

            .PanoFullScreen .exif tr:first-child {
                white-space: nowrap
            }

            .PanoFullScreen .exif td {
                padding: 0 0 0 0;
            }

        .PanoFullScreen .modalbox {
            background-color: #fffa
        }

        .PanoFullScreen .mapModal {
            padding: 0;
        }

        .PanoFullScreen hr {
            margin: 1rem 0
        }

        .PanoFullScreen h5 {
            margin-bottom: 1rem;
        }
</style>
