<template lang="html">
  <div class="card card-primary card-outline ArticleList">
    <div class="card-header p-2">
      <h3 class="card-title">页面管理</h3>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-1">
      <div class="mailbox-controls">
        <button type="button" class="btn btn-primary btn-sm mx-1" v-on:click="create()" title="新建"><i class="fa fa-plus"></i></button>
        <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
        <!-- Check all button -->
        <div class="float-right">
          <div class="dropdown">
            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              筛选
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <!--<form class="dropdown-item p-1">
                  <div class="input-group input-group-sm ">
                      <div class="input-group-prepend">
                          <span class="input-group-text" style="font-weight:700">分类</span>
                      </div>
                      <select class="form-control form-control-sm" v-model:value="query.category" v-on:change="search()" placeholder="分类">
                                      <option :value="null">所有</option>
                          <option v-for="i in cats" v-bind:value="i.name">{{i.name}}</option>
                      </select>
                  </div>
              </form>-->
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">标签</span>
                  </div>
                  <select class="form-control form-control-sm" v-model:value="query.tags" v-on:change="search()" placeholder="标签">
                    <option :value="null">所有</option>
                    <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
                  </select>
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">起始</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.beginTime" v-on:change="search()">
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">结束</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.endTime" v-on:change="search()">
                </div>
              </form>
              <!--<form class="form-control-sm text-nowrap p-1">
                  <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioFine1" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="true" v-on:change="search()">
                      <label class="custom-control-label" for="customRadioFine1">精华</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioFine2" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="false" v-on:change="search()">
                      <label class="custom-control-label" for="customRadioFine2">普通</label>
                  </div>
                  <div class="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="customRadioFine3" name="customRadioFine" class="custom-control-input" v-model="query.fine" :value="null" v-on:change="search()">
                      <label class="custom-control-label" for="customRadioFine3">不限</label>
                  </div>
              </form>-->
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed1" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed1">屏蔽</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed2" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed2">打开</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed3" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed3">不限</label>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /.pull-right -->
      </div>
      <vue-bootstrap4-table :rows="list"
                            :columns="columns"
                            :config="config"
                            :classes="classes"
                            @on-change-query="onChangeQuery">
        <template slot="sort-asc-icon">
          <i class="fas fa-sort-up"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="fas fa-sort-down"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="fas fa-sort"></i>
        </template>
        <template slot="empty-results">
          没有数据
        </template>
        <template slot="active" slot-scope="props">
          <button class="btn btn-default btn-xs mx-1" title="编辑" @click="edit(props.row)">
            <i class="fas fa-pen-square"></i>
          </button>
          <button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
            <i class="fas fa-trash"></i>
          </button>
        </template>
        <template slot="closed" slot-scope="props">
          {{props.row.closed ? "是" : "否"}}
        </template>
      </vue-bootstrap4-table>
    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <!--<div class="overlay" v-if="this.$store.state.loading">
        <i class="fa fa-refresh fa-spin"></i>
    </div>-->
    <!-- end loading -->
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :prePageSize="pager.prePageSize"
              :onPagerChange="pageChange">
      </paging>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        list: [],
        tags: [],
        item: {
          id: null,
          title: null,
          closed: false,
          tags: [],
        },
        columns: [
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
          { name: 'id', label: 'Id', sort: true, initial_sort_order: 'desc', uniqueId: true, column_classes: "text-nowrap" },
          { name: 'title', label: '标题', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'pageName', label: '页面名称', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'addTime', label: '添加时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'lastEdit', label: '修改时间', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: {
          categoryGuid: this.$route.params.id,
          pageIndex: 1,
          pageSize: 20,
          sort: "Id",
          order: "desc",
          fields: null,
          key: null,
          tags: null,
          category: null,
          fine: null,
          featured: null,
          closed: null,
          beginTime: null,
          endTime: null,
        },
        loading: false,
      }
    },
    created() {
      this.loadTags()
      this.loadData()
    },
    computed: {
    },
    methods: {
      loadData() {
        this.loading = true;
        var config = {
          params: {
            categoryGuid: this.query.categoryGuid,
            page: this.query.pageIndex,
            pageSize: this.query.pageSize,
            sort: this.query.sort,
            order: this.query.order,
            fields: this.query.fields,
            key: this.query.key,
            tags: this.query.tags,
            fine: this.query.fine,// ? true : null,
            featured: this.query.featured,// ? true : null,
            closed: this.query.closed,// ? true : null,
            beginTime: this.query.beginTime,
            endTime: this.query.endTime,
          }
        };
        this.$axios.get('/Api/Admin/Pages', config).then((res) => {
          //console.log(res.data)
          this.list = res.data.data
          this.pager.pageIndex = res.data.pageIndex
          this.pager.pageSize = res.data.pageSize
          this.pager.totalPages = res.data.totalPages
          this.pager.totalRecords = res.data.totalRecords
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        })

      },
      search() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      pageChange(pageIndex, pageSize) {
        this.query.pageIndex = pageIndex;
        this.query.pageSize = pageSize;
        this.loadData();
      },
      onChangeQuery(queryParams) {
        var sort = queryParams.sort[0]
        this.query.sort = sort.name
        this.query.order = sort.order
        this.loadData()
      },
      loadTags() {
        this.$axios.get('/Api/Tags/All')
          .then((response) => {
            //console.log(response)
            this.tags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      loadItem(id) {
        this.$axios.get('/Api/Admin/Pages/' + id)
          .then((response) => {
            //console.log(response)
            this.item = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      reset() {
        this.item.id = null
        this.item.userId = null
        this.item.userName = null
        this.item.nickName = null
        this.item.password = null
        this.item.email = null
        this.item.phoneNumber = null
        this.item.closed = false
        this.item.tags = []
      },
      create() {
        //this.reset()
        this.$router.push({ name: 'PageCreate', params: { categoryId: this.query.categoryGuid } })
      },
      edit(item) {
        //console.log(item)
        //console.log(item.categoryGUID)
        this.$router.push({ name: 'PageEdit', params: { categoryId: item.categoryGUID, pageId: item.guid } })
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.query.categoryGuid = to.params.id
      this.loadData()
      next()
    }

  }
</script>

<style lang="css">
  .ArticleList .c1 {
    width: 90px;
  }

  .ArticleList .c2 {
    width: 60px;
  }

  .ArticleList .c_dt {
    width: 155px;
  }
</style>
