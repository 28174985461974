<template lang="html">
  <div class="card card-primary card-outline hotspoticon">
    <div class="card-header p-2">
      <h3 class="card-title">热点图标管理</h3>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-1">
      <div class="mailbox-controls">
        <button type="button" class="btn btn-primary btn-sm mx-1" v-on:click="$refs.HotspotIconEdit.edit()" title="新建图标"><i class="fa fa-plus"></i></button>
        <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
        <!--<div class="float-right">
          <div class="form-inline">
              <div class="form-group form-group-sm">
                  <label>图标类型</label>
                  <select class="form-control form-control-sm" v-model:value="query.roles" v-on:change="search()" placeholder="类型">
                      <option value="">所有</option>
                      <option v-for="i in roles" v-bind:value="i">{{i}}</option>
                  </select>
              </div>
          </div>
      </div>-->
        <!-- /.pull-right -->
      </div>
      <vue-bootstrap4-table :rows="list"
                            :columns="columns"
                            :config="config"
                            :classes="classes"
                            @on-change-query="onChangeQuery">
        <template slot="sort-asc-icon">
          <i class="fas fa-sort-up"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="fas fa-sort-down"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="fas fa-sort"></i>
        </template>
        <template slot="empty-results">
          没有数据
        </template>
        <template slot="active" slot-scope="props">
          <button class="btn btn-default btn-xs mx-1" title="编辑" @click="$refs.HotspotIconEdit.edit(props.row.guid)">
            <i class="fas fa-pen-square"></i>
          </button>
          <button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
            <i class="fas fa-trash"></i>
          </button>
        </template>
        <template slot="image" slot-scope="props">
          <HotspotIconView :icon="props.row" bgcolor="#ccc">
          </HotspotIconView>
        </template>
        <template slot="closed" slot-scope="props">
          {{props.row.closed ? "是" : "否"}}
        </template>
      </vue-bootstrap4-table>
    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="this.$store.state.loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <!-- end loading -->
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :prePageSize="pager.prePageSize"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <HotspotIconEditModal ref="HotspotIconEdit" iconUrl="/Api/Admin/HotspotIcons" uploadIconUrl="/Api/Admin/HotspotIcons/Upload" @created="search()" @saved="loadData()"></HotspotIconEditModal>
  </div>
</template>

<script>
  import {
    HotspotIconEditModal,
    HotspotIconView,
    Modal,
  } from 'common'
  //import UploadSingleBox from '@/components/UploadSingleBox.vue'
  //import HotspotIconView from '@/components/HotspotIconView.vue'
  //import Modal from '@/components/Modal.vue'
  export default {
    components: {
      HotspotIconEditModal,
      HotspotIconView,
      Modal,
    },
    data() {
      return {
        list: [],
        //roles: [],
        item: {
          id: null,
          image: null,
          title: '',
          edge: null,
          isAnimation: false,
          width: 0,
          height: 0,
          fps: 30,
          enableText: false,
          textEdge: null,
          textOffsetX: 0,
          textOffsetY: 0,
        },
        columns: [
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
          { name: 'title', label: '名称', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'image', label: '图片', column_classes: "text-nowrap" },
          { name: 'width', label: '宽度', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap" },
          { name: 'height', label: '高度', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", },
          { name: 'fps', label: '帧率', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap" },
          { name: 'addTime', label: '添加时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
          { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover projects",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: { ...this.getDefaultQuery(), ...this.$route.query },
        directions: [
          'lefttop', 'top', 'righttop', 'left', 'center', 'right', 'leftbottom', 'bottom', 'rightbottom'
        ],
        updateing: false,
      }
    },
    created() {
      //this.loadRoles()
      this.loadData()
    },
    computed: {
    },
    methods: {
      loadData() {
        var _this = this;
        var config = {
          params: {
            ...this.query
          }
        };
        this.$axios.get('/Api/Admin/HotspotIcons', config).then(function (res) {
          //console.log(res.data)
          _this.list = res.data.data
          _this.pager.pageIndex = res.data.pageIndex
          _this.pager.pageSize = res.data.pageSize
          _this.pager.totalPages = res.data.totalPages
          _this.pager.totalRecords = res.data.totalRecords
        })

      },
      getDefaultQuery() {
        return {
          page: 1,
          pageSize: 20,
          sort: 'addTime',
          order: "desc",
          //fields: '',
          key: null,
        }
      },
      getDifferentQuery(query) {
        var dif = {}
        var all = { ...this.query, ...query }
        var def = this.getDefaultQuery()
        for (var i in all) {
          if (def[i] != all[i]) {
            dif[i] = all[i]
          }
        }
        return dif
      },
      setQuery(newquery) {
        if (!newquery) {
          this.query = this.getDefaultQuery()
        }
        var dif = this.getDifferentQuery(newquery)
        console.log(this.$route.query, dif)
        if (!this.$isObjectValueEqual(this.$route.query, dif)) {
          this.$router.push({ query: dif })
        }
      },
      search() {
        this.setQuery({ page: 1 })
      },
      pageChange(page, pageSize) {
        this.setQuery({ page, pageSize })
      },
      onChangeQuery(queryParams) {
        var sort = queryParams.sort[0]
        this.setQuery({ sort: sort.name, order: sort.order })
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.query = { ...this.getDefaultQuery(), ...to.query }
      this.loadData()
      next()
    }
  }
</script>

<style lang="css">
  .hotspoticon .checklaber {
    padding: 6px;
    margin: 2px;
    border: 1px solid #aaa;
    color: #999
  }

    .hotspoticon .checklaber:hover {
      color: black;
      border-color: black;
    }

    .hotspoticon .checklaber.active {
      background-color: #3c8dbc;
      color: white
    }

  .hotspoticon .mycheck {
    display: none
  }

  .hotspoticon .icontextpreview {
    border: 1px solid #000;
  }

  .hotspoticon .c1 {
    width: 90px;
  }

  .hotspoticon .c2 {
    width: 60px;
  }

  .hotspoticon .c_dt {
    width: 155px;
  }
</style>
