<template lang="html">
    <div class="row">
        <div class="col-md-6">
            <div class="card card-primary card-outline">
                <div class="card-header p-2">
                    <div class="card-title">
                        <h5>基础设置</h5>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="siteTitle">网站名称</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.siteTitle.$error }" id="siteTitle" v-model.trim="$v.form.siteTitle.$model" placeholder="请输入网站名称">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.siteTitle.required">请输入网站名称</span>
                            <span v-if="!$v.form.siteTitle.minLength">网站名称至少 {{$v.form.siteTitle.$params.minLength.min}} 个字符</span>
                            <span v-if="!$v.form.siteTitle.maxLength">网站名称最多 {{$v.form.siteTitle.$params.maxLength.max}} 个字符</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="description">网站简介</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.description.$error }" id="description" v-model.trim="$v.form.description.$model" placeholder="请输入网站简介">
                        <div class="invalid-feedback">
                            <!--<span v-if="!$v.form.description.required">请输入网站简介</span>
                            <span v-if="!$v.form.description.minLength">网站名称至少 {{$v.form.description.$params.minLength.min}} 个字符</span>
                            <span v-if="!$v.form.description.maxLength">网站名称最多 {{$v.form.description.$params.maxLength.max}} 个字符</span>-->
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="copyright">版权信息</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.copyright.$error }" id="copyright" v-model.trim="$v.form.copyright.$model" placeholder="请输入版权信息">
                        <div class="invalid-feedback">
                            <!--<span v-if="!$v.form.copyright.required">请输入版权信息</span>
                            <span v-if="!$v.form.copyright.minLength">版权信息至少 {{$v.form.copyright.$params.minLength.min}} 个字符</span>
                            <span v-if="!$v.form.copyright.maxLength">版权信息最多 {{$v.form.copyright.$params.maxLength.max}} 个字符</span>-->
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="siteUrl">网站 Url</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.siteUrl.$error }" id="siteUrl" v-model.trim="$v.form.siteUrl.$model" placeholder="请输入网站 Url">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.siteUrl.required">请输入 Url</span>
                            <span v-if="!$v.form.siteUrl.isUrl">不是有效的 Url 格式</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="myUrl">个人中心 Url</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.myUrl.$error }" id="myUrl" v-model.trim="$v.form.myUrl.$model" placeholder="请输入个人中心 Url">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.myUrl.required">请输入 Url</span>
                            <span v-if="!$v.form.myUrl.isUrl">不是有效的 Url 格式</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="adminUrl">网站管理 Url</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.adminUrl.$error }" id="adminUrl" v-model.trim="$v.form.adminUrl.$model" placeholder="请输入网站管理 Url">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.adminUrl.required">请输入 Url</span>
                            <span v-if="!$v.form.adminUrl.isUrl">不是有效的 Url 格式</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="innAdminUrl">客栈管理 Url</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.innAdminUrl.$error }" id="innAdminUrl" v-model.trim="$v.form.innAdminUrl.$model" placeholder="请输入客栈管理 Url">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.innAdminUrl.required">请输入 Url</span>
                            <span v-if="!$v.form.innAdminUrl.isUrl">不是有效的 Url 格式</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="fileServer.url">文件服务 Url</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.fileServer.url.$error }" id="fileServer.url" v-model.trim="$v.form.fileServer.url.$model" placeholder="请输入文件服务 Url">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.fileServer.url.required">请输入 Url</span>
                            <span v-if="!$v.form.fileServer.url.isUrl">不是有效的 Url 格式</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="apiUrl">Api Url</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.apiUrl.$error }" id="apiUrl" v-model.trim="$v.form.apiUrl.$model" placeholder="请输入 Api Url">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.apiUrl.required">请输入 Url</span>
                            <span v-if="!$v.form.apiUrl.isUrl">不是有效的 Url 格式</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="payUrl">支付 Url</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.payUrl.$error }" id="payUrl" v-model.trim="$v.form.payUrl.$model" placeholder="请输入支付 Url">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.payUrl.required">请输入 Url</span>
                            <span v-if="!$v.form.payUrl.isUrl">不是有效的 Url 格式</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-6">
            <div class="card card-primary card-outline">
                <div class="card-header p-2">
                    <div class="card-title">
                        <h5>微信设置</h5>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="weixinSubscribeTempl">微信公众号订阅成功信息</label>
                        <!--<input type="text" class="form-control" id="weixinSubscribeTempl" v-model.trim="form.weixinSubscribeTempl" placeholder="请输入微信公众号订阅成功信息">-->
                        <!--<PellEditor v-model.trim="form.weixinSubscribeTempl"
                                    :options="options"
                                    :buttons="['link','removeFormat',]" />-->
                        <textarea class="form-control" id="weixinSubscribeTempl" v-model.trim="form.weixinSubscribeTempl" placeholder="请输入微信公众号订阅成功信息"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="weixinSubscribeTempl">微信公众号"系统异常告警通知"模板Id</label>
                        <input type="text" class="form-control" id="weixinExceptionAlertTemplateId" v-model.trim="form.weixinExceptionAlertTemplateId" placeholder="请输入微信公众号系统异常告警通知模板Id">
                    </div>
                    <div class="form-group">
                        <label for="weixinSubscribeTempl">微信公众号"购买成功通知"模板Id</label>
                        <input type="text" class="form-control" id="weixinPaySuccessTemplateId" v-model.trim="form.weixinPaySuccessTemplateId" placeholder="请输入微信公众号购买成功通知模板Id">
                    </div>
                    <div class="form-group">
                        <label for="weixinSubscribeTempl">微信公众号"新订单提醒"模板Id</label>
                        <input type="text" class="form-control" id="weixinNewOrderTemplateId" v-model.trim="form.weixinNewOrderTemplateId" placeholder="请输入微信公众号新订单提醒模板Id">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card card-primary card-outline">
                <div class="card-header p-2">
                    <div class="card-title">
                        <h5>模板设置</h5>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="validationEmailTempl">邮箱验证邮件模板</label>
                        <editor v-model="form.validationEmailTempl"
                                :toolbarItems="['bold','italic','underline','strikethrough', 'subscript', 'superscript','fontSize','|','alignment','outdent', 'indent','bulletedList', 'numberedList','|','link','insertTable','removeFormat','undo','redo']"
                                placeholder="在这里输入邮箱验证邮件模板!" />
                        <small class="form-text text-muted">占位符：{0}=用户名 {1}=链接 {2}=日期</small>
                    </div>
                    <div class="form-group">
                        <label for="lostPasswordEmailTempl">密码重置邮件模板</label>
                        <editor v-model="form.lostPasswordEmailTempl"
                                :toolbarItems="['bold','italic','underline','strikethrough', 'subscript', 'superscript','fontSize','|','alignment','outdent', 'indent','bulletedList', 'numberedList','|','link','insertTable','removeFormat','undo','redo']"
                                placeholder="在这里输入密码重置邮件模板!" />
                        <small class="form-text text-muted">占位符：{0}=用户名 {1}=链接 {2}=日期 {3}=有效期</small>
                    </div>
                    <div class="form-group">
                        <label for="validationSMSTempl">短信验证信息模板</label>
                        <input type="text" class="form-control" id="validationSMSTempl" v-model.trim="form.validationSMSTempl" placeholder="请输入短信验证信息模板">
                        <small class="form-text text-muted">占位符：{0}=验证码 {1}=有效期</small>
                    </div>
                    <div class="form-group">
                        <label for="lostPasswordSMSTempl">密码重置短信模板</label>
                        <input type="text" class="form-control" id="lostPasswordSMSTempl" v-model.trim="form.lostPasswordSMSTempl" placeholder="请输入密码重置短信模板">
                        <small class="form-text text-muted">占位符：{0}=验证码 {1}=有效期</small>
                    </div>
                </div>
            </div>
        </div>
        <Footer>
            <!--<button type="button" class="btn btn-default" @click.prevent="Close">取消</button>-->
            <button type="button" @click.prevent="update()" class="btn btn-primary" :disabled="updating">保存</button>
        </Footer>
    </div>
</template>

<script>
    import { PellEditor, Footer } from 'common'
    import Editor from '@/components/Editor.vue'
    import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
    //import Footer from '@/components/Footer.vue'
    //import PellEditor from '@/components/PellEditor.vue'
    const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
    const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
    const isUrl = helpers.regex('isUrl', /^((http:\/\/)|(https:\/\/))?([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.?)+(([a-zA-Z]{2,6})|(:[0-9]{1,5}))$/)

    export default {
        components: {
            Editor,
            Footer,
            PellEditor
        },
        data() {
            return {
                form: {
                    siteName: '',
                    siteTitle: '',
                    description: '',
                    copyright: '',
                    keywords: '',
                    siteUrl: '',
                    myUrl: '',
                    adminUrl: '',
                    immAadminUrl: '',
                    apiUrl: '',
                    payUrl: '',
                    validationEmailTempl: '',
                    lostPasswordEmailTempl: '',
                    validationSMSTempl: '',
                    lostPasswordSMSTempl: '',
                    weixinSubscribeTempl: '',
                    weixinExceptionAlertTemplateId: '',
                    weixinPaySuccessTemplateId: '',
                    weixinNewOrderTemplateId: '',
                    fileServer: {
                        url: ''
                    },
                },
                updating: false,
                options: {
                    whiteList: {
                        //p: [],
                        //b: [],
                        //strong: [],
                        //i: [],
                        //u: [],
                        //strike: [],
                        //br: [],
                        //h1: [],
                        //h2: [],
                        //blockquote: [],
                        //hr: [],
                        //ol: [],
                        //ul: [],
                        //li: [],
                        //code: [],
                        a: ['href'],
                        //img: ['src'],
                        //sub: [],
                        //sup: [],
                    },
                    stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
                    stripIgnoreTagBody: ['script'], // script标签较特殊，需要过滤标签中间的内容
                    onTag: function (tag, html, options) {
                        if (tag == 'br') {
                            return '\n';
                        }
                    },
                    onIgnoreTag: function (tag, html, options) {
                        if (tag == 'br') {
                            return '\n';
                        }
                    }

                }
            }
        },
        validations: {
            form: {
                siteTitle: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(100)
                },
                siteName: {
                },
                description: {
                },
                copyright: {
                },
                keywords: {
                },
                siteUrl: {
                    required,
                    isUrl
                },
                myUrl: {
                    required,
                    isUrl
                },
                adminUrl: {
                    required,
                    isUrl
                },
                innAdminUrl: {
                    required,
                    isUrl
                },
                fileServer: {
                    url: {
                        required,
                        isUrl
                    },
                },
                apiUrl: {
                    required,
                    isUrl
                },
                payUrl: {
                    //required,
                    isUrl
                },

            }
        },
        created() {
            this.loadData()
        },
        mounted() {
        },
        destroyed() {
        },
        methods: {
            loadData() {
                let self = this
                this.$axios.get('/Api/Admin/Settings')
                    .then(function (response) {
                        //console.log(response)
                        let d = response.data
                        self.form = d;
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },

            update() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                this.updating = true
                let _this = this
                this.$axios.put('/Api/Admin/Settings/', this.form)
                    .then(function (response) {
                        console.log(response)
                        _this.$message({
                            message: '保存成功！',
                            type: 'success',
                            zIndex: 9999,
                            //showClose: true
                        })
                        _this.updating = false
                    })
                    .catch(function (error) {
                        console.log(error)
                        _this.updating = false
                    })
            },
        },
        computed: {
        },
        watch: {
        },
    }
</script>
<style lang="css">
</style>
