<template>
  <div class="card card-primary card-outline">
    <div class="card-header p-1">
      <h3 class="card-title">菜单管理</h3>
    </div>
    <div class="card-body">
      <div style="max-width:500px">
        <div class="btn-group mb-1" role="group">
          <button class="btn btn-primary" @click="openEditDataDialog(null, null)">添加根菜单</button>
          <button class="btn btn-default" @click="allClose">全部收缩</button>
          <button class="btn btn-default" @click="allOpen">刷新</button>
        </div>
        <div>
          <Tree :value="originalData" draggable ref="Tree" @change="onChange">
            <div slot-scope="{node, index, path, tree}">
              <b v-if="node.children && node.children.length" @click="tree.toggleFold(node, path)" class="text-gray">
                <i class="fas fa-folder" v-if="node.$folded"></i>
                <i class="fas fa-folder-open" v-else></i>
              </b>
              <div class="d-inline-block m-1" :style="{color:node.closed?'#aaa':'#000'}"><i :class="node.icon"></i> {{node.text}}</div>
              <div class="float-right">
                <button class="btn btn-xs btn-default mx-1" @click="openEditDataDialog(node.id, null)" title="编辑">
                  <i class="fas fa-pen-square"></i>
                </button>
                <button class="btn btn-xs btn-danger mx-1" @click="openDeleteDialog(node.id)" title="删除">
                  <i class="fas fa-trash"></i>
                </button>
                <button class="btn btn-xs btn-default mx-1" @click="openEditDataDialog(null, node.id)" title="添加子菜单">
                  <i class="fas fa-plus"></i>
                </button>
              </div>
            </div>
          </Tree>
        </div>
      </div>

    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <!-- end loading -->
    <Modal ref="EditMenu" :onhide="onHideEditDataDialog">
      <template slot="header">
        <h4 class="modal-title">编辑菜单</h4>
      </template>
      <form class="form-horizontal">
        <div class="form-group">
          <label class="col-sm-2 control-label">标题</label>
          <div class="col-sm-10">
            <input class="form-control" v-model.trim="item.title" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">名称</label>
          <div class="col-sm-10">
            <input class="form-control" v-model.trim="item.name" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">提示</label>
          <div class="col-sm-10">
            <input class="form-control" v-model.trim="item.tooltip" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">图标</label>
          <div class="col-sm-10">
            <input class="form-control" v-model.trim="item.icon" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">链接</label>
          <div class="col-sm-10">
            <input class="form-control" v-model.trim="item.link" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Url 目标</label>
          <div class="col-sm-10">
            <input class="form-control" v-model.trim="item.target" />
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">模块</label>
          <div class="col-sm-10">
            <select class="form-control" v-model="item.moduleGUID">
              <option :value="null">-=无=-</option>
              <option v-for="i in modules" :value="i.guid">{{i.title}}</option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Url 参数</label>
          <div class="col-sm-10">
            <KeyValueInput ref="urlParams" v-model="item.params">
            </KeyValueInput>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Url 查询</label>
          <div class="col-sm-10">
            <KeyValueInput ref="urlQuery" v-model="item.query">
            </KeyValueInput>
          </div>
        </div>
        <div class="form-group">
          <div class="col-sm-offset-2 col-sm-10">
            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="item.closed"> 关闭
              </label>
            </div>
          </div>
        </div>
      </form>
      <template v-slot:footer>
        <button type="button" class="btn btn-default" @click="hideDialog">关闭</button>
        <button type="button" class="btn btn-primary" @click="saveData" :disabled="updateing">保存</button>
      </template>
    </Modal>
    <Modal ref="DeleteMenu">
      <template v-slot:header>
        <h4 class="modal-title">删除菜单</h4>
      </template>
      {{item.title}}
      <template v-slot:footer>
        <button type="button" class="btn btn-default" @click="$refs.DeleteMenu.hide()">
          取消
        </button>
        <button type="button" class="btn btn-danger" @click="Deleteitem" :disabled="updateing">确认删除</button>
      </template>

    </Modal>

  </div>
</template>
<script>
  import {
    Tree, // 基础树
    Fold, Check, Draggable, // 插件: 折叠, 勾选框, 拖拽
    foldAll, unfoldAll, cloneTreeData, walkTreeData, getPureTreeData, //方法
  } from 'he-tree-vue'
  import 'he-tree-vue/dist/he-tree-vue.css' // 基础样式
  import { Modal, KeyValueInput} from 'common'
  //import Modal from '@/components/Modal.vue'

  export default {
    components: {
      Tree: Tree.mixPlugins([Fold, Draggable]),
      Modal,
      KeyValueInput
    },
    data() {
      return {
        id: this.$route.params.id,
        modules: [],
        originalData: [
        ],
        data: null,
        item: this.newItem(),
        updateing: false,
        loading: false,
      }
    },
    created() {
      this.loadData()
    },
    mounted() {
      this.id = this.$route.params.id;
      this.loadData()
      this.loadModules()
    },
    computed: {
    },
    methods: {
      loadData() {
        this.loading = true
        this.$axios.get(`/Api/Admin/Menus/All/${this.id}`).then((res) => {
          this.originalData = res.data
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
      },
      loadModules() {
        this.$axios.get('/Api/Admin/Modules', {
          params: {
            page: 1,
            pageSize: 50,
          }
        }).then((response) => {
          //console.log(response)
          this.modules = response.data.data
        }).catch((error) => {
          console.log(error)
        })
      },
      onChange(e) {
        //console.log(node, targetTree)
        //console.log(node.parent.children)
        this.update(e)
        //this.data = targetTree.getPureData()
      },
      gerBrotherNodes(node) {
        var parent = this.$refs.Tree.getNodeParentByPath(node.targetPath)
        if (parent) {
          return parent.children
        } else {
          return this.$refs.Tree.getPureTreeData()
        }
      },
      update(node) {
        this.updateing = true;
        var parentId = null
        var parent = this.$refs.Tree.getNodeParentByPath(node.targetPath)
        if (parent) {
          parentId = parent.id
        }
        var data = {
          guid: node.dragNode.id,
          title: node.dragNode.text,
          parentGuid: parentId
        }
        this.$axios.put('/Api/Admin/Menus/Sort', data).then((response) => {
          var idlist = [];
          var brother = this.gerBrotherNodes(node)
          for (var i in brother) {
            idlist.push(brother[i].id)
          }
          this.$axios.post('/Api/Admin/Menus/Sort', idlist)
            .then((response) => {
              console.log(response)
              this.updateing = false;
            })
            .catch((error) => {
              console.log(error)
              this.updateing = false;
            })
        }).catch((error) => {
          console.log(error)
          this.updateing = false;
        })

      },
      onHideEditDataDialog() {
        this.$refs.urlParams.cancel()
        this.$refs.urlQuery.cancel()
      },
      openEditDataDialog(id, pid) {
        if (id) {
          this.$axios.get(`/Api/Admin/Menus/${id}`).then((res) => {
            this.item = res.data;
            this.openDialog()
          })
        } else {
          this.item = this.newItem()
          this.item.parentGUID = pid;
          this.openDialog()
        }
      },
      openDeleteDialog(id) {
        if (id) {
          this.$axios.get(`/Api/Admin/Menus/${id}`).then((res) => {
            this.item = res.data;
            this.$refs.DeleteMenu.show()
          })
        }
      },
      Deleteitem() {
        this.updateing = true;
        this.$axios.delete(`/Api/Admin/Menus/${this.item.guid}`).then((res) => {
          this.$refs.DeleteMenu.hide()
          this.loadData()
          this.updateing = false;
        }).catch(e => {
          this.updateing = false;
        })

      },
      openDialog() {
        //this.$bus.$emit('EditMenu-modal-open', {});
        this.$refs.EditMenu.show()

      },
      hideDialog() {
        //this.$bus.$emit('EditMenu-modal-hide', {});
        this.$refs.EditMenu.hide()

      },
      newItem() {
        return {
          guid: null,
          categoryGUID: this.id,
          parentGUID: null,
          title: '',
          name: '',
          tooltip: '',
          icon: '',
          link: '',
          target: '',
          closed: false
        }
      },
      saveData() {
        var data = this.item
        if (data.guid) {
          this.$axios.put('/Api/Admin/Menus/', data).then((res) => {
            console.log(res);
            this.loadData();
            this.hideDialog()
          })

        } else {
          this.$axios.post('/Api/Admin/Menus/', data).then((res) => {
            console.log(res);
            this.loadData();
            this.hideDialog()
          })
        }
      },
      allClose() {
        this.$refs.Tree.foldAll()
      },
      allOpen() {
        this.loadData()
      }
    },
    beforeRouteUpdate(to, from, next) {
      // just use `this`
      console.log(to.params.id)
      this.id = to.params.id
      this.loadData()

      next()
    },
  }
</script>

<style lang="css">


  .he-tree {
    border: 1px solid #ccc;
    padding: 20px;
  }

  .tree-node {
  }

  .tree-node-inner {
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .draggable-placeholder {
  }

  .draggable-placeholder-inner {
    border: 1px dashed #0088F8;
    box-sizing: border-box;
    background: rgba(0, 136, 249, 0.09);
    color: #0088f9;
    text-align: center;
    padding: 0;
    display: flex;
    align-items: center;
  }
</style>
