//import '@babel/polyfill'
import 'vue-oidc-client/src/polyfill';
import 'mutationobserver-shim'

import './lib/css'
import './lib/script'
import './lib/global'

import Vue from 'vue'
import App from './App'
import mainAuth from './auth';
import router from './router'
import EventBus from './lib/eventBus.js'
import axios from 'axios'
import store from './vuex/store.js'
import { sync } from 'vuex-router-sync'
import MainHub from './lib/mainHub'
import { Exceptionless, ExceptionlessErrorHandler } from "@exceptionless/vue"

Vue.prototype.$logger = Exceptionless
Exceptionless.startup((c) => {
  c.useDebugLogger();

  c.apiKey = window.global.EXCEPTIONLESS.API_KEY
  c.serverUrl = window.global.EXCEPTIONLESS.SERVER_URL
  c.updateSettingsWhenIdleInterval = 15000;
  c.usePersistedQueueStorage = true;
  //c.setUserIdentity("12345678", "Blake");

  // set some default data
  //c.defaultData["SampleUser"] = {
  //  id: 1,
  //  name: "Blake",
  //  password: "123456",
  //  passwordResetToken: "a reset token",
  //  myPasswordValue: "123456",
  //  myPassword: "123456",
  //  customValue: "Password",
  //  value: {
  //    Password: "123456",
  //  },
  //};

  c.defaultTags.push("Vue", "JavaScript");
});
Exceptionless.submitLog('app.logger', 'Vue App Started', 'Info');
//Vue.config.errorHandler = ExceptionlessErrorHandler
Vue.prototype.$bus = EventBus
Vue.prototype.$axios = axios
Vue.use(MainHub)

axios.defaults.baseURL = window.global.API_BASE_URL
axios.defaults.withCredentials = true

//判断用户是否具有某个角色
Vue.prototype.$inRole = (role) => {
    var res = false
    var roles = Vue.prototype.$oidc.userProfile.role
    if (roles) {
        for (var i in roles) {
            if (roles[i] == role) {
                return true
            }
        }
    }
    return res
}

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title + ' - 管理中心'
    }
    //if (to.path == '/signin-oidc' || to.path == '/signout-oidc' || to.path == '/silent-refresh' || to.path == '/NoAccess') {
    //    next()
    //    return;
    //}

    //mgr.getAcessToken().then(
    //    acessToken => {
    //        if (acessToken) {
    //            //axios.defaults.headers.common['Authorization'] = 'Bearer ' + acessToken
    //            next()
    //        }
    //    },
    //    err => {
    //        console.log(err)
    //    }
    //)

    next()
})
//setInterval(() => {
//    mgr.renewToken().then(
//        user => {
//            if (user) {
//                axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token
//            }
//        },
//        err => {
//            console.log(err)
//        }
//    )
//}, 60000)
router.afterEach((to, from) => {
    $('.modal').modal('hide')
})

sync(store, router)
mainAuth.startup().then(ok => {
    if (ok) {
        new Vue({
            //store,
            router,
            render: h => h(App),
        }).$mount('#app');
    }
});

/* eslint-disable no-new */
// const app = new Vue({
//   store,
//   router,
//   template: '<App/>',
//   ...App
// })
// export {
//   app,
//   router,
//   store
// }
//定义一个请求拦截器
axios.interceptors.request.use(config => {
    if (Vue.prototype.$oidc.isAuthenticated) {
        config.headers.common['Authorization'] = `Bearer ${Vue.prototype.$oidc.accessToken}`
    }
    //store.state.loading = true
    //store.commit('showLoading')
    return config
}, error => {
    console.log(error)
    Vue.prototype.$logger.submitException(error)

    switch (error.status) {
        case 401:
            Vue.prototype.$message("您无权操作！")
            //mgr.renewToken()
            break;
        case 403:
            Vue.prototype.$message("您无权操作！")
            break;
    }

    Promise.reject(error)
})
//定义一个响应拦截器
axios.interceptors.response.use(function (config) {
    //store.state.loading = false
    //store.commit('hideLoading')
    return config
}, error => {
    //console.log(JSON.stringify(error))
    let errstr = JSON.stringify(error)
    console.log(errstr)
    let errjson = JSON.parse(errstr)
    console.log(errjson)
    Vue.prototype.$logger.submitException(errstr)
    if (errjson.response) {
        let status = errjson.response.status
        var data = errjson.response.data
        var statusText = errjson.response.statusText
        switch (status) {
            case 401:
                Vue.prototype.$message({
                    message: data ? data : '无权操作！',
                    type: 'error',
                    zIndex: 9999,
                    //showClose: true
                })
                //mgr.renewToken()
                break;
            case 403:
                Vue.prototype.$message({
                    message: data ? data : '无权操作！',
                    type: 'error',
                    zIndex: 9999,
                    //showClose: true
                })
                break;
            case 404:
                Vue.prototype.$message({
                    message: data ? data : '文件未找到！',
                    type: 'error',
                    zIndex: 9999,
                    //showClose: true
                })
                break;
            case 500:
                Vue.prototype.$message({
                    message: data ? data : '程序错误！',
                    type: 'error',
                    zIndex: 9999,
                    //showClose: true
                })
                break;
            default:
                Vue.prototype.$message({
                    message: "{status:" + status + ',statusText:"' + statusText + '",data:"' + data + '"}',
                    type: 'error',
                    zIndex: 9999,
                    //showClose: true
                })

        }
    } else if (errjson.message) {
        if (errjson.message == 'Request failed with status code 401') {
            //mgr.renewToken()
        }
        Vue.prototype.$message({
            message: errjson.message,
            type: 'error',
            zIndex: 9999,
            //showClose: true
        })
    }
    //store.commit('hideLoading')

    return Promise.reject(error)
})

