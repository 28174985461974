<template lang="html">
    <div class="card card-primary card-outline AudioList">
        <div class="card-header p-2">
            <h3 class="card-title">音频管理</h3>
            <div class="card-tools mr-0">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" v-on:click="search()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- /.box-header -->
        <div class="card-body p-1">
            <div class="mailbox-controls">
                <div class="mb-1">
                    <button type="button" class="btn btn-primary btn-sm mx-1" v-on:click="create()" title="添加音频"><i class="fa fa-plus"></i></button>
                    <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
                </div>
                <!-- Check all button -->
                <!-- /.btn-group -->
                <!-- 音频播放 -->
                <div class="row justify-content-end justify-content-sm-between">
                    <div class="col-12 col-sm-auto order-12 order-sm-1"><audio :src="nowAudio" controls="controls" autoplay="autoplay" class="audioPlay  mt-1 mt-sm-0" controlslist="nodownload"></audio></div>
                    <div v-if="query.userName" class="col-auto order-1 order-sm-12">
                        <div class="input-group input-group-sm ">
                            <div class="input-group-prepend">
                                <span class="input-group-text">{{query.userName}}</span>
                            </div>
                            <div class="input-group-prepend">
                                <span class="input-group-text" @click="query.userName='';loadData()"><i class="fas fa-times"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto order-1 order-sm-12">
                        <div class="input-group input-group-sm ">
                            <div class="input-group-prepend">
                                <span class="input-group-text" style="font-weight:700">标签</span>
                            </div>
                            <select class="form-control" v-model:value="query.tag" v-on:change="search()" placeholder="标签">
                                <option value="">所有</option>
                                <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <!-- /.pull-right -->
            </div>
            <vue-bootstrap4-table :rows="list"
                                  :columns="columns"
                                  :config="config"
                                  :classes="classes"
                                  @on-change-query="onChangeQuery">
                <template slot="sort-asc-icon">
                    <i class="fas fa-sort-up"></i>
                </template>
                <template slot="sort-desc-icon">
                    <i class="fas fa-sort-down"></i>
                </template>
                <template slot="no-sort-icon">
                    <i class="fas fa-sort"></i>
                </template>
                <template slot="empty-results">
                    没有数据
                </template>
                <template slot="active" slot-scope="props">
                    <button class="btn btn-default btn-sm mx-1" title="编辑" @click="edit(props.row.guid)">
                        <i class="fas fa-pen-square"></i>
                    </button>
                    <button class="btn btn-default btn-sm mx-1" title="删除" @click="deleteitem(props.row.guid)">
                        <i class="fas fa-trash"></i>
                    </button>
                    <button class="btn btn-default btn-sm mx-1" title="播放" @click="play(props.row.url)">
                        <i class="fas fa-play"></i>
                    </button>
                </template>
                <template slot="duration" slot-scope="props">
                    {{props.row.duration.substr(0,12)}}
                </template>
                <template slot="user" slot-scope="props">
                    <a v-if="props.row.user" href="javascript:;" @click="query.userName=props.row.user.userName;loadData()">{{props.row.user.nickName}}</a>
                    <a v-else href="javascript:;" @click="query.userName='<公共>';loadData()"><公共></a>
                </template>
                <template slot="closed" slot-scope="props">
                    {{props.row.closed ? "是" : "否"}}
                </template>
            </vue-bootstrap4-table>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <div class="card-footer p-2">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
        </div>
        <Modal ref="UploadModal">
            <template slot="header">
                <h4 class="modal-title">音频上传</h4>
            </template>
            <UploadBox action="/Api/Admin/Audios/Upload"
                         :multiple="true"
                         :maxSize="2"
                         :allowExtensions="/(.aac|.mp3)$/i"
                         :partSizeMB="10"
                         :maxUpper="3"
                         :uploadFinished="search"
                         previewIcon="fas fa-file-audio"
                         :changeName="changeName">
            </UploadBox>
            <template slot="footer">
                <span hidden>hidden</span>
            </template>
        </Modal>
        <Modal ref="Edit">
            <template slot="header">
                <div class="nav-tabs-custom">
                    <h4 class="modal-title">编辑音频</h4>
                </div>
            </template>
            <form class="form-horizontal">
                <div class="card-body">
                    <div class="form-group">
                        <label for="editName" class="col-sm-2 control-label">名称</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="editName" placeholder="名称" v-model.trim="item.name">
                        </div>
                    </div>
                </div>
            </form>
            {{item.roles}}
            <template slot="footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="save()" :disabled="updateing">保存</button>
            </template>
        </Modal>
        <Modal ref="Delete">
            <template slot="header">
                <h4 class="modal-title">删除音频</h4>
            </template>
            {{item}}
            <template slot="footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-danger" @click="del()" :disabled="updateing">删除</button>
            </template>
        </Modal>
        <!-- /.modal -->
    </div>
</template>

<script>
    import { Modal, UploadBox } from 'common'
    //import Modal from '@/components/Modal'
    //import UploadBox from '@/components/UploadBox'

    export default {
        components: {
            UploadBox,
            Modal,
        },
        data() {
            return {
                list: [],
                publicTags: [],
                privateTags: [],
                item: {},
                columns: [
                    { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
                    { name: 'name', label: '名称', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'duration', label: '时长', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c_du", row_text_alignment: "text-left" },
                    { name: 'addTime', label: '添加时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
                    { name: 'user', label: '用户', column_classes: "text-nowrap c_user", row_text_alignment: "text-left" },
                    { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
                ],
                classes: {
                    tableWrapper: "table-responsive",
                    table: "table-bordered table-sm table-hover",
                    //row: {
                    //    "my-row my-row2": true,
                    //    "function-class": function (row) {
                    //        return row.id == 1
                    //    }
                    //},
                    //cell: {
                    //    "my-cell my-cell2": true,
                    //    "text-danger": function (row, column, cellValue) {
                    //        return column.name == "salary" && row.salary > 2500
                    //    }
                    //}
                },
                config: {
                    //checkbox_rows: true,
                    //rows_selectable: true,
                    card_mode: false,
                    pagination: false,
                    pagination_info: false,
                    global_search: {
                        visibility: false,
                    },
                    show_refresh_button: false,
                    show_reset_button: false,
                    multi_column_sort: false,
                    server_mode: true,
                },
                pager: {
                    prePageSize: [10, 20, 50, 100],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 20,
                    sort: "addTime",
                    order: "asc",
                    fields: 'Title,FileName',
                    userName: null,
                    key: null,
                    tag: '',
                },
                nowAudio: null,
                loading: false,
                updateing: false,
            }
        },
        created() {
            this.loadTags()
            this.loadData()
        },
        computed: {
        },
        methods: {
            loadData() {
                var _this = this;
                this.loading = true;
                var config = {
                    params: {
                        page: this.query.pageIndex,
                        pageSize: this.query.pageSize,
                        sort: this.query.sort,
                        order: this.query.order,
                        fields: this.query.fields,
                        userName: this.query.userName,
                        key: this.query.key,
                        tag: this.query.tag
                    }
                };
                this.$axios.get('/Api/Admin/Audios', config).then(function (res) {
                    //console.log(res.data)
                    _this.list = res.data.data
                    _this.pager.pageIndex = res.data.pageIndex
                    _this.pager.pageSize = res.data.pageSize
                    _this.pager.totalPages = res.data.totalPages
                    _this.pager.totalRecords = res.data.totalRecords
                    _this.loading = false;

                }).catch(res => {
                    _this.loading = false;
                })

            },
            search() {
                this.query.pageIndex = 1;
                this.loadData();
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            onChangeQuery(queryParams) {
                var sort = queryParams.sort[0]
                this.query.sort = sort.name
                this.query.order = sort.order
                this.loadData()
            },
            loadTags() {
                let self = this
                var tagtype = 0;
                for (var i in this.types) {
                    if (this.types[i].value == this.query.type) {
                        tagtype = this.types[i].tagtype;
                        break;
                    }
                }
                this.$axios.get('/Api/My/Tags/All', { params: { type: tagtype } })
                    .then(function (response) {
                        //console.log(response)
                        self.privateTags = response.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                this.$axios.get('/Api/Tags/All', { params: { type: tagtype } })
                    .then(function (response) {
                        //console.log(response)
                        self.publicTags = response.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            loadAudio(id) {
                let _this = this
                this.$axios.get('/Api/Admin/Audios/' + id)
                    .then(function (response) {
                        //console.log(response)
                        _this.item = response.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            reset() {
                let _this = this
                _this.item.guid = null
                _this.item.userId = null
                _this.item.userName = null
                _this.item.nickName = null
                _this.item.password = null
                _this.item.email = null
                _this.item.phoneNumber = null
                _this.item.closed = false
                _this.item.roles = []
            },
            create() {
                this.$refs.UploadModal.show()
                //this.reset()
            },
            edit(id) {
                console.log(id)
                this.$refs.Edit.show()
                this.loadAudio(id)
            },
            deleteitem(id) {
                console.log(id)
                this.$refs.Delete.show()
                this.loadAudio(id)
            },
            save() {
                let _this = this
                _this.updateing = true;
                if (_this.item.guid != null) {
                    this.$axios.put('/Api/Admin/Audios/' + _this.item.guid, _this.item)
                        .then(function (response) {
                            _this.reset()
                            _this.loadData();
                            _this.$refs.Edit.hide()
                            //this.loadData();
                            _this.updateing = false;
                        })
                        .catch(function (error) {
                            console.log(error)
                            _this.updateing = false;
                        })
                } else {
                    _this.$message({
                        message: '发生了错误 保存缺失GUID',
                        type: 'error',
                        zIndex: 9999,
                    })
                }
            },
            del() {
                let _this = this
                _this.updateing = true;
                this.$axios.delete('/Api/Admin/Audios/' + _this.item.guid)
                    .then(function (response) {
                        _this.$message({
                            message: '删除成功',
                            type: 'info',
                            zIndex: 9999,
                        })
                        _this.updateing = false;
                    })
                    .catch(function (error) {
                        //_this.$message({
                        //    message: '删除失败\n' + error,
                        //    type: 'error',
                        //    zIndex: 9999,
                        //})
                        _this.updateing = false;
                    })
            },
            changeName(item, res) {
                var _this = this;
                if (res) {
                    console.log(item, res)
                    var data = { ...res };
                    data.name = item.title;
                    console.log(data)
                    this.$axios.put('/api/Admin/files/' + data.guid, data).then(function (res) {
                        if (res.status == 200) {
                            _this.search()
                        }
                    })
                }
            },
            play(url) {
                this.nowAudio = url;
            },
        },
        computed: {
            tags: function () {
                return [...this.publicTags, ...this.privateTags]
            }
        },

    }
</script>

<style lang="css">
    .AudioList .audioPlay {
        height: 30px;
        vertical-align: bottom;
        /*margin: 5px 0 0;*/
        max-width: 100%;
    }

    .AudioList .c1 {
        width: 125px;
    }

    .AudioList .c2 {
        width: 60px;
    }

    .AudioList .c_dt {
        width: 155px;
    }

    .AudioList .c_du {
        width: 125px;
    }
</style>
