<template lang="html">
    <div class="card card-primary card-outline">
        <!--<div class="overlay" v-if="$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>-->
        <div class="card-header p-1">
            <div class="card-title">
                <button type="button" class="btn btn-default btn-sm mr-1" v-on:click="Create()" title="添加"><i class="fa fa-plus"></i></button>
                <button type="button" class="btn btn-default btn-sm" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
            </div>
            <div class="card-tools mr-0">
                <div class="input-group input-group-sm m-0">
                    <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" v-on:click="search()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- /.card-tools -->
        </div>
        <!-- /.box-header -->
        <div class="card-body p-1">
            <div class="mailbox-controls">
                <!-- Check all button -->
                <!--<div class="pull-right">
                  <div class="form-inline">
                    <div class="form-group form-group-sm">
                      <label>角色</label>
                      <select class="form-control" v-model:value="query.roles" v-on:change="search()" placeholder="角色">
                        <option value="">所有</option>
                        <option v-for="i in roles" v-bind:value="i.name">{{i.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>-->
                <!-- /.pull-right -->
            </div>
            <vue-bootstrap4-table :rows="list"
                                  :columns="columns"
                                  :config="config"
                                  :classes="classes"
                                  @on-change-query="onChangeQuery">
                <template slot="sort-asc-icon">
                    <i class="fas fa-sort-up"></i>
                </template>
                <template slot="sort-desc-icon">
                    <i class="fas fa-sort-down"></i>
                </template>
                <template slot="no-sort-icon">
                    <i class="fas fa-sort"></i>
                </template>
                <template slot="empty-results">
                    没有数据
                </template>
                <template slot="active" slot-scope="props">
                    <button class="btn btn-default btn-xs mx-1" title="查看" @click="View(props.row.guid)">
                        <i class="fas fa-book-open"></i>
                    </button>
                    <!--<button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
                        <i class="fas fa-trash"></i>
                    </button>-->
                </template>
                <template slot="user" slot-scope="props">
                    {{props.row.user.nickName||props.row.user.userName}}
                </template>
                <template slot="closed" slot-scope="props">
                    {{props.row.closed ? "是" : "否"}}
                </template>
            </vue-bootstrap4-table>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <div class="card-footer p-2">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
        </div>
        <Modal ref="OrderModal">
            <template slot="header">
                <h4 class="modal-title">查看订单</h4>
            </template>

            <div v-if="order">
                <!--<h3 class="title" v-if="order&&order.inn">{{order.inn.isChain?'连锁':''}}{{order.inn.levelName}}{{order.inn.title}}{{order.inn.typeName}}</h3>-->
                <vue-bootstrap4-table :rows="order.items"
                                      :columns="OrderItemColumns"
                                      :config="config"
                                      :classes="classes">
                    <template slot="sort-asc-icon">
                        <i class="fas fa-sort-up"></i>
                    </template>
                    <template slot="sort-desc-icon">
                        <i class="fas fa-sort-down"></i>
                    </template>
                    <template slot="no-sort-icon">
                        <i class="fas fa-sort"></i>
                    </template>
                    <template slot="empty-results">
                        没有数据
                    </template>
                    <template slot="inntitle" slot-scope="props">
                        {{ (props.row.room||{}).title }}
                    </template>
                </vue-bootstrap4-table>
                <p>时间： {{order.addTime}}</p>
                <p>总价格： {{order.totalPrice}}</p>
                <p>客户名：{{order.user.nickName||order.user.userName}}</p>
                <p>{{order.stateName}}</p>
            </div>
            <template v-slot:footer>
                <button type="button" class="btn btn-default" @click="closeOrderModal">取消</button>
                <div>
                    <a v-if="setting&&order&&order.user" target="_blank" :href="setting.myUrl + '/Chat/' + order.user.userName" class="btn btn-default" @click="closeOrderModal">联系客户</a>
                    <template v-if="order&&order.stateName!='订单关闭'">
                        <button type="button" class="btn btn-default">已付款</button>
                        <button type="button" class="btn btn-default">结算</button>
                        <button type="button" class="btn btn-danger" @click="OpenOrderClose()">关闭订单</button>
                    </template>
                </div>
            </template>
        </Modal>
        <Modal ref="delModal">
            <template slot="header">
                <h4 class="modal-title">删除订单</h4>
            </template>
            确定删除订单 {{(item||{}).title}} ?
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="Delete(item.id)">确定</button>
            </template>
        </Modal>
        <Modal ref="CloseModal">
            <template slot="header">
                <h4 class="modal-title">关闭订单</h4>
            </template>
            <div v-if="order&&order.user">
                确定关闭 {{order.user.nickName||order.user.userName}} 的订单 ?<br />
                此操作不可撤销
            </div>
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="OrderClose(order.guid)">确定</button>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { Modal } from 'common'
    //import Modal from '@/components/Modal.vue'

    export default {
        data() {
            return {
                columns: [
                    { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
                    { name: 'inn.title', label: '客栈名称', sort: false, initial_sort_order: 'asc', column_classes: "text-nowrap" },
                    { name: 'itemsTotalPrice', label: '价格', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'addTime', label: '添加时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'stateName', label: '状态', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
                ],
                classes: {
                    tableWrapper: "table-responsive",
                    table: "table-bordered table-sm table-hover",
                    //row: {
                    //    "my-row my-row2": true,
                    //    "function-class": function (row) {
                    //        return row.id == 1
                    //    }
                    //},
                    //cell: {
                    //    "my-cell my-cell2": true,
                    //    "text-danger": function (row, column, cellValue) {
                    //        return column.name == "salary" && row.salary > 2500
                    //    }
                    //}
                },
                config: {
                    //checkbox_rows: true,
                    //rows_selectable: true,
                    card_mode: false,
                    pagination: false,
                    pagination_info: false,
                    global_search: {
                        visibility: false,
                    },
                    show_refresh_button: false,
                    show_reset_button: false,
                    multi_column_sort: false,
                    server_mode: true,
                },
                OrderItemColumns: [
                    { name: 'inntitle', label: '房间名称', sort: false, initial_sort_order: 'asc', column_classes: "text-nowrap" },
                    { name: 'date', label: '日期', sort: false, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'price', label: '价格', sort: false, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                ],
                pager: {
                    prePageSize: [10, 20, 50, 100],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 20,
                    sort: "addTime",
                    order: true,
                    fields: 'Title',
                    key: null,
                },
                order: null,
                rooms: {},
                item: null,
                loading: false,
            }
        },
        created() {
            this.loadData()
        },
        computed: {
        },
        methods: {
            loadData() {
                let _this = this
                _this.loading = true
                this.$axios.get('/Api/InnAdmin/Orders', {
                    params: {
                        page: this.query.pageIndex,
                        pageSize: this.query.pageSize,
                        sort: this.query.sort,
                        order: this.query.order,
                        fields: this.query.fields,
                        key: this.query.key,
                        innId: this.$route.params.innId
                    }
                })
                    .then(function (response) {
                        _this.pager.pageIndex = response.data.pageIndex
                        _this.pager.pageSize = response.data.pageSize
                        _this.pager.totalPages = response.data.totalPages
                        _this.pager.totalRecords = response.data.totalRecords
                        _this.list = response.data.data
                        _this.loading = false
                    })
                    .catch(function (error) {
                        console.log(error)
                        _this.loading = false
                    })
            },
            search() {
                this.query.pageIndex = 1;
                this.loadData();
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            onChangeQuery(queryParams) {
                var sort = queryParams.sort[0]
                this.query.sort = sort.name
                this.query.order = sort.order
                this.loadData()
            },
            delDialog(item) {
                this.$refs.delModal.show()
                this.item = item
            },
            Delete(id) {
                let _this = this
                this.$axios.delete('/Api/InnAdmin/Orders' + id)
                    .then(function (response) {
                        console.log(response)
                        //_this.query.data.splice(i, 1)
                        _this.loadData()
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            //Create() {
            //    this.$router.push({ name: 'InnEdit' })
            //},
            //Edit(id) {
            //    this.$router.push({ name: 'InnEdit', params: { innId: id } })
            //},
            //Room(id) {
            //    this.$router.push({ name: 'InnRoom', params: { innId: id } })
            //}
            View(id) {
                var _this = this
                this.$axios.get('/Api/InnAdmin/Orders/' + id).then(res => {
                    //console.log(res);
                    _this.order = res.data;
                    //this.$bus.$emit('OrderModal-modal-open', {});
                    this.$refs.OrderModal.show()

                })
            },
            closeOrderModal() {
                //this.$bus.$emit('OrderModal-modal-hide', {});
                this.$refs.OrderModal.hide()
            },
            OpenOrderClose() {
                this.$refs.CloseModal.show()
            },
            OrderClose(guid) {
                this.$axios.get('/Api/InnAdmin/Orders/Close/' + guid)
                    .then(res => {
                        this.$refs.CloseModal.hide()
                        this.$refs.OrderModal.hide()
                        this.loadData()
                    })
                    .catch(err => {

                    })
            }
        },
        components: {
            Modal
        }
    }
</script>
