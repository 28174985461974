<template lang="html">
    <div class="card card-primary card-outline RoleList">
        <div class="card-header p-2">
            <h3 class="card-title">模块管理</h3>
            <div class="card-tools mr-0">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" v-on:click="search()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- /.box-header -->
        <div class="card-body p-1">
            <div class="mailbox-controls">
                <button type="button" class="btn btn-primary btn-sm mr-1" v-on:click="create()" title="新建"><i class="fa fa-plus"></i></button>
                <button type="button" class="btn btn-default btn-sm" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
                <!--<div class="pull-right">
                    <div class="form-inline">
                        <div class="form-group form-group-sm">
                            <label>角色</label>
                            <select class="form-control" v-model:value="query.roles" v-on:change="search()" placeholder="角色">
                                <option value="">所有</option>
                                <option v-for="i in roles" v-bind:value="i">{{i}}</option>
                            </select>
                        </div>
                    </div>
                </div>-->
                <!-- /.pull-right -->
            </div>
            <vue-bootstrap4-table :rows="list"
                                  :columns="columns"
                                  :config="config"
                                  :classes="classes"
                                  @on-change-query="onChangeQuery">
                <template slot="sort-asc-icon">
                    <i class="fas fa-sort-up"></i>
                </template>
                <template slot="sort-desc-icon">
                    <i class="fas fa-sort-down"></i>
                </template>
                <template slot="no-sort-icon">
                    <i class="fas fa-sort"></i>
                </template>
                <template slot="empty-results">
                    没有数据
                </template>
                <template slot="active" slot-scope="props">
                    <button class="btn btn-default btn-xs mx-1" title="编辑" @click="edit(props.row)">
                        <i class="fas fa-pen-square"></i>
                    </button>
                    <button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
                        <i class="fas fa-trash"></i>
                    </button>
                </template>
                <template slot="hasCategory" slot-scope="props">
                    {{props.row.hasCategory ? "是" : "否"}}
                </template>
                <template slot="closed" slot-scope="props">
                    {{props.row.closed ? "是" : "否"}}
                </template>
            </vue-bootstrap4-table>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="this.$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <div class="card-footer p-2">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
        </div>
        <Modal ref="Edit">
            <template slot="header">
                <h4 class="modal-title">{{ item.guid!=null ? '修改模块':  '新建模块' }}</h4>
            </template>
            <form class="form-horizontal">
                <div class="card-body">
                    <div class="form-group">
                        <label for="editName" class="col-sm-2 control-label">名称</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="editName" placeholder="名称" v-model.trim="item.name">
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="editTitle" class="col-sm-2 control-label">标题</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" id="editTitle" placeholder="标题" v-model.trim="item.title">
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="item.hasCategory">
                            <label class="custom-control-label" for="customSwitch1">启用分类</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="item.closed">
                            <label class="custom-control-label" for="customSwitch2">关闭</label>
                        </div>
                    </div>
                </div>
            </form>
            <template slot="footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="save()" :disabled="updateing">保存</button>
            </template>
        </Modal>
        <Modal ref="delModal">
            <template slot="header">
                <h4 class="modal-title">删除模块</h4>
            </template>
            确定删除 {{(item||{}).name}} ?
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="del(item)" :disabled="updateing">确定</button>
            </template>
        </Modal>

        <!-- /.modal -->
    </div>
</template>

<script>
    import { Modal } from 'common'
    //import Modal from '@/components/Modal'

    export default {
        data() {
            return {
                list: [],
                item: this.newItem(),
                columns: [
                    { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
                    { name: 'title', label: '标题', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'name', label: '名称', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'hasCategory', label: '启用分类', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'sortID', label: '排序', sort: true, initial_sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                ],
                classes: {
                    tableWrapper: "table-responsive",
                    table: "table-bordered table-sm table-hover",
                    //row: {
                    //    "my-row my-row2": true,
                    //    "function-class": function (row) {
                    //        return row.id == 1
                    //    }
                    //},
                    //cell: {
                    //    "my-cell my-cell2": true,
                    //    "text-danger": function (row, column, cellValue) {
                    //        return column.name == "salary" && row.salary > 2500
                    //    }
                    //}
                },
                config: {
                    //checkbox_rows: true,
                    //rows_selectable: true,
                    card_mode: false,
                    pagination: false,
                    pagination_info: false,
                    global_search: {
                        visibility: false,
                    },
                    show_refresh_button: false,
                    show_reset_button: false,
                    multi_column_sort: false,
                    server_mode: true,
                },
                pager: {
                    prePageSize: [10, 20, 50, 100],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 20,
                    sort: "Name",
                    order: "asc",
                    fields: 'Name',
                    key: null
                },
                updateing: false,
            }
        },
        created() {
            //this.loadRoles()
            this.loadData()
        },
        computed: {

        },
        methods: {
            loadData() {
                var _this = this;
                var config = {
                    params: {
                        page: this.query.pageIndex,
                        pageSize: this.query.pageSize,
                        sort: this.query.sort,
                        order: this.query.order,
                        fields: this.query.fields,
                        key: this.query.key
                    }
                };
                this.$axios.get('/Api/Admin/Modules', config).then(function (res) {
                    //console.log(res.data)
                    _this.list = res.data.data
                    _this.pager.pageIndex = res.data.pageIndex
                    _this.pager.pageSize = res.data.pageSize
                    _this.pager.totalPages = res.data.totalPages
                    _this.pager.totalRecords = res.data.totalRecords
                })
            },
            newItem() {
                return {
                    id: null,
                    name: null,
                    hasCategory: false,
                    closed: false,
                }
            },
            search() {
                this.query.pageIndex = 1;
                this.loadData();
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            onChangeQuery(queryParams) {
                var sort = queryParams.sort[0]
                this.query.sort = sort.name
                this.query.order = sort.order
                this.loadData()
            },
            loadItem(id) {
                let _this = this
                this.$axios.get('/Api/Admin/Modules/' + id)
                    .then(function (response) {
                        //console.log(response)
                        let d = response.data
                        _this.item = d
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            reset() {
                this.item = this.newItem()
            },
            create() {
                this.$refs.Edit.show()
                this.reset()
            },
            edit(item) {
                console.log(item)
                this.$refs.Edit.show()
                this.loadItem(item.guid)
            },
            save() {
                let _this = this
                _this.updateing = true;
                if (_this.item.guid != null) {
                    this.$axios.put('/Api/Admin/Modules/' + _this.item.guid, _this.item)
                        .then(function (response) {
                            _this.reset()
                            _this.loadData();
                            _this.$refs.Edit.hide()
                            //this.loadData();
                            _this.updateing = false;
                        })
                        .catch(function (error) {
                            console.log(error)
                            _this.updateing = false;
                        })
                } else {
                    this.$axios.post('/Api/Admin/Modules', _this.item)
                        .then(function (response) {
                            _this.reset()
                            _this.loadData();
                            _this.$refs.Edit.hide()
                            _this.updateing = false;
                        })
                        .catch(function (error) {
                            console.log(error)
                            _this.updateing = false;
                        })
                }
            },
            delDialog(item) {
                this.$refs.delModal.show()
                this.item = item
            },
            del(item) {
                let _this = this
                _this.updateing = true;
                this.$axios.delete('/Api/Admin/Modules/' + item.guid)
                    .then(function (response) {
                        console.log(response)
                        _this.loadData()
                        _this.updateing = false;
                        _this.$refs.delModal.hide()
                    })
                    .catch(function (error) {
                        _this.updateing = false;
                        console.log(error)
                    })
            },

        },
        components: {
            Modal,
        }
    }
</script>

<style lang="css">
    .RoleList .c1 {
        width: 90px;
    }
</style>
