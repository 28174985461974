<template lang="html">
    <div>
        {{$route.params.id}}
        <div class="row">
            <div class="col-lg-3 col-6">
                <!-- small card -->
                <div class="small-box bg-info">
                    <div class="inner">
                        <h3>150</h3>

                        <p>房间</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-user-plus"></i>
                    </div>
                    <router-link :to="{ name: 'InnRoomList'}" class="small-box-footer">
                        管理 <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small card -->
                <div class="small-box bg-success">
                    <div class="inner">
                        <h3>53</h3>

                        <p>全景</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-image"></i>
                    </div>
                    <router-link :to="{ name: 'PanoList'}" class="small-box-footer">
                        管理 <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small card -->
                <div class="small-box bg-secondary">
                    <div class="inner">
                        <h3>44</h3>

                        <p>博文</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-file-image"></i>
                    </div>
                    <router-link :to="{ name: 'PanoList'}" class="small-box-footer">
                        管理 <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small card -->
                <div class="small-box bg-danger">
                    <div class="inner">
                        <h3>65</h3>

                        <p>漫游</p>
                    </div>
                    <div class="icon">
                        <i class="fa fa-globe"></i>
                    </div>
                    <router-link :to="{ name: 'PanoList'}" class="small-box-footer">
                        管理 <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
            <!-- ./col -->
        </div>
        <!-- /.row -->
    </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from 'vuex'

  export default {
    data() {
      return {
      }
    },
    created() {
     //this.getUser()
     //this.setPageInfo("主页")
    },
    computed: {
      ...mapGetters([
      ]),
      ...mapState({
      }),
    },
    methods: {
      ...mapActions([
       //'getUser',
       // 'setPageInfo'
      ]),
    },
    components: {
    }
  }
</script>

<style lang="css">
</style>
