<template>
    <div class="wrapper">
        <va-navibar></va-navibar>
        <va-slider :slideMenuItems="slideMenuItems"></va-slider>
        <va-content-wrap></va-content-wrap>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import VANaviBar from '@/components/NaviBar.vue'
    import VASlider from '@/components/Slider.vue'
    import VAContentWrap from '@/components/ContentWrap.vue'
    import store from '@/vuex/store.js'

    export default {
        name: 'Inn',
        data() {
            return {
                //mgr: new Mgr(),
                slideMenuItems: null,
                inn: null
            }
        },
        methods: {
            ...mapActions([
                'getSetting',
                'getUser'
            ]),
            loadData: function () {
                var self = this;
                var url = "/Api/InnAdmin/Inns/" + this.$route.params.id;
                this.$axios.get(url).then(function (res) {
                    self.inn = res.data;
                })
            },
            loadMenu: function () {
                var self = this;
                this.$axios.get('/Api/InnAdmin/Menus').then(function (res) {
                    self.slideMenuItems = res.data;
                    //$('.sidebar-menu').tree()
                })
            }
        },
        created: function () {
            if (this.$inRole("管理员")) {
                _this.getSetting()
                _this.getUser()
                _this.loadMenu();
                console.log(roles)
            } else {
                _this.$router.push({ name: 'NoAccess' })
            }
        },
        mounted() {
            //var self = this
            //this.getUser()
        },
        beforeDestroy() {
        },
        computed: {
            ...mapGetters([
                'setting',
                'unreadMessagesCount',
                'unreadNotificationsCount',
                'remainTasksCount',
                'currentUser'
            ]),
        },
        components: {
            'va-navibar': VANaviBar,
            'va-slider': VASlider,
            'va-content-wrap': VAContentWrap,
        },
        store
    }
</script>

<style>
</style>
