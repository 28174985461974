<template lang="html">
  <div class="blog_default">
    <div style="position:relative">
      <header>
        <!--<div style="display:flex;justify-content:space-between;padding:23px 0;align-items:center;">
            <div style="margin-left:25px">
                <i class="icon-close" style="width:1.88em;height:1.88em" />
            </div>
            <div style="margin-right:25px">
            </div>
        </div>-->
        <!--<div style="display:flex">
          <img :src="blog.firstImage.url" />
        </div>-->
        <h1>
          {{blog.title}}
        </h1>
        <div style="margin: 15px 25px 15px;padding-bottom:14px;border-bottom:1px solid #17202619;display:flex;justify-content:space-between;align-items: center;">
          <a style="flex:auto;cursor:pointer;display:flex;min-width:0;" :href="toUserUrl(blog.user.userName)">
            <div style="width:3.6em;height:3.6em;border-radius:50%;margin-right:5px;flex:none;align-self:center;" :style="getBgImgStyle(blog.user.avatarUrl)"></div>
            <div style="flex:auto;min-width:0;display:flex;flex-direction:column;justify-content:center;align-items:flex-start;">
              <div class="text-truncate" style="font-size:1.4em;line-height:1.2;width:100%;">{{blog.user.nickName}}</div>
              <time :datetime="blog.lastEdit||blog.addTime" pubdate style="color:#A2A6A8;font-size:1em;line-height:1.2;">{{blog.time}}发布</time>
            </div>
          </a>
          <div style="display:flex;align-items:flex-end;flex:none;">
            <div style="display:flex;align-items:flex-end;flex:none;margin-right:15px;" @click="praise">
              <i class="icon-praise" style="width:2.4em;height:2.2em;margin-top:0.8em" />
              <div style="align-self:flex-start;font-size:1em;line-height:1.2;font-weight:700;">{{blog.praiseCount}}</div>
            </div>
            <!--<div style="display:flex;align-items:flex-end;flex:none;" @click="share">
            <i class="icon-share" style="width:2rem;height:2rem" />
            </div>-->
          </div>
        </div>
      </header>
      <section>
        <div v-if="blog.text" style="margin-bottom:15px">
          <article v-html="blog.text">
          </article>
        </div>
        <div v-for="item in blog.items" style="margin-bottom:15px">
          <figure v-if="item.type==0">
            <div @click="clickPano(item)" style="position:relative">
              <img :src="(((item.linkItem||{}).firstImage||{}).url||'').replace('/0/0/0/0/', '/800/800/1/0/')">
              <div style="position:absolute;left:0;top:0;bottom:0;right:0;display:flex;justify-content:center;align-items:center">
                <div class="icon-panorama drop-shadow" style="font-size:50px" />
              </div>
            </div>
            <figcaption v-if="item.title">
              {{ item.title }}
            </figcaption>
            <article v-if="item.description" v-html="item.description">
            </article>
          </figure>
          <figure v-if="item.type==1">
            <div>
              <img :src="((item.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')">
            </div>
            <figcaption v-if="item.title">
              {{ item.title }}
            </figcaption>
            <article v-if="item.description" v-html="item.description">
            </article>
          </figure>
          <template v-if="item.type==2">
            <h3 v-if="item.title">
              {{ item.title }}
            </h3>
            <article v-if="item.description" v-html="item.description">
            </article>
          </template>
          <figure v-if="item.type==4">
            <div class="content">
              <audio :title="item.title" :src="(item.file||{}).url" controls @playing="closeOtherAudios($event)" />
            </div>
            <figcaption v-if="item.title">
              {{ item.title }}
            </figcaption>
            <article v-if="item.description" v-html="item.description">
            </article>
          </figure>
          <figure v-if="item.type==5">
              <video style="width:100%" :title="item.title" :src="(item.file||{}).url" :poster="((item.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls @playing="closeOtherAudios($event)" />
              <figcaption v-if="item.title">
                  {{ item.title }}
              </figcaption>
              <article v-if="item.description" v-html="item.description">
              </article>
          </figure>
          <template v-if="item.type==6">
            <div class="content">
              <a :title="item.title" :href="item.url"><i class="fas fa-link" />{{ item.title }}</a>
            </div>
            <article v-if="item.description" v-html="item.description">
            </article>
          </template>
        </div>
        <div></div>
      </section>
    </div>
    <div v-if="false">
      <div class="bhead">
        <h1 class="btitle">
          {{ blog.title }}
        </h1>
        <div v-if="blog.audio" class="bgaudiobtn" @click="bgaudio=!bgaudio">
          背景音乐
          <audio class="bgaudio" :class="{'show':bgaudio}" :src="(blog.audio||{}).url" :autoplay="blog.audioAutoPlay?'autoplay':null" controls @playing="closeOtherAudios($event)" />
        </div>
        <div class="lrline">
          <div class="user left">
            <a>全景旅行者</a>
            <a>
              <span>
                <img v-if="blog.user.avatarUrl" style="height:20px;border-radius:50%" :src="blog.user.avatarUrl.replace('/0/0/0/0/', '/50/50/1/0/')">
                <img v-else style="height:20px" src="/images/avatar-default.jpg">
              </span>
              {{ blog.user.nickName }}
            </a>
          </div>
          <div class="renewtime right">
            更新时间：<time :title="blog.lastEdit||blog.addTime" :datetime="blog.lastEdit||blog.addTime">{{ blog.time }}</time>
          </div>
        </div>
        <article style="margin-top:20px">
          <p>
            {{ blog.text }}
          </p>
        </article>
      </div>
      <div class="bbody">
        <div v-for="item in blog.items" class="bitem">
          <template v-if="item.type==0">
            <div class="bimgbox" @click="clickPano(item)">
              <img class="bimg" :src="(((item.linkItem||{}).firstImage||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')">
              <div class="panoOverlay" />
            </div>
            <h3 class="bimgtitle">
              {{ item.title }}
            </h3>
            <p>
              {{ item.description }}
            </p>
          </template>
          <template v-if="item.type==1">
            <div class="bimgbox">
              <img class="bimg" :src="((item.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')">
            </div>
            <h3 class="bimgtitle">
              {{ item.title }}
            </h3>
            <p>
              {{ item.description }}
            </p>
          </template>
          <template v-if="item.type==2">
            <h3 class="text-center">
              {{ item.title }}
            </h3>
            <p>
              {{ item.description }}
            </p>
          </template>
          <template v-if="item.type==4">
            <audio class="baudio" :title="item.title" :src="(item.file||{}).url" controls @playing="closeOtherAudios($event)" />
            <h3 class="baudiotitle">
              {{ item.title }}
            </h3>
            <p>
              {{ item.description }}
            </p>
          </template>
          <template v-if="item.type==5">
            <video class="bvideo" :title="item.title" :src="(item.file||{}).url" :poster="((item.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" controls @playing="closeOtherAudios($event)" />
            <h3 class="bvideotitle">
              {{ item.title }}
            </h3>
            <p>
              {{ item.description }}
            </p>
          </template>
          <template v-if="item.type==6">
            <i class="fas fa-link" />
            <a class="link" :title="item.title" :href="item.url">{{ item.title }}</a>
            <p>{{ item.description }}</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      blog: Object,
      toPano: {
        type: Function,
        default: (guid) => { }
      },
      toUserUrl: {
        type: Function,
        default: (name) => { }
      },
      praise: {
        type: Function,
        default: () => { }
      },
      share: {
        type: Function,
        default: () => { }
      },
    },
    data() {
      return {
        bgaudio: false
      }
    },
    mounted() {
    },
    methods: {
      clickPano(item) {
        if (this.toPano) {
          this.toPano(item.linkItem.guid)
        }
      },
      closeOtherAudios(e) {
        const thisav = e.srcElement
        const av = [...document.getElementsByTagName('audio'), ...document.getElementsByTagName('video')]
        console.log(av)
        for (const i in av) {
          if (thisav != av[i]) { av[i].pause() }
        }
      },
      getBgImgStyle(url, obj) {
        return {
          ...obj,
          'background-image': `url(${url})`,
          'background-size': 'cover',
          'background-position': 'center',
        }
      },

    }
  }
</script>
<style lang="css">
  .blog_default p {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    line-height: 1.8em;
    font-size: 1em;
    letter-spacing: 0.05em;
    text-align: justify;
    text-indent: 0;
  }
</style>
<style lang="css" scoped>
  img {
    width: 100%
  }

  figure {
    margin: 0;
    width: 100%;
  }

  h1 {
    padding: 30px 25px 0;
    font-size: 1.8em;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 0;
  }

  a {
    color: #172026
  }

  h3 {
    font-size: 1.6em;
    line-height: 1.4;
    font-weight: 700;
    margin: 0 25px;
  }

  article {
    font-size: 1.6em;
    line-height: 1.4;
    padding: 0 25px;
    text-indent: 0;
    letter-spacing: 0.05em;
    text-align: justify;
  }

  figcaption {
    font-size: 1.6em;
    line-height: 1.4;
    font-weight: 700;
    margin: 0 25px;
  }

  .blog_default {
    max-width: 640px;
    margin: auto;
    background-color: #fff;
    font-size: 10px;
    color: #172026;
    overflow: hidden;
  }

  .content {
    margin: 0 25px;
  }

  .icon-praise {
    display: inline-block;
    width: 1em;
    height: 1em;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDI0IDIyIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjMTcyMDI2OwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBpZD0ibGpfNzAiIGRhdGEtbmFtZT0ibGogNzAiIGNsYXNzPSJjbHMtMSIgZD0iTTEyLDIxLjk1MWExLjYzNSwxLjYzNSwwLDAsMS0uNzU1LS4xODZsLS4wNjctLjAzNEEyNy43MjgsMjcuNzI4LDAsMCwxLDUuMjUsMTcuNzU2YTE3LjgwNywxNy44MDcsMCwwLDEtMy42LTQuMjg3QTExLjU1NSwxMS41NTUsMCwwLDEsMCw3LjYwOSw3LjY4NSw3LjY4NSwwLDAsMSw0LjIxMi42NzMsNy4wNTMsNy4wNTMsMCwwLDEsMTIsMS43MzEsNy4wNTMsNy4wNTMsMCwwLDEsMTkuNzg2LjY3Miw3LjY4NSw3LjY4NSwwLDAsMSwyNCw3LjZhMTEuNTU1LDExLjU1NSwwLDAsMS0xLjY0OSw1Ljg2NSwxNy44MSwxNy44MSwwLDAsMS0zLjYsNC4yODcsMjcuNzIzLDI3LjcyMywwLDAsMS01LjkyNywzLjk3NGwtLjA2Ny4wMzRBMS42MzUsMS42MzUsMCwwLDEsMTIsMjEuOTUxWk03LjMxMywyLjAxOEE1LjQ3OCw1LjQ3OCwwLDAsMCwxLjk2OSw3LjYwOWMwLDYuNzE1LDcuNjQ1LDExLjA1OCwxMC4wMzEsMTIuMjQ3LDIuMzg3LTEuMTksMTAuMDMxLTUuNTMyLDEwLjAzMS0xMi4yNDdBNS41OTEsNS41OTEsMCwwLDAsMTguNjEyLDIuNGE1LjE4MSw1LjE4MSwwLDAsMC01Ljg3OCwxLjQ1NkwxMiw0LjdsLS43MzEtLjg0NkE1LjI0Miw1LjI0MiwwLDAsMCw3LjMxMywyLjAxOFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wNDkpIi8+Cjwvc3ZnPgo=') 50% 50% no-repeat;
    background-size: 100%;
  }
</style>
