<template lang="html">
  <div class="card card-primary card-outline UserList">
    <div class="card-header p-2">
      <h3 class="card-title">用户管理</h3>
      <!-- /.btn-group -->
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-1">
      <div class="mailbox-controls">
        <button type="button" class="btn btn-primary btn-sm mx-1" v-on:click="create()" title="新建用户"><i class="fa fa-plus"></i></button>
        <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
        <!-- Check all button -->
        <div class="float-right">
          <div class="dropdown">
            <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              筛选
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">角色</span>
                  </div>
                  <select class="form-control form-control-sm" v-model:value="query.roles" v-on:change="search()" placeholder="角色">
                    <option :value="null">所有</option>
                    <option v-for="i in roles" v-bind:value="i">{{i}}</option>
                  </select>
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">起始</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.beginTime" v-on:change="search()">
                </div>
              </form>
              <form class="dropdown-item p-1">
                <div class="input-group input-group-sm ">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-weight:700">结束</span>
                  </div>
                  <input type="date" class="form-control form-control-sm" v-model="query.endTime" v-on:change="search()">
                </div>
              </form>
              <form class="form-control-sm text-nowrap p-1">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed1" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="true" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed1">关闭</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed2" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="false" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed2">打开</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" id="customRadioClosed3" name="customRadioClosed" class="custom-control-input" v-model="query.closed" :value="null" v-on:change="search()">
                  <label class="custom-control-label" for="customRadioClosed3">不限</label>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /.pull-right -->
      </div>
      <vue-bootstrap4-table :rows="list"
                            :columns="columns"
                            :config="config"
                            :classes="classes"
                            @on-change-query="onChangeQuery">
        <template slot="sort-asc-icon">
          <i class="fas fa-sort-up"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="fas fa-sort-down"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="fas fa-sort"></i>
        </template>
        <template slot="empty-results">
          没有数据
        </template>
        <template slot="active" slot-scope="props">
          <button class="btn btn-default btn-xs mx-1" title="编辑" @click="edit(props.row.id)">
            <i class="fas fa-pen-square"></i>
          </button>
          <button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
            <i class="fas fa-trash"></i>
          </button>
        </template>
        <template slot="closed" slot-scope="props">
          {{props.row.closed ? "是" : "否"}}
        </template>
      </vue-bootstrap4-table>
    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="loading">
      <i class="fa fa-refresh fa-spin"></i>
    </div>
    <!-- end loading -->
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :prePageSize="pager.prePageSize"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="Edit">
      <template slot="header">
        <h4 class="modal-title">{{ item.id!=null ? '修改用户':  '新建用户' }}</h4>
      </template>
      <form class="form-horizontal">
        <div class="card-body">
          <div class="form-group">
            <label for="userName">用户名</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.item.userName.$error }" id="userName" v-model.trim="$v.item.userName.$model" placeholder="用户名">
            <div class="invalid-feedback">
              <span v-if="!$v.item.userName.required">请输入用户名</span>
              <span v-if="!$v.item.userName.minLength">用户名至少 {{$v.item.userName.$params.minLength.min}} 个字符</span>
              <span v-if="!$v.item.userName.maxLength">用户名最多 {{$v.item.userName.$params.maxLength.max}} 个字符</span>
              <span v-if="!$v.item.userName.isUnique">用户名不可用</span>
            </div>
          </div>
          <div class="form-group">
            <label for="nickName">昵称</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.item.nickName.$error }" id="nickName" v-model.trim="$v.item.nickName.$model" placeholder="昵称">
            <div class="invalid-feedback">
              <span v-if="!$v.item.nickName.required">请输入昵称</span>
              <!--<span v-if="!$v.item.nickName.minLength">昵称至少 {{$v.item.nickName.$params.minLength.min}} 个字符</span>-->
              <span v-if="!$v.item.nickName.maxLength">昵称最多 {{$v.item.nickName.$params.maxLength.max}} 个字符</span>
            </div>
          </div>
          <div class="form-group">
            <label for="editPassword">密码</label>
            <input type="password" class="form-control" id="editPassword" placeholder="密码" v-model="item.password" autocomplete="new-password">
          </div>
          <div class="form-group">
            <label for="email">电子邮件</label>
            <div class="input-group">
              <input type="email" class="form-control" :class="{ 'is-invalid': $v.item.email.$error }" id="email" v-model.trim="$v.item.email.$model" placeholder="Email">
              <div v-if="!item.emailConfirmed" class="input-group-prepend">
                <button class="btn btn-primary">验证</button>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.item.email.required">请输入 Email</span>
                <span v-if="!$v.item.email.email">无效的 Email 格式</span>
                <span v-if="!$v.item.email.isUnique">此邮件已经被其它账户绑定</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="phoneNumber">手机号码</label>
            <div class="input-group">
              <input type="tel" class="form-control" :class="{ 'is-invalid': $v.item.phoneNumber.$error }" id="phoneNumber" v-model.trim="$v.item.phoneNumber.$model" placeholder="手机号码" maxlength="11">
              <div v-if="!item.phoneNumberConfirmed" class="input-group-prepend">
                <router-link class="btn btn-primary" :to="{ name: 'ConfirmPhoneNumber'}">验证</router-link>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.item.phoneNumber.required">请输入手机号码</span>
                <span v-if="!$v.item.phoneNumber.isMobile">无效的手机号码格式</span>
                <span v-if="!$v.item.phoneNumber.isUnique">此号码已经被其它账户绑定</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>角色</label>
            <div>
              <label class="checkbox-inline mr-2" v-for="i in roles">
                <input type="checkbox" v-bind:value="i" v-model="item.roles">{{i}}
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="">
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="item.closed"> 关闭
                </label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="summary">个人介绍</label>
            <!--<textarea type="text" class="form-control" id="summary" v-model:value="item.summary" placeholder="个人介绍"></textarea>-->
            <PellEditor v-model.trim="item.summary"
                        :options="options"
                        :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
          </div>
        </div>
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" data-dismiss="modal">取消</button>
        <button type="button" class="btn btn-primary" @click="save()">保存</button>
      </template>
    </Modal>
    <Modal ref="delModal">
      <template slot="header">
        <h4 class="modal-title">删除用户</h4>
      </template>
      确定删除 {{(item||{}).userName}} ?
      <template slot="footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
        <button type="button" class="btn btn-primary" @click="del(item.id)" :disabled="updateing">确定</button>
      </template>
    </Modal>
    <!-- /.modal -->
  </div>

</template>

<script>
  import { Modal, PellEditor } from 'common'
  //import Modal from '@/components/Modal'
  //import PellEditor from '@/components/PellEditor.vue'
  import { required, minLength, maxLength, email, helpers } from 'vuelidate/lib/validators'
  const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
  const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
  const isUrl = helpers.regex('isUrl', /^((http:\/\/)|(https:\/\/))?([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.?)+(([a-zA-Z]{2,6})|(:[0-9]{1,5}))$/)

  export default {
    components: {
      Modal,
      PellEditor,
    },
    data() {
      return {
        list: [],
        roles: [],
        item: {
          userId: null,
          id: null,
          userName: null,
          password: null,
          email: null,
          phoneNumber: null,
          nickName: null,
          closed: false,
          roles: [],
        },
        columns: [
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
          { name: 'userId', label: 'Id', sort: true, initial_sort: true, initial_sort_order: 'desc', uniqueId: true, column_classes: "text-nowrap" },
          { name: 'userName', label: '用户名', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap" },
          { name: 'nickName', label: '昵称', sort: true, column_classes: "text-nowrap", row_classes: "text-nowrap" },
          { name: 'roles', label: '角色', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left", },
          { name: 'email', label: '电子邮件', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap", row_classes: "text-nowrap" },
          { name: 'phoneNumber', label: '手机号码', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap" },
          { name: 'lastLoginIP', label: '最后IP', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
          { name: 'lastLoginTime', label: '最后登录', sort: true, column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
          { name: 'addTime', label: '注册时间', sort: true, column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
          { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: { ...this.getDefaultQuery(), ...this.$route.query },
        loading: false,
        updateing: false,
        options: {
          whiteList: {
            p: [],
            b: [],
            strong: [],
            i: [],
            u: [],
            strike: [],
            br: [],
            //h1: [],
            //h2: [],
            //blockquote: [],
            //hr: [],
            ol: [],
            ul: [],
            li: [],
            //code: [],
            //a: ['href'],
            //img: ['src'],
            sub: [],
            sup: [],
          },
          stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
          stripIgnoreTagBody: ['script'] // script标签较特殊，需要过滤标签中间的内容
        }
      }
    },
    validations: {
      item: {
        userName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(30),
          isUnique(value) {
            // standalone validator ideally should not assume a fields is required
            if (value === '') return true
            // simulate async call, fail for all logins with even length
            return new Promise((resolve, reject) => {
              this.$axios.get(`/Api/Admin/Identity/CheckUserName/${this.item.id}/${encodeURI(value)}`)
                .then(function (response) {
                  //console.log(response)
                  resolve(response.data)
                }).catch(function (error) {
                  console.error(error)
                })
            })
          }
        },
        nickName: {
          //required,
          //minLength: minLength(3),
          maxLength: maxLength(30),
        },
        email: {
          //required,
          email,
          isUnique(value) {
            // standalone validator ideally should not assume a fields is required
            if (value === '') return true
            // simulate async call, fail for all logins with even length
            return new Promise((resolve, reject) => {
              this.$axios.get(`/Api/Admin/Identity/CheckEmail/${this.item.id}/${encodeURI(value)}`)
                .then(function (response) {
                  //console.log(response)
                  resolve(response.data)
                }).catch(function (error) {
                  console.error(error)
                })
            })
          }
        },
        phoneNumber: {
          //required,
          isMobile,
          isUnique(value) {
            // standalone validator ideally should not assume a fields is required
            if (value === '') return true
            // simulate async call, fail for all logins with even length
            return new Promise((resolve, reject) => {
              this.$axios.get(`/Api/Admin/Identity/CheckPhoneNumber/${this.item.id}/${encodeURI(value)}`)
                .then(function (response) {
                  //console.log(response)
                  resolve(response.data)
                }).catch(function (error) {
                  console.error(error)
                })
            })
          }
        },
      }
    },
    created() {
      this.loadRoles()
      this.loadData()
    },
    computed: {
    },
    methods: {
      loadData() {
        this.loading = true
        var config = {
          params: {
            ...this.query
          }
        };
        var url = window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/Admin/Users` : `/Api/Admin/Users`;
        this.$axios.get(url, config).then((res) => {
          //console.log(res.data)
          this.list = res.data.data
          this.pager.pageIndex = res.data.pageIndex
          this.pager.pageSize = res.data.pageSize
          this.pager.totalPages = res.data.totalPages
          this.pager.totalRecords = res.data.totalRecords
          this.loading = false
        })

      },
      getDefaultQuery() {
        return {
          page: 1,
          pageSize: 20,
          sort: "userId",
          order: "desc",
          fields: null,
          key: null,
          roles: null,
          closed: null,
          beginTime: null,
          endTime: null,
        }
      },
      getDifferentQuery(query) {
        var dif = {}
        var all = { ...this.query, ...query }
        var def = this.getDefaultQuery()
        for (var i in all) {
          if (def[i] != all[i]) {
            dif[i] = all[i]
          }
        }
        return dif
      },
      setQuery(newquery) {
        if (!newquery) {
          this.query = this.getDefaultQuery()
        }
        var dif = this.getDifferentQuery(newquery)
        console.log(this.$route.query, dif)
        if (!this.$isObjectValueEqual(this.$route.query, dif)) {
          this.$router.push({ query: dif })
        }
      },
      search() {
        this.setQuery({ page: 1 })
      },
      pageChange(page, pageSize) {
        this.setQuery({ page, pageSize })
      },
      onChangeQuery(queryParams) {
        var sort = queryParams.sort[0]
        this.setQuery({ sort: sort.name, order: sort.order })
      },
      loadRoles() {
        this.$axios.get('/Api/Admin/IdentityRoles/All')
          .then((response) => {
            //console.log(response)
            this.roles = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      loadUser(id) {
        this.loading = true
        this.$axios.get(`/Api/Admin/Identity/${id}`)
          .then((response) => {
            this.item = response.data
            this.loading = false
          })
          .catch((error) => {
            console.log(error)
            this.loading = false
          })
      },
      reset() {
        this.item.id = null
        this.item.userId = null
        this.item.userName = null
        this.item.nickName = null
        this.item.password = null
        this.item.email = null
        this.item.phoneNumber = null
        this.item.closed = false
        this.item.roles = []
      },
      create() {
        this.$refs.Edit.show()
        this.reset()
      },
      edit(id) {
        console.log(id)
        this.$refs.Edit.show()
        this.loadUser(id)
      },
      save() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let self = this
        if (this.item.id != null) {
          this.$axios.put(`/Api/Admin/Identity/${this.item.id}`, this.item)
            .then((response) => {
              this.reset()
              this.loadData();
              this.$refs.Edit.hide()
              //this.loadData();
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          this.$axios.post('/Api/Admin/Identity', this.item)
            .then((response) => {
              this.reset()
              this.loadData();
              this.$refs.Edit.hide()
            })
            .catch((error) => {
              console.log(error)
            })
        }
      },
      delDialog(item) {
        this.$refs.delModal.show()
        this.item = item
      },
      del(id) {
        this.updateing = true;
        this.$axios.delete(`/Api/Admin/Identity/${id}`)
          .then((response) => {
            console.log(response)
            this.loadData()
            this.updateing = false;
            this.$refs.delModal.hide()
          })
          .catch((error) => {
            this.updateing = false;
            console.log(error)
          })
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.query = { ...this.getDefaultQuery(), ...to.query }
      this.loadData()
      next()
    }
  }
</script>

<style lang="css">
  .UserList .c1 {
    width: 90px;
  }

  .UserList .c2 {
    width: 60px;
  }

  .UserList .c_dt {
    width: 155px;
  }
</style>
