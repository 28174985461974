<template lang="html">
    <div class="card card-primary card-outline lib">
        <div class="overlay" v-if="$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <div class="card-header p-1">
            <div class="card-title">
                <button class="btn btn-default btn-sm mx-1" @click="showUploadDialog()" title="添加"><i class="fa fa-plus"></i></button>
                <button class="btn btn-default btn-sm mx-1" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
            </div>
            <div class="card-tools mr-0">
                <div class="input-group input-group-sm m-0">
                    <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" v-on:click="search()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- /.box-tools -->
        </div>
        <div class="mailbox-controls">
            <!--<div class="form-inline d-inline-block">
                <div class="form-group form-group-sm">
                    <label>类型</label>
                    <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                        <option value="">所有</option>
                        <option v-for="i in types" v-bind:value="i.value">{{i.name}}</option>
                    </select>
                </div>
            </div>-->
            <div class="row justify-content-end">
                <div class="col-auto col-xl-2 col-sm-5 order-1 order-sm-12 ">
                    <div class="input-group input-group-sm ">
                        <div class="input-group-prepend">
                            <span class="input-group-text" style="font-weight:700">标签</span>
                        </div>
                        <select class="form-control" v-model:value="query.tags" v-on:change="search()" placeholder="标签">
                            <option value="">所有</option>
                            <option v-for="i in tags" v-bind:value="i.name">{{i.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-xl col-sm-7 order-12 order-sm-1">
                    <ul class="nav nav-pills">
                        <li class="nav-item" v-for="i in types">
                            <a class="nav-link px-2 py-1 mt-1" :class="{'active':query.type==i.value}" :href="'#'+i.value" data-toggle="tab" @click="changeType(i.value)">{{i.name}}</a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
        <div class="row card-body p-1">
            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" v-for="i in list">
                <template v-if="i.isImage">
                    <LibImageCard :item="i">
                        <div class="text-right">
                            <button class="btn btn-sm btn-warning m-1" @click="showEdit(i)">
                                修改
                            </button>
                            <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                                删除
                            </button>
                        </div>
                    </LibImageCard>
                </template>
                <template v-else-if="i.isVideo">
                    <LibVideoCard :item="i">
                        <div class="text-right">
                            <button class="btn btn-sm btn-warning m-1" @click="showEdit(i)">
                                修改
                            </button>
                            <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                                删除
                            </button>
                        </div>
                    </LibVideoCard>
                </template>
                <template v-else-if="i.isAudio">
                    <LibAudioCard :item="i">
                        <div class="text-right">
                            <button class="btn btn-sm btn-warning m-1" @click="showEdit(i)">
                                修改
                            </button>
                            <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                                删除
                            </button>
                        </div>
                    </LibAudioCard>
                </template>
                <template v-else>
                    <LibAudioCard :item="i">
                        <div class="text-right">
                            <button class="btn btn-sm btn-warning m-1" @click="showEdit(i)">
                                修改
                            </button>
                            <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                                删除
                            </button>
                        </div>
                    </LibAudioCard>
                </template>

            </div>
        </div>
        <div class="card-footer p-2">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
        </div>
        <Modal ref="editModal">
            <template slot="header">
                <h4>素材编辑</h4>
            </template>

            <div v-if="item">
                <div class="form-group">
                    <label for="panoTitle">标题</label>
                    <input type="text" class="form-control" id="panoTitle" placeholder="输入标题" v-model.trim:value="item.name">
                </div>
                <div class="form-group">
                    <label for="panoTitle">公共标签</label>
                    <CheckBox checkprop="name"
                              :checkList="publicTags"
                              :selected="item.tags"
                              :onChange="tagsCheckOnChange">
                    </CheckBox>
                </div>
                <div class="form-group">
                    <label for="panoTitle">私有标签</label>
                    <CheckBox checkprop="name"
                              :checkList="privateTags"
                              :selected="item.tags"
                              :onChange="tagsCheckOnChange">
                    </CheckBox>
                </div>
            </div>
            <template slot="footer">
                <button type="button" class="btn btn-default" @click.prevent="hideEdit">取消</button>
                <button type="button" @click.prevent="saveEdit()" class="btn btn-primary">更新</button>
            </template>

        </Modal>
        <Modal ref="uploadModal">
            <template slot="header">
                <ul class="nav nav-pills">
                    <li class="nav-item"><a class="nav-link active" href="#image" id="btn_image" data-toggle="tab">图片</a></li>
                    <!--<li class="nav-item"><a class="nav-link" href="#pano" id="btn_pano" data-toggle="tab">全景</a></li>-->
                    <li class="nav-item"><a class="nav-link" href="#video" id="btn_video" data-toggle="tab">视频</a></li>
                    <li class="nav-item"><a class="nav-link" href="#audio" id="btn_audio" data-toggle="tab">音频</a></li>
                </ul>
            </template>
            <div class="tab-content">
                <!-- Font Awesome Icons -->
                <!--<div class="container tab-pane" id="pano">
                    <UploadBox action="/Api/InnAdmin/Panos/Upload"
                                 :param="param"
                                 :multiple="true"
                                 :maxSize="50"
                                 :maxWidth="22000"
                                 :maxHeight="11000"
                                 :isPano="true"
                                 :allowExtensions="/(.jpg|.jpeg)$/i"
                                 :partSizeMB="10"
                                 :surlheight="100"
                                 :surlwidth="200"
                                 :maxUpper="3"
                                 :uploadFinished="search"
                                 :changeName="changePanoName">
                    </UploadBox>
                </div>-->
                <div class="container tab-pane active" id="image">
                    <UploadBox action="/Api/InnAdmin/Libs/Upload"
                               :param="param"
                               :multiple="true"
                               :maxSize="2"
                               :maxWidth="2500"
                               :maxHeight="2500"
                               :compress="true"
                               :isPano="false"
                               :allowExtensions="/(.jpg|.jpeg|.png|.gif|.bmp|.tif|.tiff)$/i"
                               :partSizeMB="10"
                               :surlheight="100"
                               :surlwidth="200"
                               :maxUpper="3"
                               :uploadFinished="search"
                               :changeName="changeName">

                    </UploadBox>
                </div>
                <div class="container tab-pane" id="audio">
                    <UploadBox action="/Api/InnAdmin/Libs/Upload"
                               :param="param"
                               :multiple="true"
                               :maxSize="2"
                               :allowExtensions="/(.aac|.mp3)$/i"
                               :partSizeMB="10"
                               :maxUpper="3"
                               :uploadFinished="search"
                               previewIcon="fas fa-file-audio"
                               :changeName="changeName">
                    </UploadBox>
                </div>
                <div class="container tab-pane" id="video">
                    <UploadBox action="/Api/InnAdmin/Libs/Upload"
                               :param="param"
                               :multiple="true"
                               :maxSize="50"
                               :allowExtensions="/(.mp4)$/i"
                               :partSizeMB="10"
                               :maxUpper="3"
                               :uploadFinished="search"
                               previewIcon="fas fa-file-video"
                               :changeName="changeName">
                    </UploadBox>
                </div>
            </div>
            <template slot="footer">
            </template>
        </Modal>
        <Modal ref="delModal">
            <template slot="header">
                <h4 class="modal-title">删除</h4>
            </template>
            确定删除 {{(item||{}).title}} ?
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="del(item)" :disabled="updateing">确定</button>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { Modal, UploadBox, LibImageCard, LibVideoCard, LibAudioCard } from 'common'
    //import UploadBox from '@/components/UploadBox'
    //import Modal from '@/components/Modal'
    //import LibImageCard from '@/components/LibImageCard'
    //import LibVideoCard from '@/components/LibVideoCard'
    //import LibAudioCard from '@/components/LibAudioCard'
    import formatBytes from "common/src/libs/formatBytes.js"
    export default {
        components: {
            UploadBox,
            Modal,
            LibImageCard,
            LibVideoCard,
            LibAudioCard,
        },
        data() {
            return {
                list: [],
                tags: [],
                types: [{ name: '图片', value: 'image' }, { name: '视频', value: 'video' }, { name: '音频', value: 'audio' }],//{ name: '全景', value: 'pano' },
                pager: {
                    prePageSize: [12, 24, 48, 96],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 12,
                    sort: "AddTime",
                    order: "desc",
                    fields: 'Name,FileName',
                    key: null,
                    type: "image",
                    tags: ""
                },
                param: {
                    innId: this.$route.params.innId
                },
                updateing: false,
                item: null,
            }
        },
        created() {
            this.loadTags()
            this.loadData()
        },
        computed: {
        },
        methods: {
            loadData() {
                let self = this
                var url = "/Api/InnAdmin/Libs"
                var params = {
                    page: this.query.pageIndex,
                    pageSize: this.query.pageSize,
                    sort: this.query.sort,
                    order: this.query.order,
                    fields: this.query.fields,
                    key: this.query.key,
                    type: this.query.type,
                    tags: this.query.tags,
                    innId: this.$route.params.innId
                }

                this.$axios.get(url, { params: params })
                    .then(function (response) {
                        console.log(response)
                        self.list = response.data.data
                        self.pager.pageIndex = response.data.pageIndex
                        self.pager.pageSize = response.data.pageSize
                        self.pager.totalPages = response.data.totalPages
                        self.pager.totalRecords = response.data.totalRecords

                    }).catch(function (error) {
                        console.log(error)
                    })

            },
            loadTags() {
                let self = this
                this.$axios.get('/Api/InnAdmin/Tags/All')
                    .then(function (response) {
                        //console.log(response)
                        self.tags = response.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            loadItem(id) {
                let self = this
                this.$axios.get('/Api/InnAdmin/Files/' + id)
                    .then(function (response) {
                        //console.log(response)
                        let d = response.data
                        self.item.id = d.id
                        self.item.userId = d.userId
                        self.item.userName = d.userName
                        self.item.nickName = d.nickName
                        self.item.email = d.email
                        self.item.phoneNumber = d.phoneNumber
                        self.item.closed = d.closed
                        self.item.roles = d.roles
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            changeType(type) {
                $('#btn_' + type).tab('show')
                this.query.type = type;
                this.loadData();
            },
            search() {
                this.query.pageIndex = 1;
                this.loadData();
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            sortingChanged(ctx) {
                // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
                // ctx.sortDesc ==> true if sorting descending, false otherwise
                this.query.sort = ctx.sortBy;
                this.query.order = ctx.sortDesc ? "desc" : "asc";
                this.loadData();
            },
            resizeimg(url) {
                return {
                    'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
                    'background-size': 'cover',
                    'background-position': 'center',
                    'background-repeat': 'no-repeat',

                }
            },
            delDialog(item) {
                this.$refs.delModal.show()
                this.item = item
            },
            del(id) {
                let _this = this
                _this.updateing = true;
                this.$axios.delete('/Api/InnAdmin/Files/' + id)
                    .then(function (response) {
                        //console.log(response)
                        let d = response.data
                        _this.loadData()
                        _this.$message({
                            type: 'success',
                            message: '删除成功',
                            zIndex: 9999,
                            showClose: false
                        })
                        _this.updateing = false;
                    })
                    .catch(function (error) {
                        console.log(error)
                        _this.$message({
                            type: 'error',
                            message: error.message,
                            zIndex: 9999,
                            showClose: false
                        })
                        _this.updateing = false
                            ;
                    })
            },
            showUploadDialog() {
                this.$refs.uploadModal.show()

            },
            hideUploadDialog() {
                this.$refs.uploadModal.hide()
                this.fileList = []

            },
            changeName(item, res) {
                var _this = this;
                if (res) {
                    console.log(item, res)
                    var data = { ...res };
                    data.name = item.title;
                    console.log(data)
                    this.$axios.put('/api/InnAdmin/files/' + data.guid, data).then(function (res) {
                        if (res.status == 200) {
                            _this.search()
                        }
                    })
                }
            },
            showEdit(item) {
                var _this = this
                this.$refs.editModal.show()
                this.$axios.get('/Api/InnAdmin/Libs/' + item.guid).then(res => {
                    _this.item = res.data
                })
            },
            hideEdit() {
                this.$refs.editModal.hide()
                this.item = null
            },
            saveEdit() {
                var _this = this
                this.$refs.editModal.show()
                this.$axios.put('/Api/InnAdmin/Files/' + this.item.guid, this.item).then(res => {
                    this.$refs.editModal.hide()

                })
            },
            changePanoName(item, res) {
                var _this = this;
                if (res) {
                    console.log(item, res)
                    var data = { ...res };
                    data.title = item.title;
                    console.log(data)
                    this.$axios.put('/api/InnAdmin/panos/' + data.guid, data).then(function (res) {
                        if (res.status == 200) {
                            _this.search()
                        }
                    })
                }
            },
            formatBytes: formatBytes
        },

    }
</script>

<style lang="css">
</style>
