<template lang="html">
    <div>
        <div class="card card-primary card-outline">

            <div class="card-header p-1">
                <div class="card-title">
                    <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="CreateModal()" title="添加"><i class="fa fa-plus"></i></button>
                    <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="loadData()" :disabled="loading" title="刷新"><i class="fas fa-sync"></i></button>
                </div>
                <div class="card-tools mr-0">
                    <div class="input-group input-group-sm m-0">
                        <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary" v-on:click="search()">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- /.card-tools -->
            </div>
            <!-- /.box-header -->
            <div class="card-body p-1">
                <div class="loading">
                    <vue-bootstrap4-table :rows="list"
                                          :columns="columns"
                                          :config="config"
                                          :classes="classes"
                                          @on-change-query="onChangeQuery">
                        <template slot="sort-asc-icon">
                            <i class="fas fa-sort-up"></i>
                        </template>
                        <template slot="sort-desc-icon">
                            <i class="fas fa-sort-down"></i>
                        </template>
                        <template slot="no-sort-icon">
                            <i class="fas fa-sort"></i>
                        </template>
                        <template slot="empty-results">
                            没有数据
                        </template>
                        <template slot="active" slot-scope="props">
                            <button class="btn btn-default btn-xs mx-1" title="编辑" @click="UpdateModal(props.row.id)">
                                <i class="fas fa-pen-square"></i>
                            </button>
                            <button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
                                <i class="fas fa-trash"></i>
                            </button>
                            <button class="btn btn-default btn-xs mx-1" title="房间库存" @click="RoomStock(props.row.id)">
                                <i class="fas fa-calendar"></i>
                            </button>
                        </template>
                        <template slot="user" slot-scope="props">
                            <div :title="props.row.user.nickName">{{props.row.user.userName}}</div>
                        </template>
                        <template slot="hasWindow" slot-scope="props">
                            {{props.row.hasWindow ? "有" : "无"}}
                        </template>
                        <template slot="closed" slot-scope="props">
                            {{props.row.closed ? "是" : "否"}}
                        </template>
                    </vue-bootstrap4-table>
                    <div class="overlay" v-if="loading">
                        <i class="fa fa-refresh fa-spin"></i>
                    </div>
                </div>
            </div>
            <!-- Loading (remove the following to stop the loading)-->
            <!--<div class="overlay" v-if="this.$store.state.loading">
                <i class="fa fa-refresh fa-spin"></i>
            </div>-->
            <!-- end loading -->
            <div class="card-footer p-2">
                <paging :page-index="pager.pageIndex"
                        :total="pager.totalRecords"
                        :page-size="pager.pageSize"
                        :per-pages="3"
                        :show-go="true"
                        :prePageSize="pager.prePageSize"
                        :onPagerChange="pageChange">
                </paging>
            </div>
        </div>
        <Modal ref="delModal">
            <template slot="header">
                <h4 class="modal-title">删除房间</h4>
            </template>
            确定删除 {{(item||{}).title}} ?
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="Delete(item.id)" :disabled="updateing">确定</button>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { Modal } from 'common'
    //import Modal from '@/components/Modal.vue'
    //import PanoLib from '@/components/PanoLib.vue'
    //import bedSelect from '@/components/bedSelect.vue'
    //import CollapedBox from '@/components/CollapedBox.vue'
    //import CheckBox from '@/components/CheckBox.vue'

    export default {
        components: {
            //PanoLib,
            //bedSelect,
            //CollapedBox,
            //CheckBox
            Modal,
        },
        data() {
            return {
                id: this.$route.params.innId,
                list: [],
                columns: [
                    { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
                    { name: 'code', label: '编码', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap" },
                    { name: 'title', label: '名称', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'roomTypeName', label: '房型', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'roomSize', label: '大小', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'floor', label: '楼层', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'hasWindow', label: '窗户', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'addTime', label: '添加时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
                ],
                classes: {
                    tableWrapper: "table-responsive",
                    table: "table-bordered table-sm table-hover",
                    //row: {
                    //    "my-row my-row2": true,
                    //    "function-class": function (row) {
                    //        return row.id == 1
                    //    }
                    //},
                    //cell: {
                    //    "my-cell my-cell2": true,
                    //    "text-danger": function (row, column, cellValue) {
                    //        return column.name == "salary" && row.salary > 2500
                    //    }
                    //}
                },
                config: {
                    //checkbox_rows: true,
                    //rows_selectable: true,
                    card_mode: false,
                    pagination: false,
                    pagination_info: false,
                    global_search: {
                        visibility: false,
                    },
                    show_refresh_button: false,
                    show_reset_button: false,
                    multi_column_sort: false,
                    server_mode: true,
                },
                pager: {
                    prePageSize: [10, 20, 50, 100],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 20,
                    sort: "addTime",
                    order: 'desc',
                    fields: 'Title',
                    key: null,
                },
                item: null,
                loading: false,
                updateing: false,
            }
        },
        created() {
            this.loadData()
        },
        mounted() {
        },
        computed: {
        },
        methods: {
            loadData() {
                let _this = this;
                this.loading = true;
                var config = {
                    params: {
                        page: this.query.pageIndex,
                        pageSize: this.query.pageSize,
                        sort: this.query.sort,
                        order: this.query.order,
                        fields: this.query.fields,
                        key: this.query.key,
                        innId: this.$route.params.innId
                    }
                };
                this.$axios.get('/Api/InnAdmin/Rooms', config)
                    .then(function (response) {
                        _this.list = response.data.data
                        _this.pager.pageIndex = response.data.pageIndex
                        _this.pager.pageSize = response.data.pageSize
                        _this.pager.totalPages = response.data.totalPages
                        _this.pager.totalRecords = response.data.totalRecords
                        _this.loading = false
                    })
                    .catch(function (error) {
                        _this.loading = false
                        console.log(error)
                    })
            },
            search() {
                this.query.pageIndex = 1;
                this.loadData();
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            onChangeQuery(queryParams) {
                console.log(queryParams.sort[0])
                var sort = queryParams.sort[0]
                this.query.sort = sort.name
                this.query.order = sort.order
                this.loadData()
            },
            //getBeds() {
            //    var _this = this
            //    this.$axios.get('/Api/InnAdmin/Beds/All').then(function (res) {
            //        _this.beds = res.data;
            //    })
            //},
            UpdateModal(id) {
                this.$router.push({ name: 'InnRoomEdit', params: { roomId: id } })
                //var _this = this
                //this.$axios.get('/Api/InnAdmin/Rooms/' + id).then(function (res) {
                //  _this.form = res.data;
                //  for (var i in _this.form.beds) {
                //    _this.form.beds[i].id = _this.form.beds[i].bedId
                //  }
                //})
                //this.getBeds()
                //$('#dialog').modal('show')
            },
            CreateModal() {
                this.$router.push({ name: 'InnRoomCreate' })

                //var _this = this;
                //this.Reset();
                //this.getBeds()
                //$('#dialog').modal('show');
            },
            //Create() {
            //    let _this = this
            //    let d = this.form
            //    for (var i in d.beds) {
            //        d.beds[i].bedid = d.beds[i].id
            //    }
            //    delete d.id
            //    console.log(d)
            //    this.$axios.post('/Api/InnAdmin/Rooms', d)
            //        .then(function (response) {
            //            console.log(response)
            //            _this.loadData()
            //            $('#dialog').modal('hide')
            //            _this.Reset()
            //        })
            //        .catch(function (error) {
            //            console.log(error)
            //        })
            //},
            delDialog(item) {
                this.$refs.delModal.show()
                this.item = item
            },
            Delete(id) {
                let _this = this
                _this.updateing = true;
                this.$axios.delete('/Api/InnAdmin/Rooms/' + id)
                    .then(function (response) {
                        console.log(response)
                        _this.loadData()
                        _this.updateing = false;
                        _this.$refs.delModal.hide()
                    })
                    .catch(function (error) {
                        _this.updateing = false;
                        console.log(error)
                    })
            },
            //Update(id) {
            //    let _this = this
            //    let d = this.form

            //    for (var i in d.beds) {
            //        d.beds[i].bedId = d.beds[i].id
            //    }
            //    this.$axios.put('/Api/InnAdmin/Rooms/' + id, d)
            //        .then(function (response) {
            //            _this.loadData()
            //            $('#dialog').modal('hide')
            //            _this.Reset()
            //            console.log(response)
            //        })
            //        .catch(function (error) {
            //            console.log(error)
            //        })
            //},
            RoomStock(roomId) {
                console.log(roomId);
                this.$router.push({ name: 'RoomStockList', params: { roomId: roomId } })
            },
            //Reset() {
            //    this.form = this.newForm()
            //    this.form.innId = this.id;
            //},
            //newForm() {
            //    return {
            //        beds: [],
            //        code: "",
            //        description: "",
            //        id: "",
            //        imageId: null,
            //        innId: "",
            //        panoId: null,
            //        panoUrl: null,
            //        point: 0,
            //        roomType: 0,
            //        title: "",
            //        facilitys: []
            //    }
            //},
            //onSelected(select) {
            //    console.log(select)
            //    this.material = select
            //    this.form.panoUrl = select.firstImage.url
            //    this.form.PanoId = select.guid
            //},
            //checkOnChange1(list) {
            //    console.log(list)
            //    this.form.facilitys = [...list]
            //},
            //onBed(beds) {
            //    var list = [...beds];
            //    this.form.beds = list;
            //},
            //showPanoLibDialog() {
            //    this.$refs.PanoLib.showDialog();

            //}
        },
    }
</script>
