<template lang="html">
    <div class="card card-primary card-outline">
        <!--<div class="overlay" v-if="$store.state.loading">
          <i class="fa fa-refresh fa-spin"></i>
        </div>-->
        <div class="card-header p-1">
            <h5>房间库存</h5>
        </div>
        <!-- /.box-header -->
        <div class="card-body p-1">
            <div class="mailbox-controls">
                <!-- Check all button -->
                <!--<div class="btn-group">
                    <button type="button" class="btn btn-default btn-sm mx-1" v-on:click="create()"><i class="fa fa-plus"></i></button>
                </div>-->
                <button type="button" class="btn btn-default btn-sm" v-on:click="loadData()"><i class="fas fa-sync"></i></button>
            </div>
            <Calendar :selectedDays="selectedDays" :onChangeSelected="selectDays" :onCurrentChange="currentChange">
                <template slot="daymsg" slot-scope="slotProps">
                    <template v-if="findDayInList(roomSocketData,slotProps.day.day)">

                        <span v-if="findDayInList(roomSocketData,slotProps.day.day).type==1" style="color:#ff0000">
                            价格：{{findDayInList(roomSocketData,slotProps.day.day).price}}
                        </span>
                        <span v-if="findDayInList(roomSocketData,slotProps.day.day).type==0" style="color:#00ff00">
                            点数：{{findDayInList(roomSocketData,slotProps.day.day).point}}
                        </span>
                    </template>
                    <span v-else>未设置</span>
                </template>
            </Calendar>
            <button class="btn btn-primary mb-1" @click="openDaysModal" :disabled="!(this.selectedDays && this.selectedDays.length > 0)">添加</button>
            <vue-bootstrap4-table :rows="roomSocketData"
                                  :columns="columns"
                                  :config="config"
                                  :classes="classes"
                                  @on-change-query="onChangeQuery">
                <template slot="sort-asc-icon">
                    <i class="fas fa-sort-up"></i>
                </template>
                <template slot="sort-desc-icon">
                    <i class="fas fa-sort-down"></i>
                </template>
                <template slot="no-sort-icon">
                    <i class="fas fa-sort"></i>
                </template>
                <template slot="empty-results">
                    没有数据
                </template>
                <template slot="active" slot-scope="props">
                    <div class="text-nowrap">
                        <button class="btn btn-default btn-xs mx-1" title="编辑" @click="UpdateModal(props.row)">
                            <i class="fas fa-pen-square"></i>
                        </button>
                        <button class="btn btn-default btn-xs mx-1" title="删除" @click="Delete(props.row)">
                            <i class="fas fa-trash"></i>
                        </button>
                    </div>
                </template>
                <template slot="closed" slot-scope="props">
                    {{props.row.closed ? "是" : "否"}}
                </template>
            </vue-bootstrap4-table>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="this.$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <!-- alert add -->
        <Modal ref="dialog">
            <template slot="header">
                <h4 class="modal-title">添加</h4>
            </template>
            <div id='example-3'>
                <form @submit.prevent>
                    <div class="form-group">
                        <label for="Date">日期</label>
                        <input type="date" class="form-control" id="Date" v-model.trim="form.date" placeholder="请输入有效期">
                        <input type="hidden" v-model.trim="form.id">
                        <input type="text" v-model.trim="form.roomId">
                    </div>

                    <!--<div class="form-group">
                      <label for="Booking">预定状态</label>
                      <input type="text" class="form-control" id="Booking" v-model.trim="form.booking" placeholder="请输入房间点数">
                    </div>
                    <div class="form-group">
                      <label for="Booking">是否核销</label>
                      <input type="text" class="form-control" id="Booking" v-model.trim="form.booking" placeholder="请输入房间点数">
                    </div>
                    <div class="form-group">
                      <label for="Point">点数</label>
                      <input type="text" class="form-control" id="Point" v-model.trim="form.point" placeholder="请输入房间点数">
                    </div>-->
                </form>
            </div>

            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal" @click="$refs.dialog.hide()">取消</button>
                <template v-if="form.id==null||form.id.length==0">
                    <button type="button" @click.prevent="Create" class="btn btn-primary">添加</button>
                </template>
                <template v-else>
                    <button type="button" @click.prevent="Update(form.id)" class="btn btn-primary" :disabled="updateing">更新</button>
                </template>
            </template>
        </Modal>

        <Modal ref="adddays">
            <template v-slot:header>
                <h4 class="modal-title">添加库存</h4>
            </template>
            <ul>
                <li v-for="item in selectedDays">{{$moment(item.day).format('YYYY-MM-DD')}}</li>
            </ul>
            <div v-if="$inRole('管理员')" class="form-group">
                <label>库存类型</label>
                <div>
                    <label class="radio-inline">
                        <input type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1" v-model="submitDaysForm.type">价格
                    </label>
                    <label class="radio-inline">
                        <input type="radio" name="inlineRadioOptions" id="inlineRadio2" value="0" v-model="submitDaysForm.type">点数
                    </label>
                </div>
            </div>

            <div class="form-group" v-if="submitDaysForm.type==1">
                <label>价格</label>
                <input class="form-control" type="number" v-model.trim="submitDaysForm.price" step="1" />
            </div>
            <div class="form-group" v-if="submitDaysForm.type==0">
                <label>点数</label>
                <input class="form-control" type="number" v-model.trim="submitDaysForm.point" step="0.01" />
            </div>


            <template v-slot:footer>
                <button type="button" class="btn btn-default" @click="$refs.adddays.hide()">关闭</button>
                <button type="button" class="btn btn-primary" @click="DaysModalSubmit" :disabled="updateing">保存</button>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'
    import { Calendar, Modal } from 'common'
    //import Calendar from '@/components/Calendar.vue'
    //import Modal from '@/components/Modal.vue'

    export default {
        data() {
            return {
                id: this.$route.params.roomId,
                form: {
                    id: '',
                    date: '111',
                    //description: '',
                    //roomType: 1,
                    //point: 1,
                    //code: '222',
                    roomId: this.$route.params.roomId
                },
                columns: [
                    { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
                    { name: 'date', label: '日期', sort: true, initial_sort: true, initial_sort_order: 'desc', uniqueId: true, column_classes: "text-nowrap" },
                    { name: 'booking', label: '预定状态', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap" },
                    { name: 'point', label: '点数', column_classes: "text-nowrap", row_classes: "text-nowrap" },
                    { name: 'price', label: '价格', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left", },
                    { name: 'user', label: '消费者', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap", row_classes: "text-nowrap" },
                    { name: 'lastEdit', label: '最后编辑', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap" },
                    { name: 'addTime', label: '注册时间', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap" },
                    { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap c2" },
                ],
                classes: {
                    tableWrapper: "table-responsive",
                    table: "table-bordered table-sm table-hover",
                    //row: {
                    //    "my-row my-row2": true,
                    //    "function-class": function (row) {
                    //        return row.id == 1
                    //    }
                    //},
                    //cell: {
                    //    "my-cell my-cell2": true,
                    //    "text-danger": function (row, column, cellValue) {
                    //        return column.name == "salary" && row.salary > 2500
                    //    }
                    //}
                },
                config: {
                    //checkbox_rows: true,
                    //rows_selectable: true,
                    card_mode: false,
                    pagination: false,
                    pagination_info: false,
                    global_search: {
                        visibility: false,
                    },
                    show_refresh_button: false,
                    show_reset_button: false,
                    multi_column_sort: false,
                    server_mode: true,
                },
                fields: [
                    { key: 'more', label: '操作' },
                    { key: 'date', label: '日期' },
                    { key: 'booking', label: '预定状态' },
                    { key: 'point', label: '消费点数' },
                    { key: 'price', label: '价格' },
                    { key: 'userId', label: '消费者Id' },
                    { key: 'lastEdit', label: '最后编辑' },
                    { key: 'addTime', label: '添加时间', sortable: true }
                ],
                roomSocketData: [],
                selectedDays: [],
                submitDaysForm: {
                    price: 0,
                    point: 0,
                    type: 1
                },
                updateing: false,
            }
        },
        created() {
            this.loadData()
        },
        computed: {
        },
        methods: {
            ...mapActions([
                'setPageInfo',
            ]),
            loadData() {
                var d = new Date()
                var c = { currentYear: d.getFullYear(), currentMonth: d.getMonth() + 1 }
                this.currentChange(c)
            },
            onChangeQuery(queryParams) {
                console.log(queryParams.sort[0])
                var sort = queryParams.sort[0]
                this.query.sort = sort.name
                this.query.order = sort.order
                this.loadData()
            },
            UpdateModal(item) {
                console.info(item)
                this.form.roomid = item.roomid
                this.form.date = item.date
                this.$refs.dialog.show()
            },
            CreateModal() {
                this.Reset()
                this.$refs.dialog.show()
            },
            Create() {
                let _this = this
                let d = this.form
                delete d.id
                this.$axios.post('/Api/InnAdmin/roomstocks', [d])
                    .then(function (response) {
                        console.log(response)
                        _this.loadData()
                        _this.$refs.dialog.hide()
                        _this.Reset()
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            Delete(item) {
                let _this = this
                this.$axios.delete('/Api/InnAdmin/roomstocks/' + this.id, { params: { date: item.date } })
                    .then(function (response) {
                        _this.loadData()

                    })
                    .catch(function (error) {
                        console.log(error.data)
                    })
            },
            Update(id) {
                let _this = this
                _this.updateing = true;
                this.$axios.put('/Api/Admin/roomstocks/' + id, this.form)
                    .then(function (response) {
                        _this.loadData()
                        _this.$refs.dialog.hide()
                        _this.Reset()
                        console.log(response)
                        _this.updateing = false;
                    })
                    .catch(function (error) {
                        console.log(error)
                        _this.updateing = false;
                    })
            },
            Reset() {
                this.form.id = ''
                this.form.date = ''
                //this.form.description = ''
                //this.form.roomType = 0
                //this.form.point = 0
                //this.form.code = ''
            },
            selectDays(list) {
                var tl = [...list]
                for (var i = tl.length - 1; i >= 0; i--) {
                    var item = this.findDayInList(this.roomSocketData, tl[i].day)
                    if (item) {
                        console.log(item)
                        tl.splice(i, 1);
                    }
                }
                this.selectedDays = tl;
            },
            openDaysModal() {
                if (this.selectedDays && this.selectedDays.length > 0) {
                    this.$refs.adddays.show()
                }
            },
            DaysModalSubmit() {
                this.submitDays()
                //this.$bus.$emit('adddays-modal-hide', {});
                this.$refs.adddays.hide()
            },
            submitDays() {
                var _this = this
                _this.updateing = true;
                var Days = [...this.selectedDays]
                var Data = []
                for (var i in Days) {
                    var item = {};
                    //item.date = this.formatDate(Days[i].day);
                    item.date = this.$moment(Days[i].day).format('YYYY-MM-DD');
                    item.roomId = this.id;
                    item.price = this.submitDaysForm.price
                    item.point = this.submitDaysForm.point
                    item.type = this.submitDaysForm.type
                    Data.push(item);
                }
                this.$axios.post('/Api/InnAdmin/roomstocks', Data)
                    .then(function (response) {
                        console.log(response)
                        _this.loadData()
                        _this.selectedDays = [];
                        _this.updateing = false;
                    })
                    .catch(function (error) {
                        let errstr = JSON.stringify(error)
                        let errjson = JSON.parse(errstr)
                        _this.$message({
                            message: errjson.response.data.date + "已存在",
                            type: 'error',
                            zIndex: 9999,
                            //showClose: true
                        })

                        console.log(errjson.response.data.date)
                        _this.updateing = false;
                    })
            },
            formatDate: function (fd) {
                var day = new Date(fd);
                var y = day.getFullYear();
                var m = day.getMonth();
                var d = day.getDate();
                if (m < 10) m = "0" + m;
                if (d < 10) d = "0" + d;
                return y + "-" + m + "-" + d
            },
            isThisDay(day1, day2) {
                var zday1 = new Date(day1);
                var zday2 = new Date(day2);
                return zday1.getFullYear() == zday2.getFullYear() && zday1.getMonth() == zday2.getMonth() && zday1.getDate() == zday2.getDate();
            },
            findDayInList(list, day) {
                var _this = this
                var daylist = [...list]
                var fl = daylist.filter(i => _this.isThisDay(i['date'], day));
                if (fl.length > 0) {
                    return fl[0];
                }
                else return null;
            },
            currentChange(current) {
                var _this = this
                var startDay = new Date(current.currentYear, current.currentMonth - 1, 1)
                startDay.setDate(startDay.getDate() - (startDay.getDay() == 0 ? 6 : startDay.getDay() - 1));
                //startDay = new Date(startDay.getTime() - 24 * 3600 * 1000 * (current.currentWeek - 1))
                var endday = new Date(startDay)
                endday.setDate(startDay.getDate() + 35)
                console.log(startDay, endday)
                this.$axios.get('/Api/InnAdmin/roomstocks', {
                    params: {
                        roomId: this.id,
                        page: 1,
                        pageSize: 35,
                        beginDate: this.$moment(startDay).format('YYYY-MM-DD'),
                        endDate: this.$moment(endday).format('YYYY-MM-DD'),
                    }
                })
                    .then(function (response) {
                        _this.roomSocketData = response.data.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })

            }
        },
        components: {
            Calendar,
            Modal
        }
    }
</script>
