<template lang="html">
    <div class="card card-primary card-outline PanoList">
        <div class="overlay" v-if="$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <div class="card-header p-2">
            <h3 class="card-title">全景管理</h3>
            <div class="card-tools mr-0">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" v-on:click="search()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-1">
            <div class="mailbox-controls p-0">
                <button class="btn btn-sm btn-default mx-1" title="添加" @click="Create()"><i class="fa fa-plus"></i></button>
                <button class="btn btn-sm btn-default mx-1" title="刷新" @click="loadData()"><i class="fas fa-sync"></i></button>
                <div class="float-right">
                    <div class="dropdown">
                        <button class="btn btn-sm btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            筛选
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            <form class="dropdown-item p-1">
                                <div class="input-group input-group-sm ">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" style="font-weight:700">标签</span>
                                    </div>
                                    <select class="form-control form-control-sm" v-model:value="query.type" v-on:change="search()" placeholder="标签">
                                        <option value="">所有</option>
                                        <option v-for="i in tags" v-bind:value="i">{{i.name}}</option>
                                    </select>
                                </div>
                            </form>
                            <form class="dropdown-item p-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchPrivate" v-model="query.private" v-on:change="search()">
                                    <label class="custom-control-label" for="customSwitchPrivate">私有</label>
                                </div>
                            </form>
                            <form class="dropdown-item p-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchFine" v-model="query.fine" v-on:change="search()">
                                    <label class="custom-control-label" for="customSwitchFine">精华</label>
                                </div>
                            </form>
                            <form class="dropdown-item p-1">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="customSwitchFeatured" v-model="query.featured" v-on:change="search()">
                                    <label class="custom-control-label" for="customSwitchFeatured">精选</label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <!-- /.pull-right -->
                <ul class="nav nav-tabs nav-nowrap my-1">
                    <li class="nav-item">
                        <a href="javascript:" class="nav-link" :class="{'active':query.state==null}" @click="query.state=null;search()">
                            全部
                        </a>
                    </li>
                    <li v-for="i in status" class="nav-item">
                        <a href="javascript:" class="nav-link" :class="{'active':query.state==i.value}" @click="query.state=i.value;search()">
                            {{i.name}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" v-for="i in list">
                    <div class="card card-widget widget-user-2">
                        <!-- Add the bg color to the header using any of the bg-* classes -->
                        <div class="widget-user-header bg-yellow" style="height: 150px;" v-bind:style="resizeimg(i.firstImage?i.firstImage.url:'')" @click="$router.push({ name: 'PanoFullScreen', params: { panoId: i.guid }})">
                            <!-- /.widget-user-image -->
                            <h4 class="">{{i.title}}</h4>
                            <h5 class="">{{i.user.nickName}}</h5>
                            <h6 class="widget-info px-2">
                                <span title="观看"><i class="fas fa-eye"></i> {{i.viewCount}}</span>
                                <span title="点赞"><i class="fas fa-thumbs-up"></i> {{i.praiseCount}}</span>
                                <span><i class="fas fa-map-marker-alt" title="地图坐标" v-if="i.gpsLat!=0 || i.gpsLng!=0"></i></span>
                                <span>{{i.status}}</span>
                                <span v-if="i.private">私有</span>
                                <span v-if="i.fine" class="text-warning">精</span>
                                <span v-if="i.featured"><i class="fas fa-star"></i></span>
                                <span>{{i.checkNote}}</span>
                                <time class="float-right" :title="i.addTime">{{$moment(i.addTime).fromNow()}}</time>
                            </h6>
                        </div>
                        <div class="card-footer p-0">
                            <ul class="nav nav-stacked">
                                <li class="">
                                    <button class="btn btn-sm btn-default m-1">
                                        <i class="fa fa-qrcode"></i>
                                    </button>
                                    <button class="btn btn-sm btn-warning m-1" @click="showDialog(i)">
                                        加星
                                    </button>
                                    <button class="btn btn-sm btn-warning m-1" @click="$router.push({ name: 'PanoEdit', params: { panoId: i.guid }})">
                                        修改
                                    </button>
                                    <button class="btn btn-sm btn-danger m-1" @click="delDialog(i)">
                                        删除
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer p-2">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
        </div>
        <Modal ref="myModal">
            <template slot="header">
                <h4 class="modal-title" id="myModalLabel">作品审核</h4>
            </template>
            <h2 v-text="item.title"></h2>
            <div id='example-3'>
                <input type="checkbox" id="jack" value="Jack" v-model="checkedNames">
                <label for="jack">标题不合规范</label>
                <br>
                <input type="checkbox" id="john" value="John" v-model="checkedNames">
                <label for="john">内容不合规范</label>
                <br>
                <input type="checkbox" id="mike" value="Mike" v-model="checkedNames">
                <label for="mike">全景图不合规范</label>
                <br>
                <span>Checked names: {{ checkedNames }}</span>
            </div>
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary">屏蔽</button>
                <button type="button" class="btn btn-primary">不收录</button>
                <button type="button" class="btn btn-primary">收录</button>
            </template>
        </Modal>
        <Modal ref="uploadModal">
            <template slot="header">
                <h4 class="modal-title" id="uploadModalLabel">上传全景</h4>
            </template>
            <UploadBox action="/Api/InnAdmin/Panos/Upload"
                       :param="param"
                       :multiple="true"
                       :maxSize="50"
                       :maxWidth="22000"
                       :maxHeight="11000"
                       :isPano="true"
                       :allowExtensions="/(.jpg|.jpeg)$/i"
                       :partSizeMB="10"
                       :surlheight="100"
                       :surlwidth="200"
                       :maxUpper="3"
                       :uploadFinished="search"
                       :changeName="changePanoName">
            </UploadBox>
            <template slot="footer">
            </template>
        </Modal>

        <Modal ref="delModal">
            <template slot="header">
                <h4 class="modal-title">删除全景</h4>
            </template>
            确定删除 {{(item||{}).title}} ?
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="del(item)" :disabled="updateing">确定</button>
            </template>
        </Modal>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'
    import { Modal, UploadBox } from 'common'
    //import Modal from '@/components/Modal.vue'
    //import UploadBox from '@/components/UploadBox'

    export default {
        components: {
            UploadBox,
            Modal,
        },
        data() {
            return {
                item: {},
                checkedNames: [],
                tags: [],
                status: [
                    { name: '待切片', value: 0 },
                    { name: '切片中', value: 1 },
                    { name: '待收录', value: 2 },
                    { name: '已收录', value: 3 },
                ],
                pager: {
                    prePageSize: [12, 24, 48, 96],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 12,
                    sort: "Id",
                    order: "desc",
                    fields: 'Title',
                    key: null,
                    innId: this.$route.params.innId,
                    private: null,
                    fine: null,
                    featured: null,
                    closed: null,
                },
                files: [],
                progress: '0%',
                showProgress: false,
                showProgressErro: false,
                ProgressErro: '',
                fileList: [],
                param: { innId: this.$route.params.innId },
                list: [],
                updateing: false,
            }
        },
        created() {
            this.loadData()
            //this.$store.state.pageinfo.main.title = "用户管理"
            //this.setPageInfo("用户管理")

        },
        computed: {
            //list() {
            //    this.pager.pageIndex = this.$store.state.panos.main.pageIndex
            //    this.pager.pageSize = this.$store.state.panos.main.pageSize
            //    this.pager.totalPages = this.$store.state.panos.main.totalPages
            //    this.pager.totalRecords = this.$store.state.panos.main.totalRecords
            //    return this.$store.state.panos.main
            //}
        },
        methods: {
            loadData() {
                var _this = this;
                this.$axios.get("/Api/InnAdmin/Panos", {
                    params: {
                        page: this.query.pageIndex,
                        pageSize: this.query.pageSize,
                        sort: this.query.sort,
                        order: this.query.order,
                        fields: this.query.fields,
                        key: this.query.key,
                        innId: this.query.innId,
                        state: this.query.state,
                        private: this.query.private ? true : null,
                        fine: this.query.fine ? true : null,
                        featured: this.query.featured ? true : null,
                    }
                }).then((res) => {
                    _this.pager.pageIndex = res.data.pageIndex;
                    _this.pager.pageSize = res.data.pageSize;
                    _this.pager.totalPages = res.data.totalPages;
                    _this.pager.totalRecords = res.data.totalRecords;
                    _this.list = res.data.data;
                })
            },
            search() {
                this.query.pageIndex = 1;
                this.loadData()
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            sortingChanged(ctx) {
                // ctx.sortBy   ==> Field key for sorting by (or null for no sorting)
                // ctx.sortDesc ==> true if sorting descending, false otherwise
                this.query.sort = ctx.sortBy;
                this.query.order = ctx.sortDesc ? "desc" : "asc";
                this.loadData();
            },
            resizeimg(url) {
                return {
                    'background-image': 'url(' + url.replace('/0/0/0/0/', '/600/0/0/0/') + ')',
                    'background-size': 'cover',
                    'background-position': 'center',
                    'background-repeat': 'no-repeat',

                }
            },
            showDialog(item) {
                this.$refs.myModal.show()
                this.item = item
            },
            Create() {
                this.$refs.uploadModal.show()
            },
            delDialog(item) {
                this.$refs.delModal.show()
                this.item = item
            },
            del(item) {
                let _this = this
                _this.updateing = true;
                var url = "/Api/InnAdmin/Panos/" + item.guid
                this.$axios.delete(url)
                    .then(function (response) {
                        console.log('#', response)
                        _this.loadData()
                        _this.$refs.delModal.hide()
                        _this.updateing = false;
                    }).catch(function (error) {
                        console.log(error)
                        _this.updateing = false;
                    })
            },
            addFile(event) {
                this.files = event.target.files[0];
                console.log(this.files);
            },
            //upload: function () {
            //    var _this = this
            //    _this.showProgress = true
            //    _this.showProgressErro = false
            //    var url = "/Api/InnAdmin/Files/UploadPano"
            //    var files = _this.files
            //    console.log(files)
            //    var data = new FormData()
            //    //data.append("articleGUID", articleGUID); //文章 Id
            //    data.append("files", files)

            //    var config = {
            //        onUploadProgress: progressEvent => {
            //            var complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
            //            _this.progress = complete
            //            console.info('#', complete)
            //            console.info('#', progressEvent)
            //            console.info('#', progressEvent.loaded)
            //        }
            //    }
            //    this.$axios.post(url, data, config)
            //        .then(function (response) {
            //            console.log('#', response)
            //            _this.showProgressErro = true
            //            if (response.data.status === 'success') {
            //                _this.showProgress = false
            //                _this.ProgressErro = '上传成功'
            //                _this.ProgressErro = '<img style="max-width:200px;max-height:200px" src=http://localhost:5001' + response.data.url + ' />'
            //            } else {
            //                _this.ProgressErro = response.data
            //            }
            //        }).catch(function (error) {
            //            console.log(error)
            //        })
            //},
            changePanoName(item, res) {
                var _this = this;
                if (res) {
                    console.log(item, res)
                    var data = { ...res };
                    data.title = item.title;
                    console.log(data)
                    this.$axios.put('/Api/InnAdmin/Panos/' + data.guid, data).then(function (res) {
                        if (res.status == 200) {
                            _this.search()
                        }
                    })
                }
            },
        },
        //beforeRouteLeave(to, from, next) {
        //  // 导航离开该组件的对应路由时调用
        //  // 可以访问组件实例 `this`
        //  $('.modal').modal('hide')
        //  next()
        //}
    }
</script>

<style lang="css" scoped>
    .PanoList .card-widget .widget-user-header h4 {
        color: #fff;
        text-shadow: 1px 1px 1px #000;
        font-size: 1rem;
    }

    .PanoList .card-widget .widget-user-header h5 {
        color: #fff;
        text-shadow: 1px 1px 1px #000;
        font-size: 0.8rem;
    }

    .PanoList .card-widget .widget-user-header h6 {
        color: #fff;
        text-shadow: 1px 1px 1px #000;
        font-size: 0.7rem;
    }

    .PanoList .card-widget .widget-user-header {
        position: relative;
    }

        .PanoList .card-widget .widget-user-header .widget-info {
            position: absolute;
            bottom: 0;
            left:0;
            right:0;
        }

            .PanoList .card-widget .widget-user-header .widget-info span {
                padding: 0 0.2rem;
            }
</style>
