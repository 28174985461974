<template lang="html">
    <div class="card card-primary card-outline">
        <div class="card-header p-1">
            <h3 class="card-title">新建通知</h3>         
        </div>
        <!-- /.box-header -->
        <div class="card-body p-1">
            <form class="form-horizontal">
                <div class="form-group">
                    <label for="editName" class="col-sm-2 control-label">标题</label>
                    <div class="col-sm-12">
                        <input type="text" class="form-control" id="editTitle" placeholder="标题" v-model.trim="item.title">
                    </div>
                </div>
                <div class="form-group">
                    <label for="editName" class="col-sm-2 control-label">内容</label>
                    <div class="col-sm-12">
                        <editor v-model="item.text"
                                :toolbarItems="['bold','italic','underline','fontSize','bulletedList', 'numberedList','link','|','undo','redo']"
                                placeholder="在这里输入通知内容!" />
                    </div>
                </div>
            </form>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="this.$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <Footer>
            <button type="button" class="btn btn-default mr-2" @click="close()">取消</button>
            <button type="button" class="btn btn-primary" @click="save()" :disabled="updateing">保存</button>
        </Footer>
    </div>
</template>

<script>
    import { Footer, Modal } from 'common'
    import Editor from '@/components/Editor.vue'
    //import Footer from '@/components/Footer.vue'
    //import Modal from '@/components/Modal'
    export default {
        components: {
            Modal,
            Editor,
            Footer
        },
        data() {
            return {
                list: [],
                item: {
                    guid: null,
                    title: '',
                    text: '',
                    closed: false,
                    type: 0,
                },
                fields: [
                    { key: 'active', label: '操作' },
                    { key: 'type', label: '类型', sortable: true, sortDirection: 'desc' },
                    { key: 'title', label: '标题', sortable: true, sortDirection: 'asc' },
                    { key: 'addTime', label: '添加时间', sortable: true, sortDirection: 'asc' },
                    { key: 'user', label: '用户' },
                ],
                pager: {
                    prePageSize: [10, 20, 50, 100],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 20,
                    sort: "Id",
                    order: "desc",
                    fields: 'Name',
                    key: null,
                    roles: "",
                    type: ''
                },
                types: [],
                updateing: false,
            }
        },
        created() {
            this.reset()
        },
        methods: {
            loadItem(id) {
                let self = this
                this.$axios.get('/Api/Admin/Notices/' + id)
                    .then(function (response) {
                        //console.log(response)
                        self.item = response.data
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            reset() {
                let self = this
                self.item.guid = null
                self.item.name = null
                if (self.query.type && self.query.type != '') {
                    self.item.type = self.query.type.key
                } else {
                    self.item.type = 0
                }
                self.item.closed = false
            },
            create() {
                //this.$refs.Edit.show()
                this.reset()
            },
            save() {
                let self = this
                if (self.item.guid != null) {
                    this.$axios.put('/Api/Admin/Notices/' + self.item.guid, self.item)
                        .then(function (response) {
                            self.reset()
                            //self.$refs.Edit.hide()
                            self.close()
                            self.loadData()
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                } else {
                    this.$axios.post('/Api/Admin/Notices', self.item)
                        .then(function (response) {
                            self.reset()
                            //self.$refs.Edit.hide()
                            self.close()
                            self.loadData()
                        })
                        .catch(function (error) {
                            console.log(error)
                        })
                }
            },
            close() {
                this.$router.push({ name: 'NoticeList' });
            }
        },
    }
</script>

<style lang="css">
</style>
