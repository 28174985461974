<template lang="html">
    <div>
        <div>
            <button @click="showModal1">show</button>
        </div>
        <button @click="get">get</button>
        <button @click="post">post</button>
        <button @click="put">put</button>
        <button @click="adelete">delete</button>
        <br />
        地址：<input v-model="data.url" /><br />
        config:<br />
        <!--<textarea v-model="data.config"></textarea><br />-->
        sendData:<textarea v-model="data.sendData"></textarea><br />
        resData:<ObjTree :object="resData"></ObjTree><br />
        error:{{errmsg}}
        <Modal ref="Modal1">
            <div style="height:800px;background-color:aqua"></div>
            <button @click="showPanoLib">PanoLib</button>
            <button @click="showSimpleModal">show</button>
            <template slot="footer">
                <button @click="hideModal1">hideModal1</button>
            </template>
        </Modal>
        <Modal ref="SimpleModal">
            <div style="height:400px;background-color:aqua"></div>
            <button @click="showModal3">show</button>
            <template slot="footer">
                <button @click="hideSimpleModal">hideSimpleModal</button>
            </template>
        </Modal>
        <Modal ref="Modal3">
            <div style="height:200px;background-color:aqua"></div>

            <template slot="footer">
                <button @click="hideModal3">hideModal3</button>
            </template>
        </Modal>
        <PanoLib ref="PanoLib"></PanoLib>
        <CollapedBox collapseIcon maximizeIcon removeIcon>
            <div slot="header">
                1
            </div>
            <CollapedBox collapseIcon maximizeIcon removeIcon>
                <div slot="header">
                    2
                </div>
                <CollapedBox collapseIcon maximizeIcon removeIcon>
                    <div slot="header">
                        3
                    </div>
                </CollapedBox>
            </CollapedBox>
        </CollapedBox>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'
    import axios from 'axios'
    import {
        ObjTree,
        Modal,
        PanoLib,
        CollapedBox
    } from 'common'
    //import ObjTree from '@/components/ObjTree.vue'
    //import Modal from '@/components/Modal.vue'
    //import PanoLib from '@/components/PanoLib.vue'
    //import CollapedBox from '@/components/CollapedBox.vue'
    export default {
        components: {
            ObjTree,
            Modal,
            PanoLib,
            CollapedBox
        },
        data() {
            return {
                data: {
                    url: '/api',
                    config: {},
                    sendData: '{}',
                    resData: '{}',
                },
                errmsg: null,
                resData: null,
            }
        },
        created() {
            this.readthis()
        },
        computed: {

        },
        methods: {
            showModal1() {
                this.$refs.Modal1.show({})
            },
            showSimpleModal() {
                this.$refs.SimpleModal.show({})
            },
            showModal3() {
                this.$refs.Modal3.show({})
            },
            hideModal1() {
                this.$refs.Modal1.hide({})
            },
            hideSimpleModal() {
                this.$refs.SimpleModal.hide({})
            },
            hideModal3() {
                this.$refs.Modal3.hide({})
            },
            showPanoLib() {
                this.$refs.PanoLib.showDialog()
            },
            get() {
                var _this = this;
                axios.get(this.data.url, this.data.config).then(res => {
                    _this.resData = res.data
                }).catch(err => {
                    _this.errmsg = err
                })
                this.savethis()
            },
            post() {
                var _this = this;
                axios.post(this.data.url, JSON.parse(this.data.sendData), this.data.config).then(res => {
                    _this.resData = res.data
                }).catch(err => {
                    _this.errmsg = err
                })
                this.savethis()
            },
            put() {
                var _this = this;
                axios.put(this.data.url, JSON.parse(this.data.sendData), this.data.config).then(res => {
                    _this.resData = res.data
                }).catch(err => {
                    _this.errmsg = err
                })

                this.savethis()
            },
            adelete() {
                var _this = this;
                axios.delete(this.data.url, JSON.parse(this.data.sendData), this.data.config).then(res => {
                    _this.resData = res.data
                }).catch(err => {
                    _this.errmsg = err
                })
                this.savethis()
            },
            savethis() {
                window.localStorage.axiostest = JSON.stringify(this.data);
            },
            readthis() {
                if (window.localStorage && window.localStorage.axiostest) {
                    this.data = JSON.parse(window.localStorage.axiostest)
                    if (!this.data) {
                        this.data = {
                            url: '',
                            config: '',
                            sendData: '',
                            resData: '',
                        }
                    }
                }
            },
        },
        
    }
</script>

<style lang="css">
</style>
