<template lang="html">
    <div class="card card-primary card-outline">
        <!--<div class="overlay" v-if="$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>-->
        <div class="card-header p-1">
            <h3 class="card-title">房间编辑</h3>
        </div>
        <div class="card-body">
            <div>
                <form @submit.prevent>
                    <div class="form-group">
                        <label for="title">房间名称</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$error }" id="title" v-model.trim="$v.form.title.$model" placeholder="请输入房间名称">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.title.required">请输入房间名称</span>
                            <span v-if="!$v.form.title.minLength">房间名称至少 {{$v.form.title.$params.minLength.min}} 个字符</span>
                            <span v-if="!$v.form.title.maxLength">房间名称最多 {{$v.form.title.$params.maxLength.max}} 个字符</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Description">描述</label>
                        <!--<textarea class="form-control" id="Description" v-model.trim="form.description" placeholder="请输入房间描述"></textarea>-->
                        <PellEditor v-model.trim="form.description"
                                    :options="options"
                                    :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
                    </div>
                    <div class="form-group">
                        <label for="RoomSize">房间面积</label>
                        <input type="number" class="form-control" :class="{ 'is-invalid': $v.form.roomSize.$error }" id="RoomSize" v-model.trim.lazy="$v.form.roomSize.$model" placeholder="请输入房间房间面积">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.roomSize.required">请输入房间面积</span>
                            <span v-if="!$v.form.roomSize.numeric">请输入房间面积</span>
                            <span v-if="!$v.form.roomSize.between">房间面积有效范围 {{$v.form.roomSize.$params.between.min}} - {{$v.form.roomSize.$params.between.max}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Floor">楼层</label>
                        <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.floor.$error }" id="Floor" v-model.trim.lazy="$v.form.floor.$model" placeholder="请输入房间楼层">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.floor.required">请输入房间楼层</span>
                            <span v-if="!$v.form.floor.integer">请输入房间楼层</span>
                            <span v-if="!$v.form.floor.between">房间楼层有效范围 {{$v.form.floor.$params.between.min}} - {{$v.form.floor.$params.between.max}}</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="HasWindow">是否有窗户</label>
                        <input type="checkbox" id="HasWindow" v-model.trim="form.hasWindow" placeholder="是否有窗户">
                    </div>
                    <div class="form-group">
                        <label>房型</label>
                        <select class="form-control" v-model="form.roomType">
                            <option v-for="i in roomTypes" :value="i.value">{{i.key}}</option>
                        </select>
                    </div>
                    <div>
                        <bedSelect :checkList="beds" checkprop="id" :selected="form.beds" :onChange="onBed">

                        </bedSelect>
                    </div>
                    <div class="form-group">
                        <label for="AllowAddBed">允许加床</label>
                        <input type="checkbox" id="AllowAddBed" v-model.trim="form.allowAddBed" placeholder="允许加床">
                    </div>
                    <div class="form-group">
                        <label for="reserveUrl">预订链接</label>
                        <input type="url" class="form-control" :class="{ 'is-invalid': $v.form.reserveUrl.$error }" id="reserveUrl" v-model.trim="$v.form.reserveUrl.$model" placeholder="请输入预订链接">
                        <div class="invalid-feedback">
                            <span v-if="!$v.form.reserveUrl.isUrl">不是有效的 Url 格式</span>
                        </div>
                    </div>

                    <!--<div class="form-group">
                        <label for="Code">房间编码</label>
                        <input type="text" class="form-control" id="Code" v-model.trim="form.code" placeholder="请输入房间编码">
                    </div>-->
                    <CollapedBox collapseIcon>
                        <template v-slot:header>
                            设置全景
                        </template>
                        <div>
                            <a class="btn btn-primary" @click="showPanoLibDialog()">请选择全景</a>
                            <PanoLib :on-selected="onSelected" :libUrl="PanolibUrl" :params="{innid:$route.params.innId}" ref="PanoLib"></PanoLib>
                            <div>
                                <img v-if="form.panoUrl" class="img-fluid" :src="form.panoUrl.replace('/0/0/0/0/', '/600/0/0/0/')" style="max-height:400px" />
                            </div>
                        </div>
                    </CollapedBox>
                    <CollapedBox collapseIcon expandCard>
                        <template v-slot:header>
                            客房设施
                        </template>
                        <CheckBox checkprop="id"
                                  :checkList="roomFacilitys"
                                  :selected="form.facilitys"
                                  :onChange="checkOnChange1">

                        </CheckBox>
                    </CollapedBox>
                    <div class="form-group">
                        <label for="Closed">关闭房间</label>
                        <input type="checkbox" id="Closed" v-model.trim="form.closed" placeholder="关闭房间">
                    </div>
                </form>
            </div>

        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="this.$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <Footer>
            <button type="button" class="btn btn-default mr-1" @click="cancel">取消</button>
            <template v-if="form.id==null||form.id.length==0">
                <button type="button" @click.prevent="Create" class="btn btn-primary">添加</button>
            </template>
            <template v-else>
                <button type="button" @click.prevent="Update(form.id)" class="btn btn-primary">更新</button>
            </template>
        </Footer>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'
    import { PanoLib, BedSelect, CollapedBox, CheckBox, Footer, PellEditor } from 'common'
    //import PanoLib from '@/components/PanoLib.vue'
    //import bedSelect from '@/components/bedSelect.vue'
    //import CollapedBox from '@/components/CollapedBox.vue'
    //import CheckBox from '@/components/CheckBox.vue'
    //import Footer from '@/components/Footer.vue'
    //import PellEditor from '@/components/PellEditor.vue'
    import { required, minLength, maxLength, between, numeric, integer, helpers } from 'vuelidate/lib/validators'
    const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
    const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
    const isUrl = helpers.regex('isUrl', /^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(?::[0-9]{1,5})?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/)

    export default {
        components: {
            PanoLib,
            BedSelect,
            CollapedBox,
            CheckBox,
            Footer,
            PellEditor
        },
        data() {
            return {
                roomId: this.$route.params.roomId,
                innId: this.$route.params.innId,
                form: {
                    id: '',
                    title: '',
                    description: '',
                    roomType: 1,
                    point: 1,
                    roomSize: '',
                    floor: '',
                    code: '',
                    innId: this.$route.params.innId,
                    panoUrl: null,
                    PanoId: null,
                    facilitys: [],
                    closed: false,
                },
                beds: null,
                roomFacilitys: [],
                roomTypes: [],
                material: null,
                PanolibUrl: window.global.ENABLE_ELASTICSEARCH ? "/Api/ES/Admin/Panos" : "/Api/Admin/Panos",
                options: {
                    whiteList: {
                        p: [],
                        b: [],
                        strong: [],
                        i: [],
                        u: [],
                        strike: [],
                        br: [],
                        //h1: [],
                        //h2: [],
                        //blockquote: [],
                        //hr: [],
                        ol: [],
                        ul: [],
                        li: [],
                        //code: [],
                        //a: ['href'],
                        //img: ['src'],
                        sub: [],
                        sup: [],
                    },
                    stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
                    stripIgnoreTagBody: ['script'] // script标签较特殊，需要过滤标签中间的内容
                }
            }
        },
        validations: {
            form: {
                title: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(30),
                },
                roomSize: {
                    //required,
                    //numeric,
                    between: between(1, 9999)
                },
                floor: {
                    //required,
                    //integer,
                    between: between(-10, 99)
                },
                reserveUrl: {
                    isUrl
                },
            }
        },
        //created() {
        //    this.loadData()
        //},
        mounted() {
            this.loadData()
        },
        computed: {
        },
        methods: {
            ...mapActions([
                'setPageInfo',
            ]),
            loadData() {
                let _this = this;
                this.$axios.get('/Api/InnAdmin/Facilitys/All', { params: { category: '客房设施' } })
                    .then(res => { _this.roomFacilitys = res.data })
                    .catch(function (error) {
                        console.log(error)
                    })
                if (this.roomId) {
                    this.$axios.get('/Api/InnAdmin/Rooms/' + this.roomId).then(function (res) {
                        _this.form = res.data;
                        for (var i in _this.form.beds) {
                            _this.form.beds[i].id = _this.form.beds[i].bedId
                        }
                    })

                } else {
                    this.Reset()
                }
                this.getBeds()
                this.getRoomTypes()
                //axios.get('/Api/InnAdmin/Rooms', {
                //  params: {
                //    innId: this.id,
                //    page: this.query.pageIndex,
                //    pageSize: this.query.pageSize,
                //    sort: this.query.sort,
                //    order: this.query.order ? "desc" : "asc",
                //    fields: this.query.fields,
                //    key: this.query.key,
                //    roles: this.query.roles
                //  }
                //})
                //  .then(function (response) {
                //    _this.query.pageIndex = response.data.pageIndex
                //    _this.query.pageSize = response.data.pageSize
                //    _this.query.totalPages = response.data.totalPages
                //    _this.query.totalRecords = response.data.totalRecords
                //    _this.query.data = response.data.data
                //  })
                //  .catch(function (error) {
                //    console.log(error)
                //  })
            },
            //pageChange(pageIndex) {
            //    this.query.pageIndex = pageIndex
            //    this.loadData()
            //    console.log(pageIndex)
            //},
            //pageSizeChange() {
            //    this.query.pageIndex = 1
            //    //this.query.pageSize = pageSize
            //    this.loadData()
            //},
            //sortingChanged(ctx) {
            //    this.query.sort = ctx.sortBy
            //    this.query.order = ctx.sortDesc
            //    this.loadData()
            //},
            getBeds() {
                var _this = this
                this.$axios.get('/Api/InnAdmin/Beds/All').then(function (res) {
                    _this.beds = res.data;
                })
            },
            getRoomTypes() {
                var _this = this
                this.$axios.get('/Api/InnAdmin/Rooms/Types').then(function (res) {
                    _this.roomTypes = res.data;
                })
            },
            //CreateModal() {
            //    var _this = this;
            //    this.Reset();
            //    this.getBeds()
            //    $('#dialog').modal('show');
            //},
            Create() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                let _this = this
                let d = this.form
                for (var i in d.beds) {
                    d.beds[i].bedid = d.beds[i].id
                }
                delete d.id
                console.log(d)
                this.$axios.post('/Api/InnAdmin/Rooms', d)
                    .then(function (response) {
                        //console.log(response)
                        //_this.loadData()
                        //$('#dialog').modal('hide')
                        //_this.Reset()
                        _this.cancel()

                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            //Delete(id, i) {
            //    let _this = this
            //    this.$axios.delete('/Api/InnAdmin/Rooms/' + id)
            //        .then(function (response) {
            //            console.log(response)
            //            _this.query.data.splice(i, 1)
            //        })
            //        .catch(function (error) {
            //            console.log(error)
            //        })
            //},
            Update(id) {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return
                }
                let _this = this
                let d = this.form

                for (var i in d.beds) {
                    d.beds[i].bedId = d.beds[i].id
                }
                this.$axios.put('/Api/InnAdmin/Rooms/' + id, d)
                    .then(function (response) {
                        //_this.loadData()
                        //$('#dialog').modal('hide')
                        //_this.Reset()
                        //console.log(response)
                        _this.cancel()
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            Reset() {
                this.form = this.newForm()
                this.form.innId = this.innId;
            },
            newForm() {
                return {
                    beds: [],
                    code: "",
                    description: "",
                    id: "",
                    imageId: null,
                    innId: "",
                    panoId: null,
                    panoUrl: null,
                    point: 0,
                    roomType: 0,
                    title: "",
                    facilitys: []
                }
            },
            onSelected(select) {
                console.log(select)
                this.material = select
                this.form.panoUrl = select.firstImage.url
                this.form.PanoId = select.guid
            },
            showPanoLibDialog() {
                this.$refs.PanoLib.showDialog();
            },
            checkOnChange1(list) {
                console.log(list)
                this.form.facilitys = [...list]
            },
            onBed(beds) {
                var list = [...beds];
                this.form.beds = list;
            },
            cancel() {
                this.$router.push({ name: 'InnRoomList' })
            }
        },
    }
</script>
