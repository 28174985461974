<template>
  <div class="wrapper">
    <va-navibar></va-navibar>
    <va-slider :slideMenuItems="slideMenuItems"></va-slider>
    <va-content-wrap></va-content-wrap>
    <!--<footer class="main-footer">
        <div class="float-right d-none d-sm-block">
            <b>Version</b> 6.0
        </div>
        <strong>Copyright &copy; 2015-2019 <a href="http://panorover.com">PanoRover.com</a>.</strong> All rights reserved.
    </footer>-->
    <aside class="control-sidebar control-sidebar-dark">
      <div class="control-sidebar-content p-3">
        <nav v-if="setting" class="nav flex-column">
          <a v-if="global.CONSOLE_URL" class="nav-link" :href="global.CONSOLE_URL">新版个人中心</a>
          <a v-if="$inRole('管理员')&&global.CONSOLE_URL" class="nav-link" :href="global.CONSOLE_URL+'/Admin'">新版管理中心</a>
          <a class="nav-link" :href="setting.myUrl">会员中心</a>
          <a v-if="$inRole('管理员')||$inRole('公共管理')" class="nav-link" :href="setting.myUrl+'/Public'">公共管理</a>
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')||$inRole('客栈店主')||$inRole('客栈店员')" class="nav-link" :href="setting.innAdminUrl+'/Inn'">客栈管理</a>
          <a v-if="$inRole('管理员')||$inRole('客栈代理')||$inRole('客栈代理员工')" class="nav-link" :href="setting.innAdminUrl+'/Agent'">代理管理</a>
          <a v-if="$inRole('管理员')||$inRole('用户组管理员')||$inRole('用户组成员')" class="nav-link" :href="setting.myUrl+'/Group'">项目管理</a>
        </nav>
      </div>
    </aside>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import VANaviBar from '@/components/NaviBar.vue'
  import VASlider from '@/components/Slider.vue'
  import VAContentWrap from '@/components/ContentWrap.vue'
  import store from '@/vuex/store.js'

  export default {
    name: 'default',
    components: {
      'va-navibar': VANaviBar,
      'va-slider': VASlider,
      'va-content-wrap': VAContentWrap,
      //Modal
    },
    data() {
      return {
        signedIn: false,
        slideMenuItems: null,
        global: window.global,
     }
    },
    methods: {
      ...mapActions([
        'getNoticesCount',
        'getNotices',
        'getMessagesCount',
        'getMessages',
        'getSetting',
        'getUser'
      ]),
      loadData: function () {
        this.loadMenu();
        this.getNoticesCount()
        this.getNotices()
        this.getMessagesCount()
        this.getMessages()
        this.getSetting()
        this.getUser()
        this.startSignalR()
      },
      loadMenu: function () {
        var _this = this;
        this.$axios.get('/Api/Admin/Menus').then(function (res) {
          _this.slideMenuItems = res.data;
          //$('.sidebar-menu').tree()
        }).catch(err => {
          console.log(err)
          if (err) {
            _this.$message({
              message: '发生了错误 关闭此消息尝试重新加载',
              type: 'error',
              zIndex: 9999,
              showClose: true,
              duration: -1,
              onClose: () => {
                _this.loadData();
              }
            })
          }
        })
      }
    },
    created: function () {
      if (this.$inRole('管理员') || this.$inRole('审核') || this.$inRole('编辑')) {
        this.loadData();
      } else {
        this.$router.push({ name: 'NoAccess' })
      }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    computed: {
      ...mapGetters([
        'setting',
        //'unreadMessagesCount',
        //'unreadNotificationsCount',
        //'remainTasksCount',
        //'currentUser'
      ]),
    },
    store
  }
</script>

<style>
</style>
