<template lang="html">
    <div class="card card-primary card-outline">
        <form @submit.prevent>
            <div class="card-header p-1">
                <h3 class="card-title">客栈信息</h3>
            </div>
            <div class="card-body p-0">
                <CollapedBox expandCard>
                    <template v-slot:header>
                        基本设置
                    </template>
                    <div class="form-group has-feedback">
                        <label for="Title">客栈名称</label>
                        <div>{{form.title}}</div>
                    </div>
                    <div class="form-group">
                        <label for="Description">简介</label>
                        <div>
                            {{form.description}}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="SpecialService">服务</label>
                        <div>
                            {{form.specialService}}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Tips">提示</label>
                        <div>
                            {{form.tips}}
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="Type">类型</label>
                        <div>
                            {{form.type}}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="Level">档次</label>
                        <div>
                            {{form.level}}
                        </div>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" v-model.trim="form.isChain" placeholder="连锁" disabled>连锁
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" v-model.trim="form.isForeigner" placeholder="接待外宾" disabled>接待外宾
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" v-model.trim="form.isHkMoTw" placeholder="接待港澳台宾客" disabled>接待港澳台宾客
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" v-model.trim="form.notElderly" placeholder="不接待老人" disabled>不接待老人
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" v-model.trim="form.notChild" placeholder="不接待小孩" disabled>不接待小孩
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" v-model.trim="form.notPet" placeholder="不接待宠物" disabled>不接待宠物
                        </label>

                    </div>

                    <div class="form-group">
                        <label for="reserveUrl">预订链接</label>
                        <div>
                            {{form.reserveUrl}}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="ownerSummary">店主介绍</label>
                        <div>
                            {{form.ownerSummary}}
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="ExpiryTime">有效期</label>
                        <div>
                            {{form.expiryTime}}
                        </div>
                    </div>

                </CollapedBox>

                <CollapedBox expandCard>
                    <template v-slot:header>
                        联系信息
                    </template>
                    <div>
                        <div class="form-group has-feedback">
                            <label for="fullName">联系人</label>
                            <div>
                                {{form.address.fullName}}
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label for="mobile">手机</label>
                            <div>
                                {{form.address.mobile}}
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label for="tel">电话</label>
                            <div>
                                {{form.address.tel}}
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <label for="mobile">地址</label>
                            <div>
                                {{form.address.areaName}} {{form.address.street}}
                            </div>
                        </div>
                    </div>
                </CollapedBox>
                <div style="height:calc(100vh - 170px);max-height:350px">
                    <aMap ref="map" :mapConfig="{zoom:16}"></aMap>
                </div>
                <div>
                    <router-link v-if="form.panoid" :to="{name: 'PanoFullScreen', params: { panoId: form.panoid }}">
                        <div>
                            <div>已选全景</div>
                            <img :src="(form.panoUrl||'').replace('/0/0/0/0/', '/600/0/0/0/')" />
                        </div>
                    </router-link>
                    <div v-else>
                        未选全景
                    </div>
                </div>
                <FacilityTXT :facilitys="form.facilitys">
                </FacilityTXT>
                <CollapedBox ref="AddInnUser" maximizeIcon>
                    <template v-slot:header>
                        店铺二维码
                    </template>
                    <h4 v-if="qrcodeLoading" class="text-center">
                        <i class="fas fa-sync fa-spin"></i>
                    </h4>
                    <img v-else :src="qrcode" class="img-fluid" />
                </CollapedBox>
                <CollapedBox ref="SetInnMaster" maximizeIcon>
                    <template v-slot:header>
                        设置店主
                    </template>
                    <div v-if="form.user&&!editUser">
                        {{form.user}}
                        <button class="btn btn-primary" @click="editUser=true">修改</button>
                    </div>
                    <template v-else>
                        <h4 v-if="qrcodeLoading" class="text-center">
                            <i class="fas fa-sync fa-spin"></i>
                        </h4>
                        <img v-else :src="qrcode" class="img-fluid" />
                    </template>
                    <!--<button type="button" class="btn btn-warning" @click="showQrcode('SetInnMaster')">绑定二维码</button>-->
                </CollapedBox>
                <CollapedBox ref="SetInnEditor" maximizeIcon>
                    <template v-slot:header>
                        设置编辑
                    </template>
                    <div v-if="form.editorUser&&!editEditorUser">
                        {{form.editorUser}}
                        <button class="btn btn-primary" @click="editEditorUser=true">修改</button>
                        <button class="btn btn-primary" @click="deleteEditorUser">删除</button>
                    </div>
                    <template v-else>
                        <h4 v-if="qrcodeLoading" class="text-center">
                            <i class="fas fa-sync fa-spin"></i>
                        </h4>
                        <img v-else :src="qrcode" class="img-fluid" />
                    </template>
                    <!--<button type="button" class="btn btn-warning" @click="showQrcode('SetInnEditor')">绑定二维码</button>-->
                </CollapedBox>
                <CollapedBox ref="SetInnService" maximizeIcon>
                    <template v-slot:header>
                        设置客服
                    </template>
                    <template v-if="addEmployee">
                        <h4 v-if="qrcodeLoading" class="text-center">
                            <i class="fas fa-sync fa-spin"></i>
                        </h4>
                        <img v-else :src="qrcode" class="img-fluid" />
                    </template>
                    <div><button class="btn btn-primary" @click="addEmployee=true">添加客服</button></div>
                    <div>
                        <div v-for="e in form.employees">{{e}}</div>
                    </div>
                    <!--<button type="button" class="btn btn-warning" @click="showQrcode('SetInnService')">绑定二维码</button>-->
                </CollapedBox>
                <CollapedBox ref="SetInnService" collapseIcon>
                    <template v-slot:header>
                        相关证件
                    </template>
                    <div class="form-group">
                        <router-link :to="{name:'InnLicenses'}">
                            <button class="btn btn-primary">上传证件</button>
                        </router-link>
                    </div>
                    <div class="form-group">
                        <label>身份证</label>
                        <FileBox v-if="form.idCard" :file="form.idCard"></FileBox>
                    </div>
                    <div class="form-group">
                        <label>营业执照</label>
                        <FileBox v-if="form.businessLicense" :file="form.businessLicense"></FileBox>
                    </div>
                    <div class="form-group">
                        <label>特殊行业许可证</label>
                        <FileBox v-if="form.specialLicense" :file="form.specialLicense"></FileBox>
                    </div>
                    <div class="form-group">
                        <label>消防许可证</label>
                        <FileBox v-if="form.fireLicense" :file="form.fireLicense"></FileBox>
                    </div>
                    <div class="form-group">
                        <label>卫生许可证</label>
                        <FileBox v-if="form.healthLicense" :file="form.healthLicense"></FileBox>
                    </div>
                </CollapedBox>

            </div>
        </form>
        <Footer>
            <div>
                <router-link v-if="$inRole('管理员')||$inRole('客栈店主')||$inRole('客栈代理')" :to="{name:'InnEdit'}">
                    <button class="btn btn-primary">修改</button>
                </router-link>
            </div>
        </Footer>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapState } from 'vuex'
    import {
        aMap,
        PanoLib,
        CollapedBox,
        FacilityTXT,
        Footer,
        FileBox,
    } from 'common'
    //import FacilityTXT from '@/components/FacilityTXT.vue'
    //import Footer from '@/components/Footer.vue'
    //import aMap from '@/components/aMap.vue'
    //import PanoLib from '@/components/PanoLib.vue'
    //import CollapedBox from '@/components/CollapedBox.vue'
    //import FileBox from '@/components/FileBox.vue'

    export default {
        components: {
            aMap,
            PanoLib,
            CollapedBox,
            FacilityTXT,
            Footer,
            FileBox,
        },
        data() {
            return {
                id: this.$route.params.innId,
                form: {
                    id: '',
                    title: '',
                    description: '',
                    address: {
                        area: null,
                        street: '',
                        tel: '',
                        mobile: '',
                        fullName: ''
                    },
                    expiryTime: '',
                    panoUrl: '',
                    lng: 0,
                    lat: 0,
                    facilitys: [],
                    businessLicense: null,
                    idCard: null,
                    specialLicense: null,
                    fireLicense: null,
                    healthLicense: null,
                    closed: false,
                },
                qrcodeLoading: false,
                qrcode: null,
                editEditorUser: false,
                editUser: false,
                addEmployee: false
            }
        },
        computed: {
        },
        methods: {
            deleteEditorUser() {

            },
            setMark() {
                console.log(123, this.form)
                if (this.form) {
                    if (this.form.lat == 0 && this.form.lng == 0) {
                        return;
                    }
                    this.$refs.map.addMark('markpoint', {
                        gpsLat: this.form.lat,
                        gpsLng: this.form.lng
                    })
                    this.$refs.map.setCenterLngLat(this.form.lng, this.form.lat, 'GPS')
                }
            },
            initData() {
            },
            loadData() {
                let _this = this
                return this.$axios.get('/Api/InnAdmin/Inns/' + this.id)
                    .then(function (response) {
                        console.log(12, response)
                        let d = response.data
                        _this.form = d;
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            },
            showQrcode(QRpath) {
                let _this = this
                console.log('showQrcode')
                //this.$bus.$emit('qrcodeModal-modal-open', {});
                //this.$refs.qrcodeModal.show()
                this.qrcodeLoading = true;
                //this.qrcode='http://localhost:5014/Api/Weixin/QrCode/'+ this.id
                this.$axios.get('/Api/Weixin/QRCode/' + QRpath + '/' + this.id)
                    .then(function (response) {
                        _this.qrcodeLoading = false;
                        console.log(response)
                        _this.qrcode = window.global.API_BASE_URL + '/Api/QRCode?url=' + response.data.url
                    })
                    .catch(function (error) {
                        console.log(error)
                    })

            },
            //hideQrcode() {
            //    //this.$bus.$emit('qrcodeModal-modal-hide', {});
            //    this.$refs.qrcodeModal.hide()

            //},
        },
        created() {
            //this.loadData()
        },
        mounted() {
            var _this = this
            console.log(_this.$refs)
            $(_this.$refs.AddInnUser.$el).on('maximized.lte.cardwidget', () => {
                _this.showQrcode('AddInnUser')
            })
            $(_this.$refs.SetInnMaster.$el).on('maximized.lte.cardwidget', () => {
                _this.showQrcode('SetInnMaster')
            })
            $(_this.$refs.SetInnEditor.$el).on('maximized.lte.cardwidget', () => {
                _this.showQrcode('SetInnEditor')
            })
            $(_this.$refs.SetInnService.$el).on('maximized.lte.cardwidget', () => {
                _this.showQrcode('SetInnEmployee')
            })

            var p = [];
            p.push(this.loadData())
            p.push(new Promise((resolve, reject) => {
                this.$refs.map.$once('MapReady', resolve)
            }))
            Promise.all(p).then(this.setMark)
        },
        watch: {
        },

    }
</script>
<style lang="css">
</style>
