<template lang="html">
  <div class="card card-primary card-outline CommentList">
    <div class="card-header p-1">
      <div class="card-title">
        <button type="button" class="btn btn-default btn-sm mx-1 mr-2" v-on:click="loadData()"><i class="fas fa-sync"></i></button>
        <!--<div class="btn-group" role="group" aria-label="Basic example">
            <router-link class="btn btn-secondary" :to="{ name: 'CommentList', query: null}">
                全部
            </router-link>
            <router-link class="btn btn-secondary" v-for="i in types" :to="{ name: 'CommentList', query: { type: i.key }}">
                {{i.key}}
            </router-link>
        </div>-->
      </div>
      <div class="card-tools mr-0">
        <div class="input-group input-group-sm mt-0">
          <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
          <div class="input-group-append">
            <button type="button" class="btn btn-primary" v-on:click="search()">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body p-1">
      <div class="mailbox-controls p-0">
        <ul class="nav nav-tabs nav-nowrap">
          <li class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.type==null}" :to="{ query: {...$route.query,page:undefined, type: undefined }}">
              全部
            </router-link>
          </li>
          <li v-for="i in types" class="nav-item">
            <router-link :class="{'nav-link':true,'active':query.type==i.key}" :to="{ query: {...$route.query,page:undefined, type: i.key }}">
              {{i.key}}
            </router-link>
          </li>
        </ul>
        <!--<div class="form-inline visible-md-inline-block visible-lg-inline-block visible-sm-inline-block">
            <div class="form-group form-group-sm">
                <label>类型</label>
                <select class="form-control" v-model:value="query.type" v-on:change="search()" placeholder="类型">
                    <option value="">所有</option>
                    <option v-for="i in types" v-bind:value="i">{{i.key}}</option>
                </select>
            </div>
        </div>-->
        <!--<div class="pull-right">
            <div class="form-inline">
                <div class="form-group form-group-sm">
                </div>
            </div>
        </div>-->
        <!-- /.pull-right -->
      </div>
      <vue-bootstrap4-table :rows="list"
                            :columns="columns"
                            :config="config"
                            :classes="classes"
                            @on-change-query="onChangeQuery">
        <template slot="sort-asc-icon">
          <i class="fas fa-sort-up"></i>
        </template>
        <template slot="sort-desc-icon">
          <i class="fas fa-sort-down"></i>
        </template>
        <template slot="no-sort-icon">
          <i class="fas fa-sort"></i>
        </template>
        <template slot="empty-results">
          没有数据
        </template>
        <template slot="active" slot-scope="props">
          <button v-if="props.row.closed" class="btn btn-default btn-xs mx-1" title="解除屏蔽" @click="unClosed(props.row)">
            <i class="fas fa-eye-slash"></i>
          </button>
          <button v-else class="btn btn-default btn-xs mx-1" title="屏蔽" @click="closed(props.row)">
            <i class="fas fa-eye"></i>
          </button>
        </template>
        <template slot="text" slot-scope="props">
          <div v-if="props.row.type!='点赞'" @click="showView(props.row)">
            <AutoTextareaView :key="props.row.guid" :value="props.row.text" :maxRow="3" :cssStyle="{background:'none',width: '100%'}"></AutoTextareaView>
          </div>
          <div v-else>
            点赞
          </div>
        </template>
        <template slot="user" slot-scope="props">
          <div v-if="props.row.user" class="text-nowrap" :title="props.row.user.nickName">{{props.row.user.nickName}}</div>
          <div v-else>匿名用户</div>
        </template>
        <template slot="readed" slot-scope="props">
          {{props.row.readed ? "是" : "否"}}
        </template>
      </vue-bootstrap4-table>
    </div>
    <!-- Loading (remove the following to stop the loading)-->
    <div class="overlay" v-if="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <!-- end loading -->
    <div class="card-footer p-2">
      <paging :page-index="pager.pageIndex"
              :total="pager.totalRecords"
              :page-size="pager.pageSize"
              :per-pages="3"
              :show-go="true"
              :onPagerChange="pageChange">
      </paging>
    </div>
    <Modal ref="View">
      <template slot="header">
        <h4 v-if="item" class="modal-title">{{item.title}}</h4>
      </template>
      <form class="form-horizontal">
        <div v-if="item" style="max-height:40vh;overflow-y:auto;">
          <pre style="white-space:normal">{{item.text}}</pre>
        </div>
        <div v-else>
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <AutoTextarea v-model="text" :maxHeight="200" :cssStyle="{width: '100%'}"></AutoTextarea>
        <div style="display:flex;padding:10px 0 5px;background-color: #fff;padding:10px;">
          <!--<textarea style="flex:auto;resize:none" v-model="text" />-->
          <div style="flex:auto"></div>
          <div style="flex:none">
          </div>
        </div>
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" @click="$refs.View.hide()">关闭</button>
        <button type="button" class="btn btn-primary" title="回复" @click="send" :disabled="sending">回复</button>
      </template>
    </Modal>
    <Modal ref="Delete">
      <template slot="header">
        <h4 class="modal-title">删除留言</h4>
      </template>
      <form class="form-horizontal">
        {{item}}
      </form>
      <template slot="footer">
        <button type="button" class="btn btn-default pull-left" @click="$refs.Delete.hide()">取消</button>
        <button type="button" class="btn btn-danger" @click="del(item)" :disabled="updateing">删除</button>
      </template>
    </Modal>
  </div>
</template>

<script>
  import { Modal, AutoTextareaView, AutoTextarea } from 'common'
  export default {
    components: {
      Modal,
      AutoTextareaView,
      AutoTextarea,
    },
    data() {
      return {
        list: [],
        item: {
          guid: null,
          name: null,
          closed: false,
          type: '默认',
        },
        columns: [
          { name: 'article.title', label: '标题', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'text', label: '内容', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
          //{ name: 'article.type', label: '类型', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_type" },
          { name: 'user', label: '用户', sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_type" },
          { name: 'addTime', label: '发布时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap c_dt", row_classes: "text-nowrap", row_text_alignment: "text-left" },
          { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
        ],
        classes: {
          tableWrapper: "table-responsive",
          table: "table-bordered table-sm table-hover",
          //row: {
          //    "my-row my-row2": true,
          //    "function-class": function (row) {
          //        return row.id == 1
          //    }
          //},
          //cell: {
          //    "my-cell my-cell2": true,
          //    "text-danger": function (row, column, cellValue) {
          //        return column.name == "salary" && row.salary > 2500
          //    }
          //}
        },
        config: {
          //checkbox_rows: true,
          //rows_selectable: true,
          card_mode: false,
          pagination: false,
          pagination_info: false,
          global_search: {
            visibility: false,
          },
          show_refresh_button: false,
          show_reset_button: false,
          multi_column_sort: false,
          server_mode: true,
        },
        pager: {
          prePageSize: [10, 20, 50, 100],
          pageIndex: 1,
          pageSize: 1,
          totalPages: 0,
          totalRecords: 0
        },
        query: { ...this.getDefaultQuery(), ...this.$route.query },
        types: [],
        sending: false,
        text: '',
        updateing: false,
        loading: false
      }
    },
    created() {
      this.loadData()
      this.loadTypes()
    },
    methods: {
      getDefaultQuery() {
        return {
          page: 1,
          pageSize: 20,
          sort: "addTime",
          order: "desc",
          //fields: 'Title,Text',
          key: null,
          type: null,
          articleGuid: null,
        }
      },
      getDifferentQuery(query) {
        var dif = {}
        var all = { ...this.query, ...query }
        var def = this.getDefaultQuery()
        for (var i in all) {
          if (def[i] != all[i]) {
            dif[i] = all[i]
          }
        }
        return dif
      },
      setQuery(newquery) {
        if (!newquery) {
          this.query = this.getDefaultQuery()
        }
        var dif = this.getDifferentQuery(newquery)
        console.log(this.$route.query, dif)
        if (!this.$isObjectValueEqual(this.$route.query, dif)) {
          this.$router.push({ query: dif })
        }
      },
      send() {
        this.writeComment(this.text, this.item).then(() => {
          this.loadData()
          this.$refs.View.hide()
          this.text = ''
        })
      },
      writeComment(comment, reply) {
        this.sending = true
        if (comment != null && comment != "") {
          var data = {
            text: comment
          }
          if (reply) {
            data.parentGUID = reply.guid
            if (reply.rootGUID) {
              data.rootGUID = reply.rootGUID
            } else {
              data.rootGUID = reply.guid
            }
          }
          return this.$axios.post(`/Api/Comments/${reply.article.guid}`, data).catch((err) => {
            switch (err.response.status) {
              case 401:
                alert('需要登录')
                break
              default:
            }
            this.sending = false
          }).then((res) => {
            //if (reply) {
            //    var com = this.getRootComment(reply)
            //    com.replys.splice(com.replys.length, 0, res.data)
            //} else {
            //    this.comments.splice(0, 0, res.data)
            //}
            this.sending = false
          })
        }
      },
      rowClass(item, type) {
        if (!item) return
        if (item.readed === false) return 'text-bold'
      },
      loadTypes() {
        this.$axios.get('/Api/Admin/Comments/Types').then(res => {
          this.types = res.data
        })
      },
      loadData() {
        this.loading = true
        var url = "/Api/ES/Admin/Comments"
        var params = {
          ...this.query
        }
        this.$axios.get(url, { params: params })
          .then((response) => {
            this.list = response.data.data
            this.pager.pageIndex = response.data.pageIndex
            this.pager.pageSize = response.data.pageSize
            this.pager.totalPages = response.data.totalPages
            this.pager.totalRecords = response.data.totalRecords
            this.loading = false
          }).catch((error) => {
            console.error(error)
            this.loading = false
          })

      },
      showView(item) {
        this.$refs.View.show()
        this.item = item
      },
      search() {
        this.setQuery({ page: 1 })
      },
      pageChange(page, pageSize) {
        this.setQuery({ page, pageSize })
      },
      onChangeQuery(queryParams) {
        var sort = queryParams.sort[0]
        this.setQuery({ sort: sort.name, order: sort.order })
      },
      opendelete(item) {
        this.$refs.Delete.show()
      },
      del(item) {
        this.updateing = true;
        this.$axios.delete(`/Api/Admin/Comments/${item.guid}`)
          .then((res) => {
            this.$refs.Delete.hide()
            this.loadData()
            this.updateing = false;
          })
          .catch((error) => {
            console.error(error)
            this.updateing = false;
          })
      },
      closed(item) {
        if (this.updateing) {
          return
        }
        this.$axios.get(`/Api/Admin/Comments/Closed/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '屏蔽成功',
              type: 'info',
              zIndex: 9999,
            })
            item.closed = true
            //setTimeout(this.loadData, 1000)
          }
        })
      },
      unClosed(item) {
        if (this.updateing) {
          return
        }
        this.$axios.get(`/Api/Admin/Comments/UnClosed/${item.guid}`).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: '解除成功',
              type: 'info',
              zIndex: 9999,
            })
            item.closed = false
            //setTimeout(this.loadData, 1000)
          }
        })
      },
    },
    beforeRouteUpdate(to, from, next) {
      this.query = { ...this.getDefaultQuery(), ...to.query }
      this.loadData()
      next()
    }
  }
</script>

<style lang="css">
  .CommentList .c1 {
    width: 90px;
  }

  .CommentList .c2 {
    width: 60px;
  }

  .CommentList .c_dt {
    width: 155px;
  }

  .CommentList .c_type {
    width: 120px;
  }
</style>
