<template lang="html">
    <div class="card card-primary card-outline MessageList">
        <div class="card-header p-2">
            <h3 class="card-title">聊天管理</h3>
            <div class="card-tools mr-0">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" placeholder="搜索" v-model="query.key" v-on:change="search()">
                    <div class="input-group-append">
                        <button type="button" class="btn btn-primary" v-on:click="search()">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- /.box-header -->
        <div class="card-body p-1">
            <div class="mailbox-controls">
                <button type="button" class="btn btn-primary btn-sm mr-1" v-on:click="create()" title="新建"><i class="fa fa-plus"></i></button>
                <button type="button" class="btn btn-default btn-sm" v-on:click="loadData()" title="刷新"><i class="fas fa-sync"></i></button>
                <!--<div class="pull-right">
                    <div class="form-inline">
                        <div class="form-group form-group-sm">
                            <label>角色</label>
                            <select class="form-control" v-model:value="query.roles" v-on:change="search()" placeholder="角色">
                                <option value="">所有</option>
                                <option v-for="i in roles" v-bind:value="i">{{i}}</option>
                            </select>
                        </div>
                    </div>
                </div>-->
                <!-- /.pull-right -->
            </div>
            <vue-bootstrap4-table :rows="list"
                                  :columns="columns"
                                  :config="config"
                                  :classes="classes"
                                  @on-change-query="onChangeQuery">
                <template slot="sort-asc-icon">
                    <i class="fas fa-sort-up"></i>
                </template>
                <template slot="sort-desc-icon">
                    <i class="fas fa-sort-down"></i>
                </template>
                <template slot="no-sort-icon">
                    <i class="fas fa-sort"></i>
                </template>
                <template slot="empty-results">
                    没有数据
                </template>
                <template slot="active" slot-scope="props">
                    <button class="btn btn-default btn-xs mx-1" title="编辑" @click="edit(props.row)">
                        <i class="fas fa-pen-square"></i>
                    </button>
                    <button class="btn btn-default btn-xs mx-1" title="删除" @click="delDialog(props.row)">
                        <i class="fas fa-trash"></i>
                    </button>
                </template>
                <template slot="user" slot-scope="props">
                    <div @click="view(props.row.user.id,props.row.toUser.id)">
                        {{props.row.user.nickName||props.row.user.userName}}
                    </div>
                </template>
                <template slot="toUser" slot-scope="props">
                    <div @click="view(props.row.toUser.id,props.row.user.id)">
                        {{props.row.toUser.nickName||props.row.toUser.userName}}
                    </div>
                </template>
                <template slot="closed" slot-scope="props">
                    {{props.row.closed ? "是" : "否"}}
                </template>
            </vue-bootstrap4-table>
        </div>
        <!-- Loading (remove the following to stop the loading)-->
        <div class="overlay" v-if="this.$store.state.loading">
            <i class="fa fa-refresh fa-spin"></i>
        </div>
        <!-- end loading -->
        <div class="card-footer p-2">
            <paging :page-index="pager.pageIndex"
                    :total="pager.totalRecords"
                    :page-size="pager.pageSize"
                    :per-pages="3"
                    :show-go="true"
                    :prePageSize="pager.prePageSize"
                    :onPagerChange="pageChange">
            </paging>
        </div>
        <Modal ref="View">
            <template slot="header">
                <h4 class="modal-title">聊天会话</h4>
            </template>
            <form class="form-horizontal">
                <div class="card-body p-0">
                    <div class="direct-chat-messages">
                        <div v-if="hasMore" class="text-center">
                            <a @click="AppendData">获取更多…</a>
                        </div>
                        <div v-else class="text-center">
                            前面没有了
                        </div>
                        <template v-for="(msg) in messages">
                            <!-- Message. Default to the left -->
                            <a :name="'msg_'+ msg.addTime" :id="'msg_'+ $moment(msg.addTime).unix()" />
                            <div v-if="msg.user.id != talkParams.userId" class="direct-chat-msg">
                                <div class="direct-chat-infos clearfix">
                                    <span class="direct-chat-name float-left">{{msg.user.nickName}}</span>
                                    <time class="direct-chat-timestamp ml-2" :title="$moment(msg.addTime).format('YYYY-MM-DD HH:mm:ss')">{{formatTime(msg.addTime)}}</time>
                                </div>
                                <!-- /.direct-chat-info -->
                                <img v-if="msg.user.avatarUrl" class="direct-chat-img" :src="msg.user.avatarUrl.replace('/0/0/0/0/', '/100/100/1/0/')" alt="Message User Image">
                                <img v-else class="direct-chat-img" src="/images/avatar-default.jpg" alt="Message User Image">
                                <!-- /.direct-chat-img -->
                                <div class="direct-chat-text">
                                    {{msg.text}}
                                </div>
                                <!-- /.direct-chat-text -->
                            </div>
                            <!-- /.direct-chat-msg -->
                            <!-- Message to the right -->
                            <div v-else class="direct-chat-msg right">
                                <div class="direct-chat-infos clearfix">
                                    <span class="direct-chat-name float-right">{{msg.user.nickName}}</span>
                                    <time class="direct-chat-timestamp float-right mr-2" :title="$moment(msg.addTime).format('YYYY-MM-DD HH:mm:ss')">{{formatTime(msg.addTime)}}</time>
                                </div>
                                <!-- /.direct-chat-info -->
                                <img v-if="msg.user.avatarUrl" class="direct-chat-img" :src="msg.user.avatarUrl.replace('/0/0/0/0/', '/100/100/1/0/')" alt="Message User Image">
                                <img v-else class="direct-chat-img" src="/images/avatar-default.jpg" alt="Message User Image">
                                <!-- /.direct-chat-img -->
                                <div class="direct-chat-text">
                                    {{msg.text}}
                                </div>
                                <!-- /.direct-chat-text -->
                            </div>
                            <!-- /.direct-chat-msg -->
                        </template>
                    </div>
                </div>
            </form>
            <template slot="footer">
                <button type="button" class="btn btn-default pull-left" data-dismiss="modal">关闭</button>
            </template>
        </Modal>
        <Modal ref="delModal">
            <template slot="header">
                <h4 class="modal-title">删除聊天记录</h4>
            </template>
            确定删除 {{(item||{}).name}} ?
            <template slot="footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">取消</button>
                <button type="button" class="btn btn-primary" @click="del(item)" :disabled="updateing">确定</button>
            </template>
        </Modal>

        <!-- /.modal -->
    </div>
</template>

<script>
    import { Modal } from 'common'
    //import Modal from '@/components/Modal'

    export default {
        data() {
            return {
                list: [],
                item: null,
                messages: [],
                talkParams: {
                    userId: null,
                    toUserId: null,
                    pageSize: 10,
                },
                hasMore: false,
                columns: [
                    { name: 'active', label: '操作', column_classes: "text-nowrap c1", row_classes: "text-nowrap" },
                    { name: 'user', label: '发送者', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap maxW200 text-truncate", row_text_alignment: "text-left" },
                    { name: 'toUser', label: '接收者', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap maxW200 text-truncate", row_text_alignment: "text-left" },
                    { name: 'text', label: '内容', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_classes: "text-nowrap maxW200 text-truncate", row_text_alignment: "text-left" },
                    { name: 'addTime', label: '时间', sort: true, initial_sort: true, initial_sort_order: 'desc', column_classes: "text-nowrap", row_classes: "text-nowrap", row_text_alignment: "text-left" },
                    { name: 'closed', label: '关闭', sort: true, initial_sort_order: 'asc', column_classes: "text-nowrap", row_text_alignment: "text-left" },
                ],
                classes: {
                    tableWrapper: "table-responsive",
                    table: "table-bordered table-sm table-hover",
                    //row: {
                    //    "my-row my-row2": true,
                    //    "function-class": function (row) {
                    //        return row.id == 1
                    //    }
                    //},
                    //cell: {
                    //    "my-cell my-cell2": true,
                    //    "text-danger": function (row, column, cellValue) {
                    //        return column.name == "salary" && row.salary > 2500
                    //    }
                    //}
                },
                config: {
                    //checkbox_rows: true,
                    //rows_selectable: true,
                    card_mode: false,
                    pagination: false,
                    pagination_info: false,
                    global_search: {
                        visibility: false,
                    },
                    show_refresh_button: false,
                    show_reset_button: false,
                    multi_column_sort: false,
                    server_mode: true,
                },
                pager: {
                    prePageSize: [10, 20, 50, 100],
                    pageIndex: 1,
                    pageSize: 1,
                    totalPages: 0,
                    totalRecords: 0
                },
                query: {
                    pageIndex: 1,
                    pageSize: 20,
                    sort: "AddTime",
                    order: "desc",
                    fields: 'Text',
                    key: null
                },
                updateing: false,
            }
        },
        created() {
            //this.loadRoles()
            this.loadData()
        },
        computed: {

        },
        methods: {
            loadData() {
                var _this = this;
                var config = {
                    params: {
                        page: this.query.pageIndex,
                        pageSize: this.query.pageSize,
                        sort: this.query.sort,
                        order: this.query.order,
                        fields: this.query.fields,
                        key: this.query.key
                    }
                };
                this.$axios.get('/Api/Admin/Messages', config).then(function (res) {
                    //console.log(res.data)
                    _this.list = res.data.data
                    _this.pager.pageIndex = res.data.pageIndex
                    _this.pager.pageSize = res.data.pageSize
                    _this.pager.totalPages = res.data.totalPages
                    _this.pager.totalRecords = res.data.totalRecords
                })
            },
            search() {
                this.query.pageIndex = 1;
                this.loadData();
            },
            pageChange(pageIndex, pageSize) {
                this.query.pageIndex = pageIndex;
                this.query.pageSize = pageSize;
                this.loadData();
            },
            onChangeQuery(queryParams) {
                var sort = queryParams.sort[0]
                this.query.sort = sort.name
                this.query.order = sort.order
                this.loadData()
            },
            loadTalks(userId, toUserId) {
                this.$axios.get('/Api/Admin/Messages/Talks', {
                    params: {
                        userId, toUserId
                    }
                }).then((response) => {
                    this.messages = (response.data || []).reverse()
                    this.talkParams = {
                        userId: userId,
                        toUserId: toUserId,
                        pageSize: 10,
                    }
                    this.hasMore = response.data.length == this.talkParams.pageSize;
                    var div = document.querySelector(".direct-chat-messages")
                    setTimeout(function () { div.scrollTop = div.scrollHeight }, 50)
                }).catch((error) => {
                    console.log(error)
                })
            },
            AppendData() {
                var url = "/Api/Admin/Messages/Talks"
                var params = {
                    userName: this.talkParams.userName,
                    toUserName: this.talkParams.toUserName,
                    pageSize: this.talkParams.pageSize,
                    lastTime: this.messages[0].addTime
                }
                this.$axios.get(url, { params: params })
                    .then((response) => {
                        console.log(response)
                        this.messages = [...(response.data || []).reverse(), ...this.messages]
                        this.hasMore = response.data.length == params.pageSize;
                        var div = document.querySelector(".direct-chat-messages")
                        setTimeout(() => {
                            var obj = document.querySelector('#msg_' + this.$moment(params.lastTime).unix())
                            $(div).scrollTop(obj.offsetTop - 32)
                            //$(div).animate({ scrollTop: obj.offsetTop-32 }, 0)
                            //$(div).fadeIn(1000);
                        }, 1)
                    }).catch(function (error) {
                        console.log(error)
                    })
            },
            view(userId, toUserId) {
                this.$refs.View.show()
                this.loadTalks(userId, toUserId)
            },
            save() {
                let _this = this
                _this.updateing = true;
                if (_this.item.guid != null) {
                    this.$axios.put('/Api/Admin/Messages/' + _this.item.guid, _this.item)
                        .then(function (response) {
                            _this.reset()
                            _this.loadData();
                            _this.$refs.Edit.hide()
                            //this.loadData();
                            _this.updateing = false;
                        })
                        .catch(function (error) {
                            console.log(error)
                            _this.updateing = false;
                        })
                } else {
                    this.$axios.post('/Api/Admin/Messages', _this.item)
                        .then(function (response) {
                            _this.reset()
                            _this.loadData();
                            _this.$refs.Edit.hide()
                            _this.updateing = false;
                        })
                        .catch(function (error) {
                            console.log(error)
                            _this.updateing = false;
                        })
                }
            },
            delDialog(item) {
                this.$refs.delModal.show()
                this.item = item
            },
            del(item) {
                let _this = this
                _this.updateing = true;
                this.$axios.delete('/Api/Admin/Messages/' + item.guid)
                    .then(function (response) {
                        console.log(response)
                        _this.loadData()
                        _this.updateing = false;
                        _this.$refs.delModal.hide()
                    })
                    .catch(function (error) {
                        _this.updateing = false;
                        console.log(error)
                    })
            },
            formatTime(time) {
                var mTime = this.$moment(time)
                if (mTime.toDate() < this.$moment().subtract(6, 'days').toDate()) {
                    return mTime.format("YYYY-MM-DD HH:mm:ss")
                }
                else if (mTime.toDate() < this.$moment().subtract(1, 'days').toDate()) {
                    return mTime.format("dddd HH:mm:ss")
                }
                else if (mTime.toDate() < this.$moment().subtract(4, 'hours').toDate()) {
                    return mTime.format("HH:mm:ss")
                }
                else {
                    return mTime.fromNow()
                }
            }
        },
        components: {
            Modal,
        }
    }
</script>
<style>
    .MessageList .maxW200 {
        max-width: 200px;
    }
</style>
<style lang="css" scoped>

    .direct-chat-messages {
        height: calc(100vh - 135px);
    }

    .direct-chat-text {
        margin-left: 10px;
        display: inline-block;
        max-width: calc(100% - 50px);
    }

    .right .direct-chat-text {
        margin-right: 10px;
        margin-left: auto;
        float: right;
    }
</style>
